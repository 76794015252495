import { TextScript, useConfig, useSessionStorage } from '@MGPD/myasurion-shared';
import { LockIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Button, ButtonProps, ChakraProvider, Flex, Stack, Text } from '@chakra-ui/react';

export interface RateButtonProps {
    label: string;
    onClick: () => void;
}

export interface CloseButtonProps {
    label: string;
    onClick: () => void;
}

export interface HowToFindButtonProps {
    label: string;
    onClick: () => void;
}

import { generateLabelKey } from '../modules/config/constants';
import PDFViewer from './generateLabel/labelViewer';


export interface JBConfirmationAfterSubmissionProps {
    title?: string;
    heading?: string;
    serviceRequestdetails?: string;
    text1?: string;
    subheading1?: string;
    subheadingcontent1?: string;
    subheadingcontent2?: string;
    subheading2?: string;
    subheading2content1?: string;
    subheading2content2?: string;
    subheading3?: string;
    subheading3content1?: string;
    subheading3generateLabel?: string;
    showGenerateLabel?: boolean;
    info1?: string;
    info1content?: string;
    info2?: string;
    info2content?: string;
    info3?: string;
    info3content?: string;
    lblhowtofind?: string;
    Date?: string;
    lblrate?: string;
    lblclose?: string;
    searchTitle?: string;
    expertGroupImage?: string;
    doAnimate?: boolean;
    rateButtonProps?: RateButtonProps;
    closeButtonProps?: CloseButtonProps;
    howToFindButtonProps?: HowToFindButtonProps;
    IncidentType?: string;
    paymentBreakdown?: string;
    serviceRequestFee?: string;
    serviceRequestFeeAmount?: string;
    dataTransferFeesVal?: string;
    dataTransferFeesLBL?: string;
    nightDelivery?: string;
    nightDeliveryAmount?: string;
    subTotal?: string;
    subTotalAmount?: string;
    Discount?: string;
    DiscountAmount?: string;
    TotalAfterDiscount?: string;
    TotalAmountAfterDiscount?: string;
    RequestedServicelbl?: string;
    Summarylbl?: string;
    RequestedServicevalue?: string;
    IMEIlbl?: string;
    IMEIvalue?: string;
    RequestTypelbl?: string;
    RequestTypevalue?: string;
    DeliveryAddresslbl?: string;
    Deliveryaddressvalue?: string;
    DeliveryDateTimelbl?: string;
    DeliveryDateTimevalue?: string;
    returnAddrsLBL?: string;
    returnAddrsVal?: string;
    returnDateTimeLBL?: string;
    returnDateTimeVal?: string;
    LFLStock?: boolean;
    isWarranty?: boolean;
    isWalkin?: boolean;
    requestId?: string;
    DateTime?: string;
    make?: boolean;
    showSurvey?: boolean;
    alternativeContactNumber?: string;
}

export const JBConfirmationAfterSubmission = ({
    title = 'Your request is submitted.',
    heading = 'Your request ID:',
    requestId = '',
    text1 = '',
    subheading1 = 'What happens next?',
    subheadingcontent1 = '',
    DateTime = '',
    subheadingcontent2 = '',
    subheading2 = '',
    subheading2content1 = '',
    subheading2content2 = '',
    subheading3 = '',
    subheading3content1 = '',
    subheading3generateLabel = '',
    showGenerateLabel = true,
    info1 = '',
    info1content = '',
    info2 = '',
    info2content = '',
    info3 = '',
    info3content = '',
    searchTitle = '',
    lblhowtofind = '',
    lblrate = '',
    lblclose = 'Close this page',
    rateButtonProps,
    closeButtonProps,
    howToFindButtonProps,
    IncidentType = 'SWAP',
    doAnimate = false,
    Date,
    expertGroupImage,
    serviceRequestdetails = '',
    paymentBreakdown = '',
    serviceRequestFee = '',
    serviceRequestFeeAmount = '',
    dataTransferFeesVal = '',
    dataTransferFeesLBL = '',
    nightDelivery = '',
    nightDeliveryAmount = '',
    subTotal = '',
    subTotalAmount = '',
    Discount = '',
    DiscountAmount = '',
    TotalAfterDiscount = '',
    TotalAmountAfterDiscount = '',
    RequestedServicelbl = '',
    Summarylbl = '',
    RequestedServicevalue = '',
    IMEIlbl = '',
    IMEIvalue = '',
    RequestTypelbl = '',
    RequestTypevalue = '',
    DeliveryAddresslbl = '',
    Deliveryaddressvalue = '',
    DeliveryDateTimelbl = '',
    DeliveryDateTimevalue = '',
    returnAddrsLBL,
    returnAddrsVal,
    returnDateTimeLBL,
    returnDateTimeVal,
    LFLStock,
    isWarranty = false,
    isWalkin = false,
    make,
    showSurvey = true,
    alternativeContactNumber = '',
    ...props
}: JBConfirmationAfterSubmissionProps & BoxProps & ButtonProps) => {
    const pageName = 'ConfirmationAfterSubmission';

    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { accent } = colors;
    const [generateLabelData] = useSessionStorage<any>(generateLabelKey, null);
    const pdfLabelBase64 = generateLabelData?.GenerateEParcellabelResponse?.PdfLabelBase64

    return (
        <Box>
            <Box
                p={'16px , 16px'}
                {...props}
                w={{ base: 'full', lg: '60%' }}
                margin={{ base: '0', lg: 'auto' }}
                pb={{ base: '0', lg: '100px' }}
            >
                <Text fontSize="3xl" mb={'8px'}>
                    <TextScript text={title} translationKey={`${pageName}/title`} />
                </Text>
                <Flex mb={'8px'}>
                    <Text fontSize="lg" mr={1}>
                        <TextScript text={heading} translationKey={`${pageName}/heading`} />
                    </Text>
                    <Text as={'b'} fontSize="lg">
                        {requestId}
                    </Text>
                </Flex>

                {text1 && (
                    <Text fontFamily={'Apercu Pro'} fontWeight="light" fontSize="lg" mb={'36px'}>
                        {text1}
                    </Text>
                )}

                {
                    <>
                        {subheading1 && (
                            <Text
                                fontFamily={'Apercu Pro'}
                                fontWeight="light"
                                fontSize="2xl"
                                mb={'8px'}
                            >
                                <TextScript
                                    text={subheading1}
                                    translationKey={`${pageName}/lblSubheading1`}
                                />
                            </Text>
                        )}
                        {subheadingcontent1 && (
                            <Flex>
                                <Text
                                    fontFamily={'Apercu Pro'}
                                    fontWeight="light"
                                    fontSize="lg"
                                    mb={'36px'}
                                >
                                    {subheadingcontent1} <b>{DateTime}</b>
                                </Text>
                            </Flex>
                        )}
                    </>
                }
                
                {subheadingcontent2 && (
                    <Text fontFamily={'Apercu Pro'} fontWeight="light" fontSize="lg" mb={'24px'}>
                        {subheadingcontent2}
                    </Text>
                )}
                {subheading2 && (
                    <Text fontFamily={'Apercu Pro'} fontWeight="light" fontSize="2xl" mb={'8px'}>
                        <TextScript
                            text={subheading2}
                            translationKey={`${pageName}/lblSubheading2`}
                        />
                    </Text>
                )}
                {subheading2content2 && (
                    <Text fontFamily={'Apercu Pro'} fontWeight="light" fontSize="lg" mb={'8px'}>
                        <TextScript
                            text={subheading2content2}
                            translationKey={`${pageName}/lblSubheading2Content2`}
                        />
                    </Text>
                )}
                {subheading2content1 && (
                    <Text fontFamily={'Apercu Pro'} fontWeight="light" fontSize="lg" mb={'8px'}>
                        <TextScript
                            text={subheading2content1}
                            translationKey={`${pageName}/lblSubheading2Content1`}
                        />
                    </Text>
                )}
                {make && IncidentType?.toUpperCase() !== 'REPLACEMENT' && (
                    <Stack mb={'24px'} p={'0px , 16px'}>
                        <Button
                            // paddingX={'16px'}
                            paddingY={'12px'}
                            colorScheme={'black'}
                            justifyContent={'flex-start'}
                            minWidth={'343px'}
                            fontWeight={'400'}
                            onClick={() => howToFindButtonProps?.onClick?.()}
                            variant="link"
                            as={'u'}
                        >
                            {lblhowtofind && (
                                <TextScript
                                    text={lblhowtofind}
                                    translationKey={`${pageName}/lblHowToFind`}
                                />
                            )}
                        </Button>
                    </Stack>
                )}
                {subheading3 && (
                    <Text fontFamily={'Apercu Pro'} fontWeight="light" fontSize="2xl" mb={'8px'}>
                        <TextScript
                            text={subheading3}
                            translationKey={`${pageName}/lblSubHeading3`}
                        />
                    </Text>
                )}
                {subheading3generateLabel && (
                    <Text fontFamily={'Apercu Pro'} fontWeight="light" fontSize="lg" mb={'8px'}>
                        <TextScript
                            text={subheading3generateLabel}
                            translationKey={`${pageName}/lblSubheading3Content1`}
                        />
                    </Text>
                )}
                {showGenerateLabel ?
                    (
                        <Box mb={'8px'}>
                            <ChakraProvider>
                                <PDFViewer pdfLabelBase64={pdfLabelBase64} />
                            </ChakraProvider>
                        </Box>
                    ) : null
                }
                {subheading3content1 && (
                    <Text fontFamily={'Apercu Pro'} fontWeight="light" fontSize="lg" mb={'24px'}>
                        <TextScript
                            text={subheading3content1}
                            translationKey={`${pageName}/lblSubheading3Content1`}
                        />
                    </Text>
                )}
                {/* {IncidentType === 'SWAP' && ( */}
                <Box>
                    {make && IncidentType?.toUpperCase() !== 'REPLACEMENT' && (
                        <Flex mb={'24px'}>
                            {/* <CloudIcon
                                marginTop={'6px'}
                                borderRadius={'64px'}
                                marginRight={'20px'}
                                flexShrink={0}
                            ></CloudIcon> */}
                            <Flex direction={'column'}>
                                {info1 && (
                                    <Text
                                        fontFamily={'Apercu Pro'}
                                        fontWeight="bold"
                                        fontSize="sm"
                                        m={0}
                                    >
                                        <TextScript
                                            text={info1}
                                            translationKey={`${pageName}/lblInfo11`}
                                        />
                                    </Text>
                                )}
                                {info1content && (
                                    <Text
                                        fontFamily={'Apercu Pro'}
                                        fontWeight="light"
                                        fontSize="sm"
                                    >
                                        <TextScript
                                            text={info1content}
                                            translationKey={`${pageName}/lblInfo1Content`}
                                        />
                                    </Text>
                                )}
                            </Flex>
                        </Flex>
                    )}
                    {make && IncidentType?.toUpperCase() !== 'REPLACEMENT' && (
                        <Flex mb={'24px'}>
                            <LockIcon
                                marginTop={'6px'}
                                borderRadius={'64px'}
                                marginRight={'20px'}
                                flexShrink={0}
                            ></LockIcon>
                            <Flex direction={'column'}>
                                {info2 && (
                                    <Text
                                        fontFamily={'Apercu Pro'}
                                        fontWeight="bold"
                                        fontSize="sm"
                                        m={0}
                                    >
                                        <TextScript
                                            text={info2}
                                            translationKey={`${pageName}/lblInfo2`}
                                        />
                                    </Text>
                                )}
                                {info2content && (
                                    <Text
                                        fontFamily={'Apercu Pro'}
                                        fontWeight="light"
                                        fontSize="sm"
                                    >
                                        <TextScript
                                            text={info2content}
                                            translationKey={`${pageName}/lblInfo2Content`}
                                        />
                                    </Text>
                                )}
                            </Flex>
                        </Flex>
                    )}
                    {IncidentType?.toUpperCase() === 'SWAP' || IncidentType?.toUpperCase() === 'UPGRADE' && (
                        <Flex mb={'24px'}>
                            {/* <CogIcon
                                marginTop={'4px'}
                                borderRadius={'64px'}
                                marginRight={'20px'}
                                flexShrink={0}
                            ></CogIcon> */}
                            <Flex direction={'column'}>
                                {info3 && (
                                    <Text
                                        fontFamily={'Apercu Pro'}
                                        fontWeight="bold"
                                        fontSize="sm"
                                        m={0}
                                    >
                                        <TextScript
                                            text={info3}
                                            translationKey={`${pageName}/lblInfo3`}
                                        />
                                    </Text>
                                )}
                                {info3content && (
                                    <Text
                                        fontFamily={'Apercu Pro'}
                                        fontWeight="light"
                                        fontSize="sm"
                                    >
                                        <TextScript
                                            text={info3content}
                                            translationKey={`${pageName}/lblInfo3content`}
                                        />
                                    </Text>
                                )}
                            </Flex>
                        </Flex>
                    )}
                    {LFLStock && (
                        <Box>
                            <Box mb={'34px'} pt={'8px'}>
                                <Flex
                                    direction={{ base: 'column', lg: 'row' }}
                                    justifyContent={{ base: 'start', lg: 'space-between' }}
                                >
                                    <Box
                                        w={{ base: 'full', lg: '50%' }}
                                        border={{ base: 'none', lg: '1px' }}
                                        padding={{ base: '0', lg: '10px 16px' }}
                                        borderRadius={{ base: 'none', lg: '16px' }}
                                        borderColor={{ base: 'none', lg: '#adaaaa' }}
                                    >
                                        <Text fontSize="2xl" fontWeight={'light'}>
                                            <TextScript
                                                text={serviceRequestdetails}
                                                translationKey={`${pageName}/serviceRequestdetails`}
                                            />
                                        </Text>
                                        {/* <Box fontWeight={700} fontSize={''} mt={2}>
                                        {Summarylbl}
                                    </Box> */}
                                        {RequestedServicelbl && (
                                            <Box fontWeight={700} mt={2}>
                                                <TextScript
                                                    text={RequestedServicelbl}
                                                    translationKey={`Global/RequestedService`}
                                                />
                                            </Box>
                                        )}
                                        <Box>
                                            <Text>{RequestedServicevalue}</Text>
                                        </Box>
                                        {IMEIlbl && (
                                            <Box fontWeight={700} mt={2}>
                                                <TextScript
                                                    text={IMEIlbl}
                                                    translationKey={`${pageName}/lblIMEI`}
                                                />
                                            </Box>
                                        )}
                                        <Box>
                                            <Text>{IMEIvalue}</Text>
                                        </Box>
                                        {RequestTypelbl && (
                                            <Box fontWeight={700} mt={2}>
                                                <TextScript
                                                    text={RequestTypelbl}
                                                    translationKey={`Global/RequestType`}
                                                />
                                            </Box>
                                        )}
                                        <Box>
                                            <Text>{RequestTypevalue}</Text>
                                        </Box>
                                        {alternativeContactNumber && (
                                            <Box mt={2} fontWeight={700}>
                                                <TextScript
                                                    text={'Alternate Contact Number'}
                                                    translationKey={`Global/AlternativeContactNumber`}
                                                />
                                            </Box>
                                        )}
                                        <Box>
                                            <Text>{alternativeContactNumber}</Text>
                                        </Box>
                                        {DeliveryAddresslbl && (
                                            <Box>
                                                <Text fontWeight={700} mt={2}>
                                                    {DeliveryAddresslbl}
                                                </Text>
                                            </Box>
                                        )}
                                        <Box>{Deliveryaddressvalue}</Box>
                                        {DeliveryDateTimelbl && (
                                            <Box fontWeight={700} mt={2}>
                                                <Text>{DeliveryDateTimelbl}</Text>
                                            </Box>
                                        )}
                                        <Box>
                                            <Text>{DeliveryDateTimevalue}</Text>
                                        </Box>
                                        <Box mt={2} fontWeight={700}>
                                            {returnAddrsLBL}
                                        </Box>
                                        <Box>
                                            <Text>{returnAddrsVal}</Text>
                                        </Box>
                                        <Box mt={2} fontWeight={700}>
                                            {returnDateTimeLBL}
                                        </Box>
                                        <Box>
                                            <Text>{returnDateTimeVal}</Text>
                                        </Box>
                                    </Box>{' '}
                                    {!isWarranty && (
                                        <>
                                            <Box display={{ base: 'none', lg: 'block' }}>
                                                <hr style={{ marginTop: '8px' }} />
                                            </Box>
                                            <Box mt={'16px'} mb={'16px'}>
                                                <hr
                                                    style={{
                                                        border: 'none',
                                                        borderTop: '2px solid #adaaaa',
                                                    }}
                                                />
                                            </Box>
                                            <Box w={{ base: 'full', lg: '45%' }}>
                                                {paymentBreakdown && (
                                                    <Text my={3} fontWeight={700}>
                                                        <TextScript
                                                            text={paymentBreakdown}
                                                            translationKey={`${pageName}/lblPaymentBreakdown`}
                                                        />
                                                    </Text>
                                                )}
                                                <Flex
                                                    mb={3}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                >
                                                    {serviceRequestFee && (
                                                        <Text>
                                                            {serviceRequestFee}
                                                            {/* <TextScript
                                                            text={serviceRequestFee}
                                                            translationKey={`${pageName}/lblServiceRequestFee`}
                                                        /> */}
                                                        </Text>
                                                    )}
                                                    <Text>{serviceRequestFeeAmount}</Text>
                                                </Flex>
                                                <Flex
                                                    mb={3}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                >
                                                    {dataTransferFeesLBL && (
                                                        <Text>
                                                            <TextScript
                                                                text={dataTransferFeesLBL}
                                                                translationKey={`${pageName}/lblDataTransferFees`}
                                                            />
                                                        </Text>
                                                    )}
                                                    <Text>{dataTransferFeesVal}</Text>
                                                </Flex>
                                                <Flex
                                                    mb={3}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                >
                                                    {nightDelivery && (
                                                        <Text>
                                                            <TextScript
                                                                text={nightDelivery}
                                                                translationKey={`${pageName}/lblNightDelivery`}
                                                            />
                                                        </Text>
                                                    )}
                                                    <Text>{nightDeliveryAmount}</Text>
                                                </Flex>
                                                {!isWarranty && (
                                                    <hr
                                                        style={{
                                                            border: 'none',
                                                            borderTop: '2px dotted #adaaaa',
                                                        }}
                                                    />
                                                )}
                                                <Flex
                                                    my={2}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                >
                                                    {subTotal && (
                                                        <Text>
                                                            <TextScript
                                                                text={subTotal}
                                                                translationKey={`Global/Subtotal`}
                                                            />
                                                        </Text>
                                                    )}
                                                    <Text>{subTotalAmount}</Text>
                                                </Flex>
                                                <Flex
                                                    mb={2}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                >
                                                    <Text>{Discount}</Text>
                                                    <Text>{DiscountAmount}</Text>
                                                </Flex>
                                                <Flex
                                                    mb={2}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                >
                                                    {TotalAfterDiscount && (
                                                        <Text fontWeight={700}>
                                                            <TextScript
                                                                text={TotalAfterDiscount}
                                                                translationKey={`Global/Total`}
                                                            />
                                                        </Text>
                                                    )}
                                                    <Text fontWeight={700}>
                                                        {TotalAmountAfterDiscount}
                                                    </Text>
                                                </Flex>
                                            </Box>
                                            <Box mt={'16px'} mb={'16px'}>
                                                <hr
                                                    style={{
                                                        border: 'none',
                                                        borderTop: '2px solid #adaaaa',
                                                    }}
                                                />
                                            </Box>
                                        </>
                                    )}
                                </Flex>
                                {/* <hr style={{ marginTop: '8px' }} />
                        
                        <hr /> */}
                            </Box>
                        </Box>
                    )}
                </Box>
                {/* )} */}
            </Box>
            <Box
                position={{ base: 'relative', lg: 'fixed' }}
                bottom="0"
                w="full"
                mt={'36px'}
                pb="10px"
                bgColor={{ base: '', lg: '#faf9f5' }}
                display={showSurvey ? 'block' : 'none'}
            >
                <Box display={{ base: 'none', lg: 'block' }}>
                    <hr
                        style={{
                            border: 'none',
                            borderTop: '2px solid #adaaaa',
                        }}
                    />
                </Box>
                <Flex direction={{ base: 'column', lg: 'row-reverse' }} align={'center'} py="10px">
                    <Button
                        paddingX={'16px'}
                        paddingY={'12px'}
                        colorScheme={'primary'}
                        // minWidth={'343px'}
                        w={{ base: 'full', lg: '13%' }}
                        margin={'auto'}
                        onClick={() => rateButtonProps?.onClick?.()}
                    >
                        {lblrate && (
                            <TextScript text={lblrate} translationKey={`${pageName}/lblRate`} />
                        )}
                    </Button>
                    <Button
                        paddingX={'16px'}
                        paddingY={'12px'}
                        color={accent}
                        // minWidth={'343px'}
                        w={{ base: 'full', lg: '5%' }}
                        margin={'auto'}
                        onClick={() => closeButtonProps?.onClick?.()}
                        cursor="pointer"
                        variant="link"
                        as={'u'}
                    >
                        {lblclose && (
                            <TextScript text={lblclose} translationKey={`Global/CloseThisPage`} />
                        )}
                    </Button>
                </Flex>
            </Box>
        </Box>
    );
};
