import {
    ReviewSubmission,
    SubHeader,
    getCarrierByDomain,
    getTypeByDomain,
    useAccount,
} from '@MGPD/myasurion-shared';
import { getTextScript, useSessionStorage } from '@MGPD/myasurion-shared';
import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../Scripts/asurion-script.json';
import ReviewScripts from '../Scripts/asurion-script.json';
import translationsKeys from '../Scripts/translationsKeys.json';
import MainStackLayout from '../layout/MainStackLayout';
import {
    ConfirmDeviceKey,
    LFLStockKey,
    ResumeFlowDataKey,
    assetDataKey,
    btChargeOrderKey,
    btProcessPaymentKey,
    btSubmitOrderKey,
    deliveryAddressKey,
    nightDeliveryFeeKey,
    processIncidentKey,
    serviceFeeKey,
    storeAddressKey,
    storeIdKey,
} from '../modules/config/constants';
import { updateShippingOrderResponseKey } from '../modules/config/constants';
import {
    chargeOrderApi,
    chargeOrderApiBrainTree,
    createRepairRequestApi,
    logGTMEvent,
    processPaymentApi,
    submitOrderApi,
} from '../services/api.ts';
import ActionTypes from '../store/actionTypes';
// import { ConfirmDeviceKey, assetDataKey, nightDeliveryFeeKey } from '../modules/config/constants';
import { useCentralStore } from '../store/appContext';
import { GTM_EVENT, INCIDENT_TYPES } from '../utils/constant.tsx';
import {
    hasAddRepresentativeFeature,
    hasMultiDeviceSupport,
    hideIWHeaderText,
    isMalfuntionWarrantyEligible,
    skipReturnSlotSelection,
    isJBUpgradeFlow
} from '../utils/featuresUtills.tsx';
import { getDeviceImageByModel } from '../utils/get-device-images';
import { camelize, getAssetCapacity } from '../utils/helper.tsx';
import {
    getCurrencySymbol,
    getGlobalDateFormat,
    getPerilText,
} from '../utils/localization-helper.tsx';
import {
    callLoggerApi,
    convertDateToUTC,
    getDateAndDay,
    getGTMData,
    getServiceRequestFee,
    getTime,
    isBatteryReplacement,
    isDelivery as isDeliveryType,
    isDeviceRefresh,
    isDeviceRefreshFree,
    isDeviceRepair,
    isEWSClaim,
    isInWarranty,
    isInWarrantyOEM,
    isMalfunction,
    isPUR,
    isReplacement,
    isScreenRepair,
    isScreenRepairBatReplacement,
    isScreenRepairWarranty,
    isSwap,
    isWalkIn as isWalkInType,
    saveError,
    showErrorPage,
    timeConvert24to12Hr,
} from '../utils/utills.tsx';
import CancelSrPopup from './myclaims/CancelSrPopup.tsx';

// import { getClientToken } from '../services/api.ts';
// import { useQuery } from '@tanstack/react-query';

function Reviewsubmission() {
    const navigate = useNavigate();
    const globalState = useCentralStore();
    const [updateShippingOrderSession] = useSessionStorage<any>(
        updateShippingOrderResponseKey,
        null
    );
    const enableReviewBtn =
        globalState?.state?.ShippingOrderUpdateResults !== '' ||
        updateShippingOrderSession?.data !== ''
            ? true
            : false;

    const [account] = useAccount({ firstname: 'firstName', lastname: 'lastName', email: 'email' });
    const showLoader = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };
    const [LFLStock] = useSessionStorage<any>(LFLStockKey, null);
    const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
    const reviewcripts = ReviewScripts?.ReviewSubmission;
    const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
    const R_ServiceRequestId = R_ServiceRequests?.ServiceRequestId;
    const R_CustomerCaseId = R_ServiceRequests?.CustomerCaseId;
    const R_ServiceOrderId = R_ServiceRequests?.ServiceOrder?.ServiceOrderId;
    const R_ServiceOrderNumber = R_ServiceRequests?.ServiceOrder?.ServiceOrderNumber;
    const R_ReplacementAsset = ResumeFlowData?.ReplacementAsset;
    const R_IMEI = R_ServiceRequests?.EnrolledDevice?.IMEI;
    const R_IncidentType = ResumeFlowData?.IncidentType;

    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    // const [deviceData,] = useState<string>("");
    const processIncData = globalState?.state?.processIncData;

    const agreementData: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const clientOfferName = agreementData?.ClientOffer?.ClientOfferName;
    const isUpgradeFlow = isJBUpgradeFlow(clientOfferName);
    const [assetData] = useSessionStorage<any>(assetDataKey, null);
    const [deliveryAddress] = useSessionStorage<any>(deliveryAddressKey, null);
    const [deliveryDate] = useSessionStorage<any>('deliveryDate', null);
    const [deliveryTime] = useSessionStorage<any>('deliveryTime', null);
    const [srDeliveryType] = useSessionStorage<any>('deliveryType', null);
    const [proxyUserData] = useSessionStorage<any>('proxyUserData', null);
    const isDelivery = isDeliveryType(srDeliveryType);
    const [walkingAddressarray] = useSessionStorage<any>(storeAddressKey, null);
    const isPickup = isPUR(srDeliveryType);
    const isWalkin = isWalkInType(srDeliveryType);
    // const [walkingAddressarray] = useSessionStorage<any>('getaspstorelistData', null);
    const [confirmedDevice] = useSessionStorage<any>(ConfirmDeviceKey, null);
    const walkingAddress = walkingAddressarray; //&& walkingAddressarray[0];
    // const [walkingAddress] = useSessionStorage<any>('storeAddress', null);
    const [walkingDate] = useSessionStorage<any>('storeDate', null);
    const [walkingTime] = useSessionStorage<any>('storeTime', null);
    const [NighDeliverytFees] = useSessionStorage<any>(nightDeliveryFeeKey, null);
    const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);
    const [, setSubmitOrderResponse] = useSessionStorage<any>(btSubmitOrderKey, null);
    const [, setChargeOrderResponse] = useSessionStorage<any>(btChargeOrderKey, null);
    const [, setProcessPaymentResponse] = useSessionStorage<any>(btProcessPaymentKey, null);
    const [StoreId] = useSessionStorage<any>(storeIdKey, null);

    const [, setPaymentError] = useState<string>('');
    const initializeData = globalState?.state?.sessionResponse?.InitializeResponse;
    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const _ServiceOrderId =
        globalState?.state?.serviceOrderData?.CreateServiceOrderResults?.ServiceOrderId ||
        R_ServiceOrderId;
    const _ServiceOrderNumber =
        globalState?.state?.serviceOrderData?.CreateServiceOrderResults?.ServiceOrderNumber ||
        R_ServiceOrderNumber;
    const _locationCoordinates = globalState?.state?.locationCoordinates;

    const find_agreement = globalState?.state?.currentAgreementData;

    const incType =
        globalState?.state?.processIncData?.ServiceRequest?.IncidentType || R_IncidentType;

    const returnDate = globalState?.state?.returnDate;
    const returnTime = globalState?.state?.returnTime;

    const IsWarranty =
        isInWarranty(serviceRequestData) ||
        isEWSClaim(serviceRequestData, incType) ||
        isMalfuntionWarrantyEligible(find_agreement?.ClientOffer?.ClientOfferName);

    // const IncidentType =
    //   serviceRequestData?.ServiceRequest?.IncidentType.toUpperCase() === 'FAULT REPAIR'
    //     ? 'Device Repair'
    //     : serviceRequestData?.ServiceRequest?.IncidentType.toUpperCase() === 'BAT SCR REPLACEMENT'
    //     ? 'Screen Repair and Battery Replacement'
    //     : serviceRequestData?.ServiceRequest?.IncidentType;

    // const getDisplayIncidenType = isScreenRepairBatReplacement(incType)
    //     ? 'Screen Repair and Battery Replacement'
    //     : incType;

    const [PerilType, setPerilType] = useState('');
    const [FulFillmentOptionType, setFulFillmentOptionType] = useState('');
    const [RequestedService, setRequestedService] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [dateFormat, setDateFormat] = useState<string>('');
    const [ServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);

    const isTOM =
        globalState?.state?.sessionResponse?.InitializeResponse?.ClientName === 'Asurion_TechCare';
    const isRepair =
        isDeviceRefresh(incType) ||
        isDeviceRepair(incType) ||
        isBatteryReplacement(incType) ||
        isScreenRepair(incType) ||
        isScreenRepairBatReplacement(incType) ||
        isScreenRepairWarranty(incType) ||
        (isMalfunction(incType) && isInWarrantyOEM(serviceRequestData)) ||
        isMalfuntionWarrantyEligible(find_agreement?.ClientOffer?.ClientOfferName);
    isEWSClaim(serviceRequestData, incType);

    // const deviceName = find_agreement?.Assets?.Asset.filter(
    //   (asset: any) => asset?.AssetInstance === 'ENROLLED'
    // )?.[0];

    let deviceName = find_agreement?.Assets?.Asset?.filter(
        (asset: any) => asset?.AssetInstance === 'ENROLLED'
    )?.[0];
    if (deviceName === undefined) {
        deviceName = find_agreement?.Assets?.Asset?.filter(
            (asset: any) => asset?.AssetInstance === 'REPLACED'
        )?.[0];
    }

    const userAddress = hasMultiDeviceSupport(find_agreement?.ClientOffer?.ClientOfferName)
        ? deliveryAddress
        : agreementData?.Address;

    const LFLSRFFees = getServiceRequestFee(ServiceFeeData, incType);
    // ServiceFeeData &&
    // ServiceFeeData?.filter((item: any) => item?.IncidentType === R_IncidentType?.toUpperCase())?.[0]
    //   ?.Fees?.TotalAmount;

    const AssetFamily =
        (confirmedDevice && confirmedDevice?.familyName) ||
        (confirmedDevice &&
            camelize(confirmedDevice[0].HorizonItemInformation.AssetFamily.toLowerCase()) +
                ' ' +
                getAssetCapacity(confirmedDevice[0]) +
                ' ' +
                confirmedDevice[0].SCMItemInformation.Color) ||
        (R_ReplacementAsset &&
            camelize(R_ReplacementAsset?.Make?.toLowerCase()) +
                ' ' +
                camelize(R_ReplacementAsset?.AssetCatalogName.split(' ')?.slice(1)?.join(' '))) ||
        camelize(deviceName?.Make?.Name?.toLowerCase()) +
            ' ' +
            camelize(
                deviceName?.AssetCatalog?.AssetCatalogName.split(' ')?.slice(1)?.join(' ')
            )?.replace(/iphone/gi, 'iPhone');

    const image =
        (confirmedDevice && confirmedDevice?.img) ||
        (confirmedDevice &&
            getDeviceImageByModel(
                globalState?.state?.deviceImages,
                confirmedDevice[0].HorizonItemInformation.AssetFamily,
                '',
                100
            )) ||
        getDeviceImageByModel(
            globalState?.state?.deviceImages,
            assetData?.AssetCatalog.AssetFamily,
            assetData?.Model?.Color ?? '',
            100
        );

    const R_SRF =
        ResumeFlowData?.ServiceFee?.ServiceFee &&
        parseFloat(ResumeFlowData?.ServiceFee?.ServiceFee)?.toFixed(2);

    const [totalSRFee, setTotalSRFee] = useState<any>(globalState?.state?.srf || R_SRF);

    const hasDTAddOnAcquired = false; //globalState?.state?.doorStepAddOnData?.hasAddOnAcquired || ResumeFlowData?.IsDataTransfer;

    useEffect(() => {
        setTotalSRFee(
            (
                Number(globalState?.state?.srf || R_SRF || LFLSRFFees) +
                Number(globalState?.state?.nightDeliveryFee) +
                Number(globalState?.state?.doorStepAddOnData.fee)
            )?.toFixed(2)
        );
        globalState?.dispatch({
            type: ActionTypes.SRF,
            payload: (
                Number(globalState?.state?.srf || R_SRF || LFLSRFFees) +
                Number(globalState?.state?.nightDeliveryFee) +
                Number(globalState?.state?.doorStepAddOnData.fee)
            )?.toFixed(2),
        });
    }, [
        globalState?.state?.nightDeliveryFee,
        R_SRF,
        LFLSRFFees,
        globalState?.state?.doorStepAddOnData?.fee,
    ]);

    const onCancelClick = () => {
        setOpenCancelPopup(!openCancelPopup);
    };
    //API Calls for InWarranty

    const chargeOrderRequest = () => {
        let ChargeOrder = {
            PaymentMethodType: 'COD',
            ChargeOrderStatus: 'PREAUTH',
            AdditionalChargeAuth: 'false',
        };
        callChargeOrderApi(ChargeOrder);
    };

    const callSubmitOrder = async () => {
        showLoader(true);
        const SubmitServiceOrderParameters = {
            ServiceRequestId:
                processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
            InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
            ServiceOrderId: _ServiceOrderId,
        };
        try {
            // navigate to final page and then call submit API in background
            showLoader(false);
            // sessionStorage.removeItem('ResumeFlowData');
            navigate('/claimconfirmation', { replace: true });

            submitOrderApi(
                { SubmitServiceOrderParameters },
                globalState?.state?.findAgreementSessionData
            )
                .then((response: any) => {
                    setSubmitOrderResponse(response?.SubmitServiceOrderResults);
                })
                .catch((error) => {
                    console.error(error);
                    callLoggerApi(error, globalState);
                });
        } catch (error) {
            showLoader(false);
            // navigate('/claimconfirmation')
            console.error(error);
            // onApiFailure(ActionTypes.PROCESS_PAYMENT, error);
            setPaymentError('Payment failed');
            // saveError(error, globalState);
            // showErrorPage(navigate)
        }
    };

    const callCreateRepairRequest = async (
        ChargeOrder: any,
        _payment_method: any,
        _chargOrderPayload: any
    ) => {
        showLoader(true);
        let CreateRepairRequestParams = {
            InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
            CustomerCaseId: processIncData?.CustomerCaseId || R_CustomerCaseId,
            ServiceRequestId:
                processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
            ServiceOrderId: _ServiceOrderId,
            RepairRequestType: isPickup ? 'PUR' : 'WALKIN',
            ServiceProviderId: isWalkin ? StoreId : undefined,
            AssetMake: 'APPLE',
            Longitude: _locationCoordinates?.lng, //103.840400,
            Lattitude: _locationCoordinates?.lat, //1.428850,
            AppointmentDate: isWalkin
                ? convertDateToUTC(
                      `${walkingDate} ${walkingTime}`,
                      'YYYY-MM-DD HH:mm:ssa',
                      initializeData?.TimeZone
                  )
                : globalState?.state?.pickupDate,
        };

        createRepairRequestApi(
            { CreateRepairRequestParams },
            interactionData?.InteractionLine.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((data: any) => {
                console.log('repairrequest repairrequestResponse', data);
                const GTMData = getGTMData(
                    GTM_EVENT.CREATE_REPAIR_REQUEST_EVENT,
                    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements
                        ?.Agreement[0],
                    getCarrierByDomain(),
                    getTypeByDomain(),
                    globalState?.state?.currentAgreementData?.CustomerCaseNumber
                );
                logGTMEvent(GTMData);
                if (
                    isMalfunction(incType) &&
                    (isInWarrantyOEM(serviceRequestData) || isEWSClaim(serviceRequestData, incType))
                )
                    callProcessPayment(ChargeOrder, _payment_method, _chargOrderPayload);
                else callSubmitOrder();
            })
            .catch((error) => {
                showLoader(false);
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    const chargeOrderV1Request = () => {
        showLoader(true);
        const ChargeOrder = {
            PaymentMethodType: 'COD',
            IncidentType: incType,
            ChargeOrderStatus: 'PREAUTH',
            AddressId: agreementData?.billingAddress?.AddressId,
        };

        const CreateChargeOrderParameters: any = {
            InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
            ServiceRequestId: serviceRequestData?.ServiceRequest?.ServiceRequestId, //processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
            CustomerCaseId: serviceRequestData?.CustomerCaseId, //processIncData?.CustomerCaseId || R_CustomerCaseId,
            AgreementId: agreementData?.AgreementId,
            ClientId: interactionData?.ClientId,
            ClientChannelId: interactionData?.ClientChannelId,
            // FulfillmentOption: "RPLCMNT",
            CustomerCaseNumber:
                serviceRequestData?.CustomerCaseNumber || ResumeFlowData?.CustomerCaseNumber,
            ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
            MobileNumber: assetData?.MobileDeviceNumber,
            ChargeOrder: ChargeOrder,
            ServiceOrderId: _ServiceOrderId,
        };
        chargeOrderApi(
            { CreateChargeOrderParameters },
            _ServiceOrderId,
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((data: any) => {
                showLoader(false);
                const ChargeOrderResponse = data?.CreateChargeOrderResults?.ChargeOrder;
                setChargeOrderResponse(ChargeOrderResponse);

                const chargeOrderHold = data?.CreateChargeOrderResults.Holds;
                const isHold = (holds: any) => {
                    // return holds.some(hold => hold.HoldType === 'SRVAL' && hold.HoldStatus === "OPEN");
                    // not allowing to move ahead for any hold
                    return holds.some((hold: any) => hold.HoldStatus === 'OPEN');
                };

                if (chargeOrderHold?.length > 0 && isHold(chargeOrderHold)) {
                    //changes required for HOLD
                    navigate('../reviewhold', { replace: true });
                } else if (isRepair) {
                    callCreateRepairRequest(
                        ChargeOrderResponse,
                        ChargeOrder?.PaymentMethodType,
                        ChargeOrder
                    );
                } else {
                    callProcessPayment(
                        ChargeOrderResponse,
                        ChargeOrder?.PaymentMethodType,
                        ChargeOrder
                    );
                }
            })
            .catch((err: any) => {
                showLoader(false);
                setPaymentError('Payment failed');
                saveError(err, globalState);
                showErrorPage(navigate);
            });
    };

    const callProcessPaymentApi = async (
        ProcessPaymentParameters: any,
        ServiceOrderId: any,
        ChargeOrderId: any
    ) => {
        if (ChargeOrderId) {
            showLoader(true);

            processPaymentApi(
                { ProcessPaymentParameters },
                ServiceOrderId,
                ChargeOrderId,
                interactionData?.InteractionLine?.InteractionLineId,
                globalState?.state?.findAgreementSessionData
            )
                .then((response) => {
                    setProcessPaymentResponse(response?.ProcessPaymentResults);
                    callSubmitOrder();
                })
                .catch((error) => {
                    showLoader(false);
                    setPaymentError('Payment failed');
                    saveError(error, globalState);
                    showErrorPage(navigate);
                });
        }
    };

    const callProcessPayment = (
        ChargeOrder: any,
        _payment_method: any,
        _chargOrderPayload: any
    ) => {
        const ProcessPaymentParameters: any = {
            ChargeOrderId: ChargeOrder?.ChargeOrderId,
            ServiceOrderId: _ServiceOrderId,
            ApplicationId: initializeData?.ApplicationId,
            ClientId: initializeData?.ClientId,
            InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
            ServiceRequestId:
                processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
            ServiceOrderNumber: _ServiceOrderNumber?.toString(),
            AuthorizeAdditionalCharges: false,
            ValidateAddress: false,
            ValidateCardSecurityCode: false,
            IpAddressForFraud: interactionData?.SourceIpAddress,
            FulfillmentOption: 'RPLCMNT',
            PaymentInformation: {
                PaymentMethodType: 'COD',
            },
            Address: userAddress,
            ContactDetails: {
                phone: assetData?.MobileDeviceNumber,
                workPhone: assetData?.MobileDeviceNumber,
                cellPhone: assetData?.MobileDeviceNumber,
                email: account?.email,
            },
            AutoCompleteId: ChargeOrder?.AutoCompleteId,
        };

        callProcessPaymentApi(
            ProcessPaymentParameters,
            _ServiceOrderId,
            ChargeOrder?.ChargeOrderId
        );
    };

    const callChargeOrderApi = async (ChargeOrder: any) => {
        const _payment_method = ChargeOrder?.PaymentMethodType;
        const _chargOrderPayload = ChargeOrder;
        const CreateChargeOrderParameters: any = {
            InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
            ServiceRequestId: serviceRequestData?.ServiceRequest?.ServiceRequestId, //processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
            CustomerCaseId: serviceRequestData?.CustomerCaseId, //processIncData?.CustomerCaseId || R_CustomerCaseId,
            AssetCategoryId:
                (hasMultiDeviceSupport(find_agreement?.ClientOffer?.ClientOfferName) ||
                isMalfuntionWarrantyEligible(find_agreement?.ClientOffer?.ClientOfferName))
                    ? assetData?.AssetCategory?.AssetCategoryId
                    : undefined,
            AgreementId: agreementData?.AgreementId,
            ClientId: interactionData?.ClientId,
            ClientChannelId: interactionData?.ClientChannelId,
            ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
            MobileNumber: assetData?.MobileDeviceNumber,
            ChargeOrder: ChargeOrder,
            ServiceOrderId: _ServiceOrderId,
            // IsRedeliveryFee: isReschedulePickupDelivery ? true : false,
        };
        showLoader(true);

        chargeOrderApiBrainTree(
            { CreateChargeOrderParameters },
            _ServiceOrderId,
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((response) => {
                const GTMData = getGTMData(
                    GTM_EVENT.CHARGE_ORDER_EVENT,
                    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements
                        ?.Agreement[0],
                    getCarrierByDomain(),
                    getTypeByDomain(),
                    globalState?.state?.currentAgreementData?.CustomerCaseNumber
                );
                logGTMEvent(GTMData);
                const ChargeOrder = response?.CreateChargeOrderResults?.ChargeOrder;
                setChargeOrderResponse(ChargeOrder);
                const chargeOrderHold = response?.CreateChargeOrderResults.Holds;
                const isHold = (holds: any) => {
                    return holds.some((hold: any) => hold.HoldStatus === 'OPEN');
                };
                if (chargeOrderHold?.length > 0 && isHold(chargeOrderHold)) {
                    navigate('../reviewhold', { replace: true });
                } else if (isRepair) {
                    callCreateRepairRequest(ChargeOrder, _payment_method, _chargOrderPayload);
                } else {
                    callProcessPayment(ChargeOrder, _payment_method, _chargOrderPayload);
                }
            })
            .catch((error) => {
                showLoader(false);
                setPaymentError('Payment failed');
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    const onSubmit = () =>
        // response: any, paymentMethodResp: any, paymentMethod: any, deviceData: any
        {
            if (globalState?.state?.ShippingOrderUpdateResults?.Holds?.length > 0) {
                navigate('../reviewhold', { replace: true });
            } else if (
                globalState?.state?.selectedPaymentMethod === 'Cash on Delivery' ||
                IsWarranty ||
                Number(totalSRFee) === 0 ||
                isDeviceRefreshFree(find_agreement, incType)
            ) {
                isTOM ? chargeOrderV1Request() : chargeOrderRequest(); // for Maxis and Starhub call chargeorder with BrainTree
            } else {
                navigate('/payment');
            }
        };

    const onBackClick = () => {
        window.history.back();
    };

    useEffect(() => {
        getDateFormat();
        getCurrency();
        getPerilLbl();
        getFullFillmentOption();
        const GTMData = getGTMData(
            GTM_EVENT.REVIEW_SUBMISSION_SCREEN_EVENT,
            globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0],
            getCarrierByDomain(),
            getTypeByDomain(),
            globalState?.state?.currentAgreementData?.CustomerCaseNumber
        );
        logGTMEvent(GTMData);
    }, []);

    useEffect(() => {
        if (PerilType && PerilType?.length > 0) {
            getPickupAddressLBL();
            getDeliveryAddressLBL();
            getCollectionAddressLBL();
            getCollectionAddressLBLSwap();
            getPickupDateTimeLBLSwap();
            getDeliveryDateTimeLBL();
            getCollectionDateTimeLBL();
            // getbatteryReplacementFeeLBL()
            getSRFLBL();
            getRepairSRFtext();
            getServiceTypeLBL();
        }
    }, [PerilType]);

    const getPerilLbl = async () => {
        const incidentType = isInWarrantyOEM(serviceRequestData)
            ? INCIDENT_TYPES.MALFUNCTION_IW
            : isEWSClaim(serviceRequestData, incType)
            ? INCIDENT_TYPES.MALFUNCTION_EWS
            : incType;
        const defaultIncidentTypeLBL = isInWarrantyOEM(serviceRequestData)
            ? Scripts.Global.MalfunctionIW
            : isEWSClaim(serviceRequestData, incType)
            ? Scripts.Global.MalfunctionEWS
            : incType;

        const peril = await getPerilText(incidentType, defaultIncidentTypeLBL);
        setPerilType(peril);
    };

    const getFullFillmentOption = async () => {
        const fulFillmentType = await getPerilText(srDeliveryType?.toUpperCase(), srDeliveryType);
        setFulFillmentOptionType(fulFillmentType);
    };

    const getCurrency = async () => {
        const currency = await getCurrencySymbol();
        setCurrencySymbol(currency);
    };

    const getDateFormat = async () => {
        const format = await getGlobalDateFormat();
        setDateFormat(format);
    };

    const ReviewSubmissiontranslationKey = translationsKeys.ReviewSubmissionPage;
    const [PickupAddressLBLText, setPickupAddressLBLText] = useState('');
    const [DeliveryAddressLBLText, setDeliveryAddressLBLText] = useState('');
    const [CollectionAddressLBLText, setCollectionAddressLBLText] = useState('');
    const [CollectionAddressLBLSwapText, setCollectionAddressLBLSwapText] = useState('');
    const [PickupDateTimeLBLSwapText, setPickupDateTimeLBLSwapText] = useState('');
    const [DeliveryDateTimeLBLText, setDeliveryDateTimeLBLText] = useState('');
    const [CollectionDateTimeLBLText, setCollectionDateTimeLBLText] = useState('');
    // const [batteryReplacementFeeText,setbatteryReplacementFeeText] = useState('')
    const [SRFLBLText, setSRFLBLText] = useState('');
    const [RepairSRFText, setRepairSRFtext] = useState('');

    const getPickupAddressLBL = async () => {
        const PickupAddressLBL = await getTextScript(
            `${translationsKeys.Global.PageName}/${translationsKeys.Global.PickUpAddress}`,
            Scripts.Global.PickUpAddress
        );
        setPickupAddressLBLText(PickupAddressLBL);
    };
    const getDeliveryAddressLBL = async () => {
        const DeliveryAddressLBL = await getTextScript(
            `${ReviewSubmissiontranslationKey.PageName}/${ReviewSubmissiontranslationKey.DeliveryAddressLBL}`,
            `${reviewcripts?.DeliveryAddressLBL}`
        );
        setDeliveryAddressLBLText(DeliveryAddressLBL);
    };
    const getCollectionAddressLBL = async () => {
        const CollectionAddressLBL = await getTextScript(
            `${ReviewSubmissiontranslationKey.PageName}/${ReviewSubmissiontranslationKey.CollectionAddressLBL}`,
            `${reviewcripts?.CollectionAddressLBL}`
        );
        setCollectionAddressLBLText(CollectionAddressLBL);
    };
    const getCollectionAddressLBLSwap = async () => {
        const CollectionAddressLBLSwap = await getTextScript(
            `${ReviewSubmissiontranslationKey.PageName}/${ReviewSubmissiontranslationKey.CollectionAddressLBLSwap}`,
            `${reviewcripts?.CollectionAddressLBLSwap}`
        );
        setCollectionAddressLBLSwapText(CollectionAddressLBLSwap);
    };
    const getPickupDateTimeLBLSwap = async () => {
        const PickupDateTimeLBLSwap = await getTextScript(
            `${translationsKeys.Global.PageName}/${translationsKeys.Global.PickUpDateTime}`,
            Scripts.Global.PickUpDateTime
        );
        setPickupDateTimeLBLSwapText(PickupDateTimeLBLSwap);
    };
    const getDeliveryDateTimeLBL = async () => {
        const DeliveryDateTimeLBL = await getTextScript(
            `${ReviewSubmissiontranslationKey.PageName}/${ReviewSubmissiontranslationKey.DeliveryDateTimeLBL}`,
            `${reviewcripts?.DeliveryDateTimeLBL}`
        );
        setDeliveryDateTimeLBLText(DeliveryDateTimeLBL);
    };
    const getCollectionDateTimeLBL = async () => {
        const CollectionDateTimeLBL = await getTextScript(
            `${ReviewSubmissiontranslationKey.PageName}/${ReviewSubmissiontranslationKey.CollectionDateTimeLBL}`,
            `${reviewcripts?.CollectionDateTimeLBL}`
        );
        setCollectionDateTimeLBLText(CollectionDateTimeLBL);
    };
    // const getbatteryReplacementFeeLBL = async () => {
    //   const batteryReplacementFeeLBL = await getTextScript(
    //     `${ReviewSubmissiontranslationKey.PageName}/${ReviewSubmissiontranslationKey.batteryReplacementFeeLBL}`,
    //     `${Scripts?.Payment?.batteryReplacementFee}`
    //   );
    //   setbatteryReplacementFeeText(batteryReplacementFeeLBL);
    // }
    const getSRFLBL = async () => {
        const SRFLBL = await getTextScript(
            `${translationsKeys.Global.PageName}/${translationsKeys.Global.SRFLBL}`,
            ReviewScripts.Global.SRFLBL
        );
        setSRFLBLText(SRFLBL);
    };

    const getRepairSRFtext = async () => {
        const PlaceHolderText = await getTextScript(
            `${translationsKeys.Global.PageName}/${translationsKeys.Global.RepairFeeLBL}`,
            Scripts.Global.RepairFeeLBL
        );
        setRepairSRFtext(PlaceHolderText);
    };

    const getServiceTypeLBL = async () => {
        let _PerilType =
            PerilType?.toLowerCase() === INCIDENT_TYPES.FAULT_REPAIR?.toLowerCase()
                ? INCIDENT_TYPES.DEVICE_REPAIR
                : PerilType;
        let serviceLbl = _PerilType;

        if (
            IsWarranty &&
            isRepair &&
            !hideIWHeaderText(agreementData?.ClientOffer?.ClientOfferName)
        ) {
            serviceLbl = `In-Warranty ${_PerilType}`;
        }

        setRequestedService(serviceLbl);
    };

    const getDeliverySlot = (time: any) => {
        if (isTOM) {
            return timeConvert24to12Hr(time);
        } else {
            // if(type === 'return'){
            //   return timeConvert24to12Hr(formatReturnTime(time))
            // }else{
            return time;
            // }
        }
    };

    return (
        <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'}>
            <Box w={{ base: 'full', lg: '80%' }} marginX={{ base: '', lg: 'auto' }}>
                <SubHeader
                    onClick={onBackClick}
                    backText={'Back'}
                    allowBackButtonClick={false}
                    showCancelButton={true}
                    showBackButton={hasAddRepresentativeFeature(
                        agreementData?.ClientOffer?.ClientOfferName
                    )}
                    cancelText={ReviewScripts.CancelSR.cancelBtnText}
                    onCancelClick={onCancelClick}
                ></SubHeader>
            </Box>
            <MainStackLayout gap={0} spacing={0}>
                {dateFormat && dateFormat?.length > 0 && (
                    <ReviewSubmission
                        title={reviewcripts?.Title}
                        deviceName={`${AssetFamily?.replace(/iphone/gi, 'iPhone')?.replace(
                            'gb',
                            'GB'
                        )}`}
                        image={
                            hasMultiDeviceSupport(agreementData?.ClientOffer?.ClientOfferName) ? (
                                <></>
                            ) : (
                                image
                            )
                        }
                        requestedServiceLBL={reviewcripts?.RequestedServiceLBL}
                        // requestedServiceVal={IsWarranty && isRepair? `In-Warranty ${IncidentType|| R_IncidentType}` : IsWarranty && !isRepair ? IncidentType || R_IncidentType :IncidentType || R_IncidentType}
                        requestedServiceVal={RequestedService || ''}
                        imeiLBL={reviewcripts?.ImeiLBL}
                        imeiVal={assetData?.IMEI || R_IMEI}
                        requestedTypeLBL={Scripts.Global?.RequestType}
                        // requestedTypeVal={`${
                        //   isDelivery && srDeliveryType === 'Delivery' ? 'Delivery' : 'Walkin'
                        // }`}
                        requestedTypeVal={FulFillmentOptionType || srDeliveryType}
                        deliveryAddrsLBL={
                            isPickup
                                ? `${PickupAddressLBLText}`
                                : isDelivery
                                ? `${DeliveryAddressLBLText}`
                                : isRepair
                                ? `${CollectionAddressLBLText}`
                                : `${CollectionAddressLBLSwapText}`
                        }
                        deliveryAddrsVal={
                            isPickup
                                ? globalState?.state?.pickUpAddress
                                : isDelivery
                                ? `${
                                      deliveryAddress.Address1
                                          ? deliveryAddress.Address1 + ', '
                                          : ''
                                  }${
                                      deliveryAddress.Address2
                                          ? deliveryAddress.Address2 + ', '
                                          : ''
                                  }${
                                      deliveryAddress.Address3
                                          ? deliveryAddress.Address3 + ', '
                                          : ''
                                  }${
                                      deliveryAddress.StateProvinceCode
                                          ? deliveryAddress.StateProvinceCode + ', '
                                          : ''
                                  }${deliveryAddress.PostalCode || ''}`
                                : `${
                                      walkingAddress?.RepairStoreName
                                          ? walkingAddress.RepairStoreName + ', '
                                          : ''
                                  }${
                                      walkingAddress?.AddressLine1
                                          ? walkingAddress.AddressLine1 + ', '
                                          : ''
                                  }${walkingAddress?.City ? walkingAddress.City + ', ' : ''}${
                                      walkingAddress?.PostalCode || ''
                                  }`
                        }
                        deliveryDateTimeLBL={
                            isPickup
                                ? `${PickupDateTimeLBLSwapText}`
                                : isDelivery
                                ? (!LFLStock || isUpgradeFlow)
                                    ? ''
                                    : `${DeliveryDateTimeLBLText}`
                                : `${CollectionDateTimeLBLText}`
                        }
                        deliveryDateTimeVal={
                            isPickup
                                ? `${getDateAndDay(
                                      globalState?.state?.pickupDate,
                                      dateFormat
                                  )} ${getTime(getDeliverySlot(globalState?.state?.pickupTime))}`
                                : isDelivery
                                ? (!LFLStock || isUpgradeFlow)
                                    ? ''
                                    : `${getDateAndDay(deliveryDate, dateFormat)} ${
                                          isTOM
                                              ? getTime(timeConvert24to12Hr(deliveryTime))
                                              : globalState?.state?.isMaxis &&
                                                (isSwap(incType) || isMalfunction(incType))
                                              ? 'By 8pm'
                                              : deliveryTime
                                      } `
                                : `${getDateAndDay(walkingDate, dateFormat)} ${getTime(
                                      walkingTime
                                  )} `
                        }
                        returnAddrsLBL={
                            isPickup &&
                            !isInWarrantyOEM(serviceRequestData) &&
                            !isEWSClaim(serviceRequestData, incType)
                                ? Scripts.Global.ReturnAddress
                                : ''
                        }
                        returnAddrsVal={
                            isPickup &&
                            !isInWarrantyOEM(serviceRequestData) &&
                            !isEWSClaim(serviceRequestData, incType)
                                ? globalState?.state?.returnAddress
                                : ''
                        }
                        returnDateTimeLBL={
                            isPickup &&
                            !isInWarrantyOEM(serviceRequestData) &&
                            !isEWSClaim(serviceRequestData, incType) &&
                            (returnDate || returnTime) &&
                            !skipReturnSlotSelection(agreementData?.ClientOffer?.ClientOfferName)
                                ? Scripts.Global.ReturnDateTime
                                : ''
                        }
                        returnDateTimeVal={
                            isPickup &&
                            !isInWarrantyOEM(serviceRequestData) &&
                            !isEWSClaim(serviceRequestData, incType) &&
                            returnDate &&
                            returnTime &&
                            !skipReturnSlotSelection(agreementData?.ClientOffer?.ClientOfferName)
                                ? `${getDateAndDay(returnDate, dateFormat)} ${getTime(
                                      getDeliverySlot(returnTime)
                                  )}`
                                : ''
                        }
                        nightDeliveryFeesLBL={reviewcripts?.NightDeliveryFeesLBL}
                        nightDeliveryFeesVal={
                            (isDelivery || isPickup) &&
                            NighDeliverytFees &&
                            currencySymbol + NighDeliverytFees
                        }
                        dataTransferFeesLBL={Scripts?.Payment?.dataTransfer}
                        dataTransferFeesVal={
                            hasDTAddOnAcquired
                                ? currencySymbol + globalState?.state?.doorStepAddOnData?.fee
                                : undefined
                        }
                        paymentLabel={
                            IsWarranty || isDeviceRefreshFree(find_agreement, incType)
                                ? ''
                                : reviewcripts?.PaymentBreakdownLBL
                        }
                        feeLBL={
                            IsWarranty || isDeviceRefreshFree(find_agreement, incType)
                                ? ''
                                : isSwap(incType) ||
                                  isReplacement(incType) ||
                                  isMalfunction(incType) ||
                                  isDeviceRefresh(incType)
                                ? SRFLBLText || Scripts.Global.SRFLBL
                                : isUpgradeFlow ? Scripts.Global.DeviceNonReturnFee
                                : RepairSRFText || Scripts.Global.RepairFeeLBL
                        }
                        feeVal={
                            IsWarranty || isDeviceRefreshFree(find_agreement, incType)
                                ? ''
                                : `${currencySymbol}${
                                      globalState?.state?.srf ||
                                      R_SRF ||
                                      Number(LFLSRFFees)?.toFixed(2)
                                  }`
                        }
                        subtotalLBL={
                            IsWarranty || isDeviceRefreshFree(find_agreement, incType)
                                ? ''
                                : Scripts.Global?.Subtotal
                        }
                        subtotalVal={
                            IsWarranty || isDeviceRefreshFree(find_agreement, incType)
                                ? ''
                                : currencySymbol + totalSRFee
                        }
                        totalLBL={
                            IsWarranty || isDeviceRefreshFree(find_agreement, incType)
                                ? ''
                                : Scripts.Global?.Total
                        }
                        totalVal={
                            IsWarranty || isDeviceRefreshFree(find_agreement, incType)
                                ? ''
                                : currencySymbol + totalSRFee
                        }
                        paymentMethodLBL=""
                        paymentMethodVal=""
                        isDelivery={isWalkin ? true : isDelivery}
                        proxyAuthorisedDeliveryLBL={
                            proxyUserData?.name ? reviewcripts?.ProxyAuthorised : ''
                        }
                        proxyAuthorisedDeliveryVal={proxyUserData ? proxyUserData?.name : ''}
                        proxyContactNumberDeliveryLBL={
                            proxyUserData?.mobile ? reviewcripts?.ProxyContactNumber : ''
                        }
                        proxyContactNumberDeliveryVal={proxyUserData ? proxyUserData?.mobile : ''}
                        proxyIDLBL={proxyUserData?.FIN ? reviewcripts?.ProxyID : ''}
                        proxyIDVal={proxyUserData ? proxyUserData?.FIN : ''}
                        discountLBL={''}
                        discountVal=""
                        btnSubmitLBL={
                            IsWarranty ||
                            Number(totalSRFee) === 0 ||
                            isDeviceRefreshFree(find_agreement, incType)
                                ? Scripts.Global?.Submit
                                : Scripts.Global?.Submit
                        }
                        onClickSubmit={() => (enableReviewBtn ? onSubmit() : undefined)}
                        linkCancel={''}
                        onClickCancel={() => {}}
                        iswarranty={
                            IsWarranty || isDeviceRefreshFree(find_agreement, incType)
                                ? true
                                : false
                        }
                        enableReviewBtn={enableReviewBtn}
                        alternativeContactNumber={globalState?.state?.alternateContactNumber}
                    />
                )}
            </MainStackLayout>
            <CancelSrPopup
                closeCancelPopup={setOpenCancelPopup}
                openCancelPopup={openCancelPopup}
            />
        </Box>
    );
}

export default Reviewsubmission;
