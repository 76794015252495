/* eslint-disable no-console */
import {
    ReviewUpdateDevice,
    SubHeader,
    getCarrierByDomain,
    getTypeByDomain,
    useAccount,
} from '@MGPD/myasurion-shared';
import { Box, Image } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import { createInquiry, logGTMEvent } from '../../services/api';
import ActionTypes from '../../store/actionTypes.ts';
import { useCentralStore } from '../../store/appContext.tsx';
import { AssetData } from '../../types/types.ts';
import { GTM_EVENT } from '../../utils/constant.tsx';
import { getDeviceImageByModel } from '../../utils/get-device-images.tsx';
import { getGTMData, getRedirectUrl, saveError, showErrorPage } from '../../utils/utills.tsx';
import CancelSrPopup from '../myclaims/CancelSrPopup.tsx';

function UpdateDeviceReview() {
    const reviewScrips = Scripts.UpdateDeviceDetails.ReviewUpdateDevice;
    const globalState = useCentralStore();
    const navigate = useNavigate();
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [assetData, setAssetData] = useState<any>();
    const [account] = useAccount({ firstName: 'firstName', lastName: 'lastName', email: 'email' });
    const [deviceMake, setDeviceMake] = useState<any>();

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };
    console.log(setLoading);

    const onCancelClick = () => {
        // setOpenCancelPopup(!openCancelPopup);
        window.location.href = getRedirectUrl(getTypeByDomain());
    };
    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
    const InitializeResponse = globalState?.state?.sessionResponse?.InitializeResponse;

    // let filterDevices = globalState?.state?.imeiDeviceDetails

    useEffect(() => {
        let GTMData = getGTMData(
            GTM_EVENT.UPDATE_DEVICE_REVIEW_SCREEN_EVENT,
            globalState?.state?.currentAgreementData,
            getCarrierByDomain(),
            getTypeByDomain(),
            globalState?.state?.currentAgreementData?.CustomerCaseNumber
        );
        logGTMEvent(GTMData);
    }, []);

    useEffect(() => {
        if (agreementData1) {
            let assetData1: AssetData = agreementData1?.Assets.Asset.filter(
                (a: any) => a.AssetInstance === 'ENROLLED'
            )[0];
            setAssetData(assetData1);
        }
    }, [agreementData1 || globalState?.state?.sessionResponse]);

    useEffect(() => {
        let _deviceMake =
            globalState?.state?.imeiDeviceDetails &&
            globalState?.state?.imeiDeviceDetails.filter(
                (item: any) =>
                    item.ClientColor === globalState?.state?.updateDeviceColor &&
                    item.Capacity === globalState?.state?.updateDeviceCapacity
            );
        setDeviceMake(_deviceMake);
    }, [globalState?.state?.imeiDeviceDetails]);

    const handleSubmit = () => {
        // console.log('review moru', globalState?.state, globalState?.state?.updateDeviceFile);

        const payload = {
            CreateInquiryParams: {
                MobileDeviceNumber: assetData?.MobileDeviceNumber,
                AgreementId: agreementData1?.AgreementId,
                FirstName: account?.firstName,
                LastName: account?.lastName,
                Email: account?.email,
                ReferenceType: 'WUD',
                CustomerId: agreementData1?.Customers?.Customer?.[0]?.CustomerId,
                ClientName: InitializeResponse?.ClientName,
                DepartmentType: 'Care',
                InquiryChannel: 'Inquiry Channel',
                InquiryType: 'MyAsurion',
                InquirySubType: 'EUD',
                InquiryDescription: globalState?.state?.updateDeviceInqDesc,
                CallDriverCategory: 'Update Device Info',
                CallDriverSubCategory: 'Update Device Info',
                ProgramName: 'TechCare_SwapOnly_Monthly',
                ProgramType: 'Handset Protection',
                Priority: 'HIGH',
                ClientId: InitializeResponse?.ClientId,
                ClientChannelId: interactionData?.ClientChannelId,
                AssetId: assetData?.AssetId,
                Documents: globalState?.state?.updateDeviceFile,
                // Files: [
                //   {
                //     fileId: "upload-UpdateDevice-Id-doc",
                //     name: globalState?.state?.updateDeviceFile?.name,
                //     fileType: globalState?.state?.updateDeviceFile?.type,
                //     preview: {
                //       type: globalState?.state?.updateDeviceFile?.name.split('.').pop(),
                //       url: "blob:https://ui-seahzn-ag-sqa.consoleone.apac.npr.aws.asurion.net/04f397fa-5864-4401-83c0-7b1fa441be46"
                //     },
                //     fileSize: globalState?.state?.updateDeviceFile?.size,
                //     data: globalState?.state?.updateDeviceBaseURL
                //   }
                // ],
                UpdateDeviceDetails: {
                    AssetCatalogId: deviceMake[0].AssetCatalogId,
                    IMEI: globalState?.state?.updateDeviceImei,
                    ClientAssetSkuNumber: deviceMake[0]?.AssetCatalogName,
                    MobileDeviceNumber: assetData?.MobileDeviceNumber,
                    Email: account?.email,
                    SubscriptionNumber: agreementData1?.SubscriptionNumber,
                },
            },
        };
        setLoading(true);
        createInquiry(payload, interactionLineId, globalState?.state?.findAgreementSessionData)
            .then((resp) => {
                console.log('createInquiry---', resp);
                setLoading(false);
                navigate('/confirmationUpdateDevice');
            })
            .catch((err) => {
                console.log('', err);
                setLoading(false);
                saveError(err, globalState);
                showErrorPage(navigate);
            });
    };

    // const isDisabled = false

    return (
        <Box>
            <Box
                w={{ base: 'full', lg: '80%' }}
                margin="auto"
                pb={{ base: 0, lg: '150px' }}
                id={'myasurion-profile-dashboard'}
                paddingY={'54px'}
                // mb='20px'
            >
                <MainStackLayout gap={0} spacing={0}>
                    <SubHeader
                        backText={''}
                        allowBackButtonClick={false}
                        showCancelButton={true}
                        showBackButton={false}
                        cancelText={Scripts.CancelSR.cancelBtnTextCancel}
                        onCancelClick={onCancelClick}
                    ></SubHeader>
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '80%' }}
                        margin="0 auto"
                        h={'auto'}
                        p={'10px'}
                    >
                        <ReviewUpdateDevice
                            title={reviewScrips.title}
                            heading={reviewScrips.heading}
                            mobileNoLbl={reviewScrips.mobileNoLbl}
                            mobileNo={`(${
                                assetData?.CountryCallingCode.split('+')?.[1] ||
                                agreementData1?.ContactPoints?.ContactPoint?.[0]?.CountryCallingCode
                            }) ${assetData?.MobileDeviceNumber}`}
                            deviceNameLbl={reviewScrips.deviceNameLbl}
                            deviceName={assetData?.AssetCatalog.AssetCatalogName}
                            imeiLbl={reviewScrips.imeiLbl}
                            imei={assetData?.IMEI}
                            image=""
                            imageEl={
                                <Image
                                    height={'80px'}
                                    marginTop={'22px'}
                                    alignSelf={'center'}
                                    src={getDeviceImageByModel(
                                        globalState?.state?.deviceImages,
                                        `${assetData?.AssetCatalog.AssetFamily}`,
                                        '',
                                        80
                                    )}
                                ></Image>
                            }
                            confirmText={reviewScrips.confirmText}
                            makeModelLbl={reviewScrips.makeModelLbl}
                            capacityLbl={reviewScrips.capacityLbl}
                            colorLbl={reviewScrips.colorLbl}
                            updatedImei={globalState?.state?.updateDeviceImei}
                            updatedDeviceName={deviceMake?.[0]?.Family}
                            updatedCapacityVal={
                                deviceMake?.[0]?.Capacity == 1
                                    ? `${deviceMake?.[0]?.Capacity}TB`
                                    : `${deviceMake?.[0]?.Capacity}GB`
                            }
                            // deviceMake?.[0]?.Capacity?.includes('GB') ? deviceMake?.[0]?.Capacity : deviceMake?.[0]?.Capacity?.concat('GB')}
                            updatedColorVal={deviceMake?.[0]?.ClientColor}
                            btnSubmitLBL={reviewScrips.btnSubmitLBL}
                            onClickSubmit={() => handleSubmit()}
                        />
                    </Box>
                </MainStackLayout>
                <CancelSrPopup
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
        </Box>
    );
}

export default UpdateDeviceReview;
