import { useConfig, useTextScript } from '@MGPD/myasurion-shared';
import { TextScript, getTextScript } from '@MGPD/myasurion-shared';
import { Box, BoxProps, Button, Flex, Image, Square, Text } from '@chakra-ui/react';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import DeviceModelScript from '../Scripts/asurion-script.json';
import DeviceModelTranslations from '../Scripts/translationsKeys.json';
import AlertIcon from './AlertIcon';
import { useCentralStore } from '../store/appContext';
import CancelSrPopup from './myclaims/CancelSrPopup';

export interface DeviceDetails {
    img: string;
    familyName: string;
    srf: string;
}

export interface DeviceModelProps {
    DeviceDetails: DeviceDetails;
    onClickCallback: (args: DeviceDetails) => void;
    inStock: boolean;
    incidentType: string;
    isWarrantyRepairEligible?: boolean;
}

const DeviceModel: React.FC<PropsWithChildren<BoxProps & DeviceModelProps>> = ({
    onClickCallback,
    children,
    DeviceDetails,
    inStock,
    incidentType,
    ...props
}) => {
    const [ServiceRequestFeeText, setServiceRequestFeeText] = useState('');
    const [inStockText, setInStockText] = useState<string | null>(null);
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const globalState = useCentralStore();
    const { language } = useTextScript();

    useEffect(() => {
       if(!props.isWarrantyRepairEligible) getServiceRequestFeeText();
       getInStockText().then((text) => setInStockText(text));
    }, [DeviceDetails]);

    const getInStockText = async () => {
       let returnText: string = '';
        if(inStock) {
            if(props.isWarrantyRepairEligible && !globalState?.state?.isWarrantyRepairEligible){
                returnText = await getTextScript(
                    `${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.WarrantyRepairInStock}`,
                    'Your model is available!',
                    language                   
                );
            } else{
            returnText = await getTextScript(
            `${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.scriptInStock}`,
            'Your model is available for {1}',
            'en-US',
            [incidentType]
        );
       }} else {
        if(props.isWarrantyRepairEligible && !globalState?.state?.isWarrantyRepairEligible){
         returnText = await getTextScript(
            `${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.WarrantyRepairOutOfStock}`,
            `Sorry, your model is not available. Please choose one of the phone options below.`,
            language
         );   
        } else{
            returnText = await getTextScript(
                `${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.scriptOutOfStock}`,
                'Sorry, your model is not available for {1}',
                'en-US',
                [incidentType]
            );
       }}
        return returnText;
    }

    const getServiceRequestFeeText = async () => {
        let returnText = '';
        if(inStock){
        returnText = await getTextScript(
            `${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.ServiceRequestFee}`,
            `Standard service request fee (SRF) <b>{1}</b>`,
            'en-US',
            [DeviceDetails.srf]
        );
}
        setServiceRequestFeeText(returnText);        
    };

    const handleCancel = () => {
        setOpenCancelPopup(true);
    };

    const ButtonComponent = () => {
        //localization color theme changes
        const { data } = useConfig();
        const { theme } = data;
        const { colors } = theme;
        const { primary, accent } = colors;
        return (
            <>
                {inStock ? (
                    <Button
                        variant="outline"
                        onClick={() => {
                            onClickCallback && onClickCallback(DeviceDetails);
                        }}
                        display={'flex'}
                        justifyContent={'center'}
                        marginX={'auto'}
                        bg={primary}
                        textColor={'white'}
                        width={'full'}
                        mt={{ base: '0.5rem', lg: '1rem' }}
                    >
                        <TextScript
                            text={'Proceed'}
                            translationKey={`${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.KeyProceedButton}`}
                        />
                    </Button>
                ) : ( props.isWarrantyRepairEligible ? (<Box w="full" mt={2}>
                    <Button
                        variant={'outline'}
                        w={'full'}
                        py={3}
                        px={5}
                        color={accent}
                        borderColor={primary}
                        onClick={() =>
                            handleCancel()
                        }
                    >
                        <TextScript
                            text={"Cancel request and return phone"}
                            translationKey={`${DeviceModelTranslations.ClaimCard.PageName}/${DeviceModelTranslations.ClaimCard.cancelRequestKey}`}
                        />
                    </Button>
                </Box>) : (<Box>
                        <Button
                            variant="outline"
                            onClick={() => {
                                onClickCallback && onClickCallback(DeviceDetails);
                            }}
                            // display={'flex'}
                            // justifyContent={'center'}
                            // marginX={'auto'}
                            colorScheme="primary"
                            // width={'full'}
                            mt={{ base: '0.5rem', lg: '1rem' }}
                            // p={2}
                            mb={'8px'}
                        >
                            <Text color={accent}>
                                <TextScript
                                    text={DeviceModelScript.DeviceModel.waitForDeviceToRestocked}
                                    translationKey={`${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.waitForDeviceToRestocked}`}
                                />
                            </Text>
                        </Button>   
                         <Flex>
                            <AlertIcon height={'1.5rem'} width={'1.5rem'} />
                            <span style={{ paddingLeft: '4px', color: '#DB1B1B' }}>
                                <TextScript
                                    text={'Do note that backorder takes approximately 2 weeks.'}
                                    translationKey={`${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.KeyLblBackOrderInfo}`}
                                />
                            </span>
                        </Flex>
                    </Box>)
                )}
                <CancelSrPopup
                        closeCancelPopup={setOpenCancelPopup}
                        openCancelPopup={openCancelPopup}
                    />
            </>
            
        );
    };

    return (
        <>
            <Box {...props} paddingX={'1rem'}>
                <Box w={{ base: 'full', lg: '60%' }} m={'auto'}>
                    <Flex justify={{ base: 'center', lg: 'space-between' }}>
                        <Flex direction={'column'}>
                            <Box marginRight={{ base: '', lg: '2rem' }}>
                                <Text fontSize={'2rem'}>{inStockText}</Text>                                
                                <Text fontSize={'1.12rem'}>{DeviceDetails.familyName}</Text>
                                <Box mt={'1rem'}>
                                    {ServiceRequestFeeText && (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: ServiceRequestFeeText,
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                            <Box
                                w={{ base: 'full', lg: '60%' }}
                                display={{ base: 'none', lg: 'block' }}
                            >
                                <ButtonComponent />
                            </Box>
                        </Flex>
                        <Square maxWidth={'148px'} maxHeight={'148px'}>
                            <Image src={DeviceDetails.img} alt="your model" />
                        </Square>
                    </Flex>
                </Box>
                <Box display={{ base: 'block', lg: 'none' }}>
                    <ButtonComponent />
                </Box>
            </Box>
        </>
    );
};

export default DeviceModel;
