import {
    SubHeader,
    TextScript,
    getCarrierByDomain,
    getTypeByDomain,
    useConfig,
    useSessionStorage,
} from '@MGPD/myasurion-shared';
import { Box, Button, Center, Flex, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import MainStackLayout from '../../layout/MainStackLayout';
import {
    ResumeFlowDataKey,
    assetDataKey,
    fmipEligibilityKey,
    processIncidentKey,
    FulfillmentOptionKey,
    deliveryTypeKey,
    interactionLineKey,
} from '../../modules/config/constants';
import { fmipCheckEligibility, logGTMEvent, createServiceOrder, setFulfillmentMethodApi} from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { GTM_EVENT, FULLFILLMENT_METHOD_TYPE, INCIDENT_TYPES, DEVICE_MAKE_CATEGORY } from '../../utils/constant';
import features from '../../utils/features.json';
import { getFulfillmentMethodType, hasCheckBalanceFeature, hasDataTransferFeature, isJBUpgradeFlow } from '../../utils/featuresUtills';
import { camelize } from '../../utils/helper';
import { getGTMData, isInWarranty, saveError, showErrorPage, isEWSClaim, isInWarrantyOEM, isPUR, isUpgrade, getDeviceMakeCategory } from '../../utils/utills';
import CancelSrPopup from '../myclaims/CancelSrPopup';

function Fmip() {
    const globalState = useCentralStore();
    const FmipPageKeys = translationsKeys.FmipPage;
    const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
    const [processIncidentData] = useSessionStorage<any>(processIncidentKey, null);
    const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
    const R_IMEI = R_ServiceRequests?.EnrolledDevice?.IMEI;

    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [fulfillmentOption, setFulfillmentOption] = useSessionStorage<string>(FulfillmentOptionKey, '')
    const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);
    const processIncData = globalState?.state?.processIncData || serviceRequestData;
    const R_IncidentType = R_ServiceRequests?.IncidentType;
  const incType = camelize(processIncData?.ServiceRequest?.IncidentType || R_IncidentType);
  const [srDeliveryType] = useSessionStorage<any>(deliveryTypeKey, null);
  const [interactionLineId] = useSessionStorage<any>(interactionLineKey, null);
  const isPickup = isPUR(srDeliveryType);
  const R_ServiceRequestId = R_ServiceRequests?.ServiceRequestId;
  const ServiceRequestId =
  processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId;
  const R_FulfillmentOptionExist =
    (ResumeFlowData?.FulfillmentOptionExist === true && R_ServiceRequests?.FulfillmentOption)
    || fulfillmentOption;
    const R_CustomerCaseId = R_ServiceRequests?.CustomerCaseId;

    const navigate = useNavigate();
    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };
    const [, setFmipResponse] = useSessionStorage<any>(fmipEligibilityKey, null);
    const [assetData] = useSessionStorage<any>(assetDataKey, null);
    const imei = assetData?.IMEI || R_IMEI;
    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const IsWarranty = isInWarranty(processIncidentData);
    const isBackOrderPendingAction = false;
    // ResumeFlowData?.CustomerCaseStatus?.toUpperCase() === 'WORKING' &&
    // ResumeFlowData?.IsWBKODeviceAvailable;

    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    // const isSS4 = agreementData1?.IsSmartSupport4
    const incidentTypeFromState =
        globalState?.state?.IncidentType?.toUpperCase() === 'REFRESH'
            ? 'Device Refresh'
            : globalState?.state?.IncidentType?.toUpperCase();

    const IncidentType = camelize(
        processIncidentData?.ServiceRequest?.IncidentType ||
        R_ServiceRequests?.IncidentType ||
        incidentTypeFromState
    );

const fetchFulfillmentMethodType = () => {
  const incidentType = isInWarrantyOEM(serviceRequestData) ? INCIDENT_TYPES.MALFUNCTION_IW : isEWSClaim(serviceRequestData, incType) ? INCIDENT_TYPES.MALFUNCTION_EWS : incType;
  return getFulfillmentMethodType(incidentType, agreementData1?.ClientOffer?.ClientOfferName) || FULLFILLMENT_METHOD_TYPE.REPAIR;
}

const isUpgradeFlow = isJBUpgradeFlow(agreementData1?.ClientOffer?.ClientOfferName)

const getServiceOrderParams = () => {
  return {
    InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
    ApprovedServiceFeeChange: false,
    ServiceOrder: {
      FulfillmentMethodType: fetchFulfillmentMethodType(),
      ServiceRequestId:
        processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
      CustomerCaseId: processIncData.CustomerCaseId || R_CustomerCaseId,
      ServiceOrderLines: {
        ServiceOrderLine: [
          {
            ServiceOrderLineType: assetData?.AssetCategory?.AssetCategoryName || 'PHN',
            Quantity: 1,
            AssetCatalogId:
              assetData?.AssetCatalog?.AssetCatalogId ||
              R_ServiceRequests?.EnrolledDevice?.AssetCatalog?.AssetCatalogId,
            VendorItemId: assetData?.ItemId || 'S830-0420-IPH125G128BLK',
            Priority: 'MNDTRY',
            VenderItemType: assetData?.AssetCategory?.AssetCategoryName || 'PHN',
            VenderItemDescription: assetData?.AssetCategory?.AssetCategoryName || 'PHONE',
            VendorData: {
              IsLikeForLike: true,
              IsSimCard: false,
              IsInKit: false,
              IsInStock: true,
            },
          },
        ],
      },
    },
  };
}

const callSetFulfillmentApi = async () => {
  setLoading(true);
  const InteractionLineId = interactionData?.InteractionLine?.InteractionLineId;
  const FulfillmentOption = (isUpgrade(incType) && isUpgradeFlow) ? 'RPLCMNT' : isPickup ? 'PUR' : 'WALKIN';

  setFulfillmentMethodApi(InteractionLineId, FulfillmentOption, ServiceRequestId, globalState?.state?.findAgreementSessionData)
    .then((response) => {
      globalState?.dispatch({
        type: ActionTypes.SET_FULFILLMENT_DATA,
        payload: response?.FulfillmentResponse,
      });

      setFulfillmentOption(FulfillmentOption)
      const CreateServiceOrderParameters = getServiceOrderParams();
      createServiceOrder(CreateServiceOrderParameters, interactionLineId, globalState?.state?.findAgreementSessionData)
        .then((response) => {
          setLoading(false);
          globalState?.dispatch({
            type: ActionTypes.SET_SERVICE_ORDER_DATA,
            payload: response,
          })
        })
    })
}

useEffect(() => {
  if ((isUpgrade(incType) && isUpgradeFlow) && (!R_FulfillmentOptionExist)) {
    callSetFulfillmentApi()
  }
}, [(isUpgrade(incType) && isUpgradeFlow), R_FulfillmentOptionExist]);
        useEffect(() => {
        let GTMData = getGTMData(
            GTM_EVENT.FMIP_SCREEN_EVENT,
            globalState?.state?.currentAgreementData,
            getCarrierByDomain(),
            getTypeByDomain(),
            globalState?.state?.currentAgreementData?.CustomerCaseNumber
        );
        logGTMEvent(GTMData);
    }, []);

    const { refetch: fmipCheckOn, data: fmipData } = useQuery(
        ['fmipCheckEligibility'],
        async () => {
            setLoading(true);
            console.log(fmipData, 'Fmip');
            return await fmipCheckEligibility(
                imei,
                interactionData?.InteractionLine.InteractionLineId,
                agreementData1?.AgreementId,
                globalState?.state?.findAgreementSessionData
            );
        },
        {
            enabled: false,
            onSuccess: (data: any) => {
                setLoading(false);
                setFmipResponse(data);
                if (isBackOrderPendingAction) {
                    if (
                        hasDataTransferFeature(
                            IncidentType,
                            agreementData1?.ClientOffer?.ClientOfferName,
                            features
                        )
                    )
                        navigate('/datatransfer', { replace: true });
                    else navigate('/deliveryoptions', { replace: true });
                } else if (data.fmip === false && IsWarranty) {
                    navigate('/deliveryoptions', { replace: true });
                } else if (data.fmip === false) {
                    navigate('/terms', { replace: true });
                } else {
                    navigate('/unabletoturnofffmip');
                }
            },
            onError: (error: any) => {
                setLoading(false);
                navigate('/deliveryoptions', { replace: true }); // temprory fix
                //  navigate('/terms', { replace: true }); // temprory fix
                saveError(error, globalState);
                showErrorPage(navigate);
            },
        }
    );

  const fmipinstructions = () => {
    const clientOfferName = agreementData1?.ClientOffer?.ClientOfferName;
    const isValidJBUpgradeFlow = hasCheckBalanceFeature(clientOfferName) || isJBUpgradeFlow(clientOfferName);
    const deviceMake = getDeviceMakeCategory(assetData?.Make?.Name, isValidJBUpgradeFlow);

    let navigationPath = '/fmipinstructions';

    if (isValidJBUpgradeFlow) {
      if (deviceMake === DEVICE_MAKE_CATEGORY.APPLE) {
        // ToDo: Need to update the path for macbook after DB changes are confirmed
        // navigationPath = '/fmipinstructionsmacbook';
        navigationPath = '/fmipinstructions';
      } else if (deviceMake === DEVICE_MAKE_CATEGORY.MICROSOFT) {
        navigationPath = '/fmipinstructionswindows';
      }
    }

    navigate(navigationPath);
  };
  const unableProceedToSR = () => {
    navigate('/unabletoproceedsr');
  };
  const fmipIsStillOn = () => {
    fmipCheckOn();
  };

    const onCancelClick = () => {
        setOpenCancelPopup(!openCancelPopup);
    };
    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { primary, accent } = colors;

    return (
        <Center>
            <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'} w="full">
                <MainStackLayout gap={0} spacing={0}>
                    {/* <Box
            id={'myasurion-profile-avatar'}
            mt={4}
            w={'full'}
            h={'auto'}
            p={'16px 16px 8px 10px'}
          > 
          <Flex id={'myasurion-profile-avatar'} direction='row' justifyContent='space-between' h={'auto'} marginX={{ base: '0', lg: '40' }} marginBottom={{ base: '0', lg: '5' }} p={'16px 16px'} marginTop={'16px'}>
            <Breadcrumb spacing="8px" separator={<ChevronRightIcon />}>
              <BreadcrumbItem>
                <BreadcrumbLink href="#">Service request</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href="#">Turn off Find my Device</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Box> */}
                    <Box
                        w={{ base: 'full', lg: '80%' }}
                        marginX={{ base: '', lg: 'auto' }}
                        mb={{ base: '0', lg: '20px' }}
                    >
                        <SubHeader
                            allowBackButtonClick={false}
                            showCancelButton={true}
                            showBackButton={false}
                            cancelText={Scripts.CancelSR.cancelBtnText}
                            onCancelClick={onCancelClick}
                        ></SubHeader>
                    </Box>
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '60%' }}
                        margin={{ base: '0', lg: '0 auto' }}
                        h={'auto'}
                        p={'24px 16px'}
                    >
                        <Text fontSize={'24px'}>
                            <TextScript
                                text={'Turn off Find My iPhone (FMiP) '}
                                translationKey={`${FmipPageKeys.PageName}/${FmipPageKeys.HeaderText}`}
                            />
                        </Text>
                        <Text mb="10px">
                            <TextScript
                                text={
                                    'Under the terms and conditions, you are required to disable FMIP on your device prior to handing over to our courier. If it is not disabled, the Additional Fee may be charged.'
                                }
                                translationKey={`${FmipPageKeys.PageName}/${FmipPageKeys.TextScript1}`}
                            />
                        </Text>
                        <Text mb="8px">
                            <TextScript
                                text={`We will now proceed to perform a check* on your mobile device's current FMiP status.`}
                                translationKey={`${FmipPageKeys.PageName}/${FmipPageKeys.TextScript2}`}
                            />
                        </Text>
                        <Text mb="16px" onClick={fmipinstructions} as={'u'}>
                            <TextScript
                                text={`How to turn off Find my iPhone`}
                                translationKey={`${FmipPageKeys.PageName}/${FmipPageKeys.HowtoFindLbl}`}
                            />
                        </Text>
                        <Text fontSize={'xs'} mb="10px" mt="16px">
                            <TextScript
                                text={`*Please note that by allowing us to proceed, you will be deemed as consenting to the processing of your personal data in Singapore and/or oversea for the purpose of this check.`}
                                translationKey={`${FmipPageKeys.PageName}/${FmipPageKeys.NoteText}`}
                            />
                        </Text>
                    </Box>
                    <Box id={'myasurion-profile-avatar'} w={'full'} h={'auto'} p={'24px 16px'}>
                        <Stack minWidth="max-content" alignItems="center" gap="2">
                            <Flex direction="column" display={{ base: 'flex', lg: 'none' }}>
                                <Button
                                    height={'48px'}
                                    colorScheme={accent}
                                    color={accent}
                                    borderColor={primary}
                                    variant="outline"
                                    onClick={fmipIsStillOn}
                                    cursor="pointer"
                                    mb={2}
                                >
                                    <TextScript
                                        text={`Ok, I have turned off Find my iPhone`}
                                        translationKey={`${FmipPageKeys.PageName}/${FmipPageKeys.OkIHaveTurnedBtnLbl}`}
                                    />
                                </Button>
                                <Button
                                    colorScheme={accent}
                                    color={accent}
                                    borderColor={primary}
                                    variant="link"
                                    height="40px"
                                    onClick={unableProceedToSR}
                                    cursor="pointer"
                                    as={'u'}
                                >
                                    <TextScript
                                        text={`Unable to turn off Find my iPhone`}
                                        translationKey={`${FmipPageKeys.PageName}/${FmipPageKeys.UnableTurnOffBtnLbl}`}
                                    />
                                </Button>
                            </Flex>
                            {/* <Button
                as={'u'}
                colorScheme={accent}
                color={accent}
                variant="link"
                height="48px"
                cursor='pointer'
                onClick={fmipinstructions}
              >
                How to turn off Find my iPhone
              </Button> */}
                        </Stack>
                    </Box>
                    <Box
                        position="fixed"
                        bottom={0}
                        w="full"
                        display={{ base: 'none', lg: 'block' }}
                    >
                        <hr
                            style={{
                                border: 'none',
                                borderTop: '2px solid #adaaaa',
                            }}
                        />
                        <Flex
                            minWidth="max-content"
                            alignItems="center"
                            gap="2"
                            direction="row"
                            py={5}
                            justifyContent="center"
                        >
                            {/* <Stack minWidth="max-content" alignItems="center" gap="2"> */}
                            <Button
                                colorScheme={accent}
                                color={accent}
                                borderColor={primary}
                                variant="outline"
                                onClick={fmipIsStillOn}
                                cursor="pointer"
                            >
                                <TextScript
                                    text={`Ok, I have turned off Find my iPhone`}
                                    translationKey={`${FmipPageKeys.PageName}/${FmipPageKeys.OkIHaveTurnedBtnLbl}`}
                                />
                            </Button>
                            <Button
                                colorScheme={accent}
                                color={accent}
                                borderColor={primary}
                                variant="outline"
                                height="40px"
                                onClick={unableProceedToSR}
                                cursor="pointer"
                                marginX={10}
                            >
                                <TextScript
                                    text={`Unable to turn off Find my iPhone`}
                                    translationKey={`${FmipPageKeys.PageName}/${FmipPageKeys.UnableTurnOffBtnLbl}`}
                                />
                            </Button>
                            {/* </Stack> */}
                        </Flex>
                    </Box>
                </MainStackLayout>
                <CancelSrPopup
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
        </Center>
    );
}

export default Fmip;
