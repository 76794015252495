import {
    applePay,
    client,
    dataCollector,
    googlePayment,
    hostedFields,
    paypalCheckout,
    threeDSecure,
} from 'braintree-web';

declare global {
    interface Window {
        ApplePaySession: any;
    }
}

export const callCreateClient = (
    clientTokenResponse: any,
    onCCSuccess: any,
    // showLoader: any,
    setValidCardNumber: any,
    setValidName: any,
    setValidExpDate: any,
    setValidCVV: any,
    setInValidCardNumber: any,
    setInValidName: any,
    setInValidExpDate: any,
    setInValidCVV: any,
    setAuthenticationError: any,
    setCardType: any
) => {
    let paymentMethod: any = [];

    let fields = {
        number: {
            container: '#cardNumber',
            placeholder: 'Credit card number',
        },
        cvv: {
            container: '#cvv',
            type: 'password',
            placeholder: 'CVV',
        },
        expirationDate: {
            container: '#expiryDate',
            placeholder: 'Expiration date (MM/YYYY)',
        },
        cardholderName: {
            container: '#cardName',
            placeholder: 'Cardholder name',
        },
    };

    client.create({ authorization: clientTokenResponse }).then((res: any) => {
        console.log('res-----', res);

        dataCollector.create({ client: res }).then(async (deviceData: any) => {
            console.log('res deviceData-----', deviceData);
            paymentMethod.push(await createHostedFields(res, deviceData));
            return paymentMethod;
        });

        const createHostedFields = (authorization: any, deviceData: any) => {
            return hostedFields
                .create({
                    client: authorization,
                    styles: {
                        input: {
                            'font-size': '16px',
                        },
                    },
                    fields: fields,
                })
                .then((hostedFieldsInstance: any) => {
                    console.log('hostedFieldsInstance 1------', hostedFieldsInstance);

                    hostedFieldsInstance.on('validityChange', function (event: any) {
                        let field = event.fields[event.emittedBy];
                        //   showLoader(false);

                        if (field.isValid) {
                            if (event.emittedBy === 'number') {
                                setValidCardNumber(true);
                                setInValidCardNumber(false);
                                setAuthenticationError('');
                            } else if (event.emittedBy === 'cardholderName') {
                                setValidName(true);
                                setInValidName(false);
                                setAuthenticationError('');
                            } else if (event.emittedBy === 'expirationDate') {
                                setValidExpDate(true);
                                setInValidExpDate(false);
                                setAuthenticationError('');
                            } else if (event.emittedBy === 'cvv') {
                                setValidCVV(true);
                                setInValidCVV(false);
                                setAuthenticationError('');
                            }
                        } else {
                            if (event.emittedBy === 'number') {
                                setValidCardNumber(false);
                                setInValidCardNumber(true);
                                setAuthenticationError('');
                            } else if (event.emittedBy === 'cardholderName') {
                                setValidName(false);
                                setInValidName(true);
                                setAuthenticationError('');
                            } else if (event.emittedBy === 'expirationDate') {
                                setValidExpDate(false);
                                setInValidExpDate(true);
                                setAuthenticationError('');
                            } else if (event.emittedBy === 'cvv') {
                                setValidCVV(false);
                                setInValidCVV(true);
                                setAuthenticationError('');
                            }
                        }
                    });

                    hostedFieldsInstance.on('cardTypeChange', function (event: any) {
                        if (event.cards.length === 1) {
                            setCardType(event.cards[0].niceType);
                            console.log(event.cards[0].niceType, '-------event000');
                        }
                    });

                    threeDSecure
                        .create({
                            client: authorization,
                            version: 2,
                        })
                        .then((threeDSecureInstance: any) => {
                            onCCSuccess(hostedFieldsInstance, threeDSecureInstance, deviceData);
                        })
                        .catch((err: any) => {
                            console.log(err);
                            return err;
                        });
                })
                .catch((err: any) => {
                    // showLoader(false);
                    console.log(err, 'hostedFieldsInstance Error');
                    return err;
                });
        };
    });
};

export const callCreatePaypal = (
    clientTokenResponse: any,
    onPayPalSuccess: any,
    agreementData: any,
    showLoader: any,
    countryCode: string
) => {
    const agreement = agreementData;
    const userAddress = agreement?.Address;

    client.create({ authorization: clientTokenResponse }).then((res) => {
        dataCollector.create({ client: res }).then((deviceData) => {
            return createPaypal(res, deviceData);
        });

        const createPaypal = (clientRes: any, deviceData: any) => {
            // const container = document.getElementById("btnPaypal");
            let customerName = `${agreement.Customers?.Customer[0]?.FirstName} ${agreement.Customers?.Customer[0]?.LastName}`;
            // container.style = "width: 295px";
            return paypalCheckout
                .create({
                    client: clientRes,
                })
                .then((paypalCheckoutInstance) => {
                    paypalCheckoutInstance
                        .loadPayPalSDK({
                            vault: true,
                        })
                        .then(() => {
                            showLoader(false);
                            window.paypal
                                .Buttons({
                                    fundingSource: window.paypal.FUNDING.PAYPAL?.toString(),
                                    style: {
                                        // layout: "vertical",
                                        color: 'white' as any,
                                        shape: 'rect' as any,
                                        label: 'pay' as any,
                                        //   // height: 55,
                                    },

                                    createBillingAgreement: function () {
                                        return paypalCheckoutInstance.createPayment({
                                            flow: 'vault' as any, // Required
                                            displayName: 'Asurion', // todo assign prograame name
                                            // The following are optional params
                                            billingAgreementDescription: 'Asurion',
                                            enableShippingAddress: true,
                                            shippingAddressEditable: false,
                                            shippingAddressOverride: {
                                                recipientName: customerName,
                                                line1: userAddress?.Address1,
                                                line2: userAddress?.Address2,
                                                city: userAddress?.City,
                                                countryCode: countryCode,
                                                postalCode: userAddress?.PostalCode,
                                                state: userAddress?.StateProvinceCode,
                                                phone: agreement?.ContactPoints?.ContactPoint[0]
                                                    ?.PhoneNumber,
                                            },
                                        });
                                    },
                                    onApprove: (data: any, actions: any) => {
                                        console.log('actions', actions);

                                        return new Promise((resolve, reject) => {
                                            paypalCheckoutInstance
                                                .tokenizePayment(data)
                                                .then(function (payload) {
                                                    resolve(onPayPalSuccess(payload, deviceData));
                                                })
                                                .catch((err) => {
                                                    reject(err);
                                                });
                                        });
                                    },
                                    onCancel: function (data) {
                                        console.log(
                                            'PayPal payment cancelled',
                                            JSON.stringify(data)
                                        );
                                    },
                                    onError: function (err) {
                                        console.error('PayPal error', err);
                                    },
                                })
                                .render('#btnPaypal');
                        });

                    // The PayPal script is now loaded on the page and
                    // window.paypal.Buttons is now available to use
                    // render the PayPal button (see Render the PayPal Button section)
                })
                .catch((err) => {
                    console.log(err, 'PayPal Error');
                    showLoader(false);
                });
        };
    });
};

export const callCreateGpay = (
    clientTokenResponse: any,
    totalSRFee: any,
    onGpaySuccess: any,
    showLoader: any
) => {
    let paymentMethod: any = [];
    let _paymentData: any;

    const envType = import.meta.env.VITE_ENV;
    const _env =
        envType?.toLowerCase() === 'dev' || envType?.toLowerCase() === 'sqa'
            ? 'TEST'
            : 'PRODUCTION';
    const _googleMerchantId =
        envType?.toLowerCase() === 'uat' || envType?.toLowerCase() === 'production'
            ? 'BCR2DN4T22HKTJY4'
            : 'StarhubHomePlusOTPWebSGD';
    // console.log('moru', envType, _env, _googleMerchantId);

    const paymentsClient = new window.google.payments.api.PaymentsClient({
        environment: _env,
    });

    client.create({ authorization: clientTokenResponse }).then((res) => {
        dataCollector.create({ client: res }).then(async (deviceData) => {
            paymentMethod.push(await createGpay(res, deviceData));
            return paymentMethod;
        });

        let createGpay = (authorization: any, deviceData: any) => {
            return googlePayment
                .create({
                    client: authorization,
                    googlePayVersion: 2,
                    googleMerchantId: _googleMerchantId, // Optional in sandbox; if set in sandbox, this value must be a valid production Google Merchant ID
                })
                .then((googlePaymentInstance: any) => {
                    return paymentsClient
                        .isReadyToPay({
                            apiVersion: 2,
                            apiVersionMinor: 0,
                            allowedPaymentMethods:
                                googlePaymentInstance.createPaymentDataRequest()
                                    .allowedPaymentMethods,
                            existingPaymentMethodRequired: true, // Optional
                        })
                        .then((response) => {
                            showLoader(false);
                            console.log('response--------');

                            if (response.result) {
                                console.log('result---------');

                                const container: any = document.querySelector('#gpay-button');
                                console.log(container, 'container');

                                const button = paymentsClient.createButton({
                                    buttonColor: 'black',
                                    // buttonType: "plain",
                                    buttonType: 'pay',
                                    // buttonSizeMode: "rect",
                                    onClick: () => finalCall(),
                                    allowedPaymentMethods: [], // use the same payment methods as for the loadPaymentData() API call
                                });
                                container.appendChild(button);
                                var paymentDataRequest =
                                    googlePaymentInstance.createPaymentDataRequest({
                                        transactionInfo: {
                                            currencyCode: 'SGD',
                                            totalPriceStatus: 'FINAL',
                                            totalPrice: totalSRFee?.toString(), // Your amount
                                        },
                                    });
                                var cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
                                cardPaymentMethod.parameters.billingAddressRequired = true;
                                cardPaymentMethod.parameters.billingAddressParameters = {
                                    format: 'FULL',
                                    phoneNumberRequired: true,
                                };
                                const finalCall = () => {
                                    paymentsClient
                                        .loadPaymentData(paymentDataRequest)
                                        .then((paymentData) => {
                                            _paymentData = paymentData;
                                            return googlePaymentInstance.parseResponse(paymentData);
                                        })
                                        .then((result) => {
                                            onGpaySuccess(result, deviceData, _paymentData);
                                            console.log(result, '-------------nonce');
                                        })
                                        .catch((err) => {
                                            console.log(err, '---------err1');
                                        });
                                };
                            }
                        });
                })
                .catch((err) => {
                    console.log(err, '----------------err2');
                    showLoader(false);
                });
        };
    });
};

export const callCreateApplepay = (
    clientTokenResponse: any,
    totalSRFee: any,
    onApplepaySuccess: any,
    showLoader: any
) => {
    if (!window.ApplePaySession) {
        console.error('This device does not support Apple Pay');
    }

    if (
        !window.ApplePaySession.canMakePayments &&
        !window.ApplePaySession.canMakePaymentsWithActiveCard
    ) {
        console.error('This device is not capable of making Apple Pay payments');
    }

    if (!window.ApplePaySession.supportsVersion(3)) {
        console.error('This device does not support version 3 of ApplePay');
    }
    let paymentMethod: any = [];

    client.create({ authorization: clientTokenResponse }).then((clientInstance) => {
        dataCollector.create({ client: clientInstance }).then(async (deviceData) => {
            console.log(deviceData, '---------deviceData');

            paymentMethod.push(await createApplePay(clientInstance, deviceData));
            return paymentMethod;
        });
    });
    let createApplePay = (authorization: any, deviceData: any) => {
        console.log(deviceData, '--------deviceData out');

        applePay.create({ client: authorization }, (applePayErr: any, applePayInstance: any) => {
            if (applePayErr) {
                console.error('Error creating applePayInstance:', applePayErr);
                return;
            }

            var paymentRequest = applePayInstance.createPaymentRequest({
                total: {
                    label: 'Total',
                    amount: totalSRFee?.toString(),
                },
                supportedNetworks: ['amex', 'discover', 'masterCard', 'visa'],
                requiredBillingContactFields: ['postalAddress'],
            });

            let container: any = document.getElementById('appleButton');
            showLoader(false);
            container.addEventListener('click', () => {
                var session = new window.ApplePaySession(3, paymentRequest);

                //callback  to validate ApplePay session object
                session.onvalidatemerchant = (event: any) => {
                    console.log('Validation being performed');
                    console.log(event, 'event');
                    applePayInstance.performValidation(
                        {
                            validationURL: event.validationURL,
                            displayName: 'Asurion',
                        },
                        (err: any, merchantSession: any) => {
                            if (err) {
                                console.log('Apple Pay failed to load.', err);
                                return;
                            }
                            session.completeMerchantValidation(merchantSession);
                        }
                    );
                };

                //callback  to send token to ApplePay and receive nonce
                session.onpaymentauthorized = (event: any) => {
                    console.log('Your shipping address is:', event.payment.shippingContact);

                    applePayInstance.tokenize(
                        { token: event.payment.token },
                        (tokenizeErr: any, payload: any) => {
                            if (tokenizeErr) {
                                console.error('Error tokenizing Apple Pay:', tokenizeErr);
                                session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                                return;
                            }

                            // Send payload.nonce to your server.
                            console.log('Full response:', JSON.stringify(payload));
                            console.log('nonce:', payload.nonce);
                            onApplepaySuccess(payload, deviceData);

                            // If requested, address information is accessible in event.payment
                            // and may also be sent to your server.
                            console.log(
                                'billingPostalCode:',
                                event.payment.billingContact.postalCode
                            );

                            // After you have transacted with the payload.nonce,
                            // call `completePayment` to dismiss the Apple Pay sheet.
                            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
                        }
                    );
                };

                //begins ApplePay session
                session.begin();
                console.log('session started.');
            });
        });
    };
};
