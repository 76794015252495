import { TextScript } from '@MGPD/myasurion-shared';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box, // BoxProps,
    Flex,
    Text,
} from '@chakra-ui/react';

import { showFAQSectionLink } from '../../utils/featuresUtills';

const faqs = [
    {
        q: 'How do I file a service request?',
        a: 'This is the content',
    },
    {
        q: 'How long does it take for my service request to be processed?',
        a: 'This is the content',
    },
    {
        q: 'Is there a limit on the number of service requests I can file?',
        a: 'This is the content',
    },
    {
        q: 'How do I track the status of my service requests?',
        a: 'This is the content',
    },
    {
        q: 'I have filed my service request, so what do I do next?',
        a: 'This is the content',
    },
    {
        q: 'Do I need to be home for pick-up and delivery of device?',
        a: 'This is the content',
    },
    {
        q: 'Can I reschedule my pick-up or return service?',
        a: 'This is the content',
    },
];

export interface FaqListProps {
    ClientOfferName: string;
}

const FaqList = ({ ...props }: FaqListProps) => {
    // images, titles and other branding should be coming from useConfig of shared
    return (
        <Box
            id={'myasurion-portal-faqlist'}
            w={'full'}
            m={'0'}
            minW={'375px'}
            px={{ base: 4, lg: 0 }}
            my={{ base: '2rem', lg: 0 }}
            {...props}
        >
            <Flex
                flexFlow="column nowrap"
                justifyContent="center"
                alignItems="flex-start"
                gap="4px"
                mb={'0.5rem'}
            >
                <Text fontWeight="400" fontSize="24px">
                    <TextScript text={'Your questions answered'} translationKey={'FaqList/title'} />
                </Text>
            </Flex>
            {showFAQSectionLink(props?.ClientOfferName) && (
                <Text fontWeight="400" fontSize="18px">
                    Please click{' '}
                    <span style={{ textDecoration: 'underline' }}>
                        <a
                            href="https://www.starhub.com/personal/support/search.html?q=smartsupport"
                            target="_blank"
                        >
                            here
                        </a>
                    </span>{' '}
                    to access the FAQ section.
                    {/* <TextScript text={'Please click here to access the FAQ section.'} translationKey={'FaqList/title'} /> */}
                </Text>
            )}
            {!showFAQSectionLink(props?.ClientOfferName) && (
                <Accordion width="100%">
                    {faqs.map((faq, fi) => {
                        return (
                            <AccordionItem key={`accordion-item-faq-${fi}`} py="6">
                                <AccordionButton>
                                    <Box as="span" flex="1" textAlign="left">
                                        <Text fontSize="18px" fontWeight="300" lineHeight="23.76px">
                                            <TextScript
                                                text={faq.q}
                                                translationKey={`FaqList/questions${fi}`}
                                            />
                                        </Text>
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel>
                                    <TextScript
                                        text={faq.a}
                                        translationKey={`FaqList/content${fi}`}
                                    />
                                </AccordionPanel>
                            </AccordionItem>
                        );
                    })}
                </Accordion>
            )}
        </Box>
    );
};
export default FaqList;
