export const contentTypes = [
    'Cybersecurity',
    'Expert Tips',
    'Apple',
    'Tech',
    'Android',
    'Tech Support',
    'Guide',
    'Data Transfer',
];

export const notificationApps = [
    { id: 'email', label: 'Email' },
    { id: 'whatsapp', label: 'Whatsapp' },
];

export const notificationPreferences = [
    {
        id: 'order',
        title: 'Order updates',
        description:
            'Notify when there are updates about my orders, including payment related updates.',
    },
    {
        id: 'newstuff',
        title: 'New stuff',
        description: 'New product launches: hear it first, get it first',
    },
    {
        id: 'discount',
        title: 'Discounts and sales',
        description: 'Be first in line to nab what you want for less',
    },
    {
        id: 'exclusives',
        title: 'Your exclusives',
        description: 'Enjoy a birthday treat, as well as tailored rewards and account updates.',
    },
];

export const feedbackSuccessMessage: any = {
    idea: {
        title: 'Submit Idea',
        description: 'You have successfully submitted an idea',
    },
    report: {
        title: 'Report Bug',
        description: 'You have successfully reported a bug',
    },
};

export const feedbackErrorMessage: any = {
    idea: {
        title: 'Submit Idea',
        description: 'There was an error while submitting your idea',
    },
    report: {
        title: 'Report Bug',
        description: 'There was an error while reporting a bug',
    },
};

export const feedbackOptions = [
    {
        label: 'I would like to report a bug with the site',
        labelDesktop: 'Report a bug',
        opts: {
            element: 'textarea',
            placeholder:
                'If something went wrong while you were using the site, send us the details and we’ll investigate that pesky bug.',
            limit: 200,
            btnLabel: 'Report bug',
            id: 'report',
            type: 'report',
        },
    },
    {
        label: 'I woud like to suggest an idea',
        labelDesktop: 'Suggest an idea',
        opts: {
            element: 'textarea',
            placeholder: 'Got an idea to make our site even better? Let us know!',
            limit: 200,
            btnLabel: 'Submit idea',
            id: 'submit',
            type: 'idea',
        },
    },
    {
        label: 'I need help with something',
        labelDesktop: 'I need help with something',
        opts: {
            placeholder:
                'If you need help with your order, or to chat to us about something else, take a look at our Help pages.',
            btnLabel: 'Talk to an expert',
            id: 'talk',
            type: 'chat',
        },
    },
];

export const addressLine1PartsLabels: string[] = [
    'houseNo',
    'bldgName',
    'roomNo',
    'village',
    'alley',
];
export const addressLine2PartsLabels: string[] = ['street', 'subDistrict'];

export const dashboardFieldLabels: { [key: string]: string[] } = {
    default: ['My Name', 'Enrolled Email', 'Enrolled Mobile Number'],
    asurion_techcare: [
        'First Name (as per your National ID/Passport)',
        'Middle Name (as per your National ID/Passport)',
        'Last Name (as per your National ID/Passport)',
        'Email Address',
        'Mobile number',
        'Date of Birth (as per your National ID/Passport)',
        'ID Type (as per your National ID/Passport)',
        'ID No. (as per your National ID/Passport)',
        'House No.',
        'Name of Building',
        'Room No.',
        'Village',
        'Alley',
        'Street',
        'Sub-district',
        'Province',
        'Postal Code',
    ],
    maxis: [
        'First Name (as per your National ID/Passport)',
        'Middle Name (as per your National ID/Passport)',
        'Last Name (as per your National ID/Passport)',
        'Email Address',
        'Mobile number',
        'Date of Birth (as per your National ID/Passport)',
        'ID Type (as per your National ID/Passport)',
        'ID No. (as per your National ID/Passport)',
        'House No.',
        'Name of Building',
        'Room No.',
        'Village',
        'Alley',
        'Street',
        'Sub-district',
        'Province',
        'Postal Code',
    ],
};

export const labels = {
    dashboard: {
        title: 'My Account',
        avatarLabel: 'main account',
        fieldLabels: ['My Name', 'Enrolled Email', 'Enrolled Mobile Number'],
    },
    communication: {
        title: 'Communication Preferences',
        caption1:
            'What would you like to hear about? Select your options below and we’ll keep you in the loop.',
        caption2: 'Changes to your preferences may take up to seven days to take effect.',
        placeholders: ['Preferred Name', 'Preferred Contact Number', 'Preferred Email', 'Address'],
        notifLabel: 'Notifications',
        confirmLabel: 'Confirm preferences',
    },
    feedback: {
        title: 'Share feedback',
        heading: 'Tell us what you think',
        caption1: 'Help us improve the site by sharing your experience.',
    },
    techcare: {
        title: 'Tech care preference',
        titleDesktop: 'Tech Care Preferences',
        caption1:
            'Raise an idea on our ideas hub if you have a suggestion for us. If you have an issue with your order, select ‘something else’ and we’ll point you in the right direction.',
        caption2: 'Changes to your preferences may take up to seven days to take effect.',
        subtitle: 'Personalised content type',
        confirmLabel: 'Confirm preferences',
    },
};

export enum FEATURES {
    TRANSLATOR = 'translator',
}

export const defaultToastProps: any = {
    duration: 2000,
    isClosable: true,
};

export const assets: { [key: string]: any } = {
    asurion: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/images/carriers/asurion/profile-home.png',
                },
                {
                    code: 'techcare',
                    name: 'Tech care preferences',
                    url: '/profile/techcare',
                    icon: '/images/carriers/asurion/profile-preference.png',
                },
                {
                    code: 'communication',
                    name: 'Communication preferences',
                    url: '/profile/notifications',
                    icon: '/images/carriers/asurion/profile-communication.png',
                },
                {
                    code: 'feedback',
                    name: 'Share feedback',
                    url: '/profile/feedback',
                    icon: '/images/carriers/asurion/profile-feedback.png',
                },
                {
                    code: 'changepass',
                    name: 'Change password',
                    url: '/profile/changepass',
                    icon: '/images/carriers/asurion/profile-password.png',
                },
            ],
        },
    },
    starhub: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/images/carriers/starhub/profile-home.png',
                },
                {
                    code: 'portalplan',
                    name: 'Plan Overview',
                    url: '/portal/plan',
                    icon: '/images/carriers/starhub/profile-home.png',
                },
                {
                    code: 'myplansandservicerequests',
                    name: 'My Service Requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/starhub/profile-myplansandservicerequests.png',
                },
            ],
        },
    },
    true: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/profile/images/carriers/true/profile-home.png',
                },
                {
                    code: 'techcare',
                    name: 'Tech care preferences',
                    url: '/profile/techcare',
                    icon: '/profile/images/carriers/true/profile-preference.png',
                },
                {
                    code: 'communication',
                    name: 'Communication preferences',
                    url: '/profile/notifications',
                    icon: '/profile/images/carriers/true/profile-communication.png',
                },
                {
                    code: 'feedback',
                    name: 'Share feedback',
                    url: '/profile/feedback',
                    icon: '/profile/images/carriers/true/profile-feedback.png',
                },
                {
                    code: 'changepass',
                    name: 'Change password',
                    url: '/profile/changepass',
                    icon: '/profile/images/carriers/true/profile-password.png',
                },
            ],
        },
    },
    techcare: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/profile/images/carriers/techcare/profile-home.png',
                },
                {
                    code: 'techcare',
                    name: 'Tech care preferences',
                    url: '/profile/techcare',
                    icon: '/profile/images/carriers/techcare/profile-preference.png',
                },
                {
                    code: 'communication',
                    name: 'Communication preferences',
                    url: '/profile/notifications',
                    icon: '/profile/images/carriers/techcare/profile-communication.png',
                },
                {
                    code: 'feedback',
                    name: 'Share feedback',
                    url: '/profile/feedback',
                    icon: '/profile/images/carriers/techcare/profile-feedback.png',
                },
                {
                    code: 'changepass',
                    name: 'Change password',
                    url: '/profile/changepass',
                    icon: '/profile/images/carriers/techcare/profile-password.png',
                },
            ],
        },
    },
    asurion_techcare: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/profile/images/carriers/techcare/profile-home.png',
                },
                {
                    code: 'payments',
                    name: 'My Payments',
                    url: '/swap/myPayments',
                    icon: '/profile/images/carriers/techcare/profile-payments.png',
                },
            ],
        },
    },
    celcom: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/profile/images/carriers/celcom/profile-home.png',
                },
                {
                    code: 'techcare',
                    name: 'Tech care preferences',
                    url: '/profile/techcare',
                    icon: '/profile/images/carriers/celcom/profile-preference.png',
                },
                {
                    code: 'communication',
                    name: 'Communication preferences',
                    url: '/profile/notifications',
                    icon: '/profile/images/carriers/celcom/profile-communication.png',
                },
                {
                    code: 'feedback',
                    name: 'Share feedback',
                    url: '/profile/feedback',
                    icon: '/profile/images/carriers/celcom/profile-feedback.png',
                },
                {
                    code: 'changepass',
                    name: 'Change password',
                    url: '/profile/changepass',
                    icon: '/profile/images/carriers/celcom/profile-password.png',
                },
            ],
        },
    },
    umobile: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/profile/images/carriers/umobile/profile-home.png',
                },
                {
                    code: 'techcare',
                    name: 'Tech care preferences',
                    url: '/profile/techcare',
                    icon: '/profile/images/carriers/umobile/profile-preference.png',
                },
                {
                    code: 'communication',
                    name: 'Communication preferences',
                    url: '/profile/notifications',
                    icon: '/profile/images/carriers/umobile/profile-communication.png',
                },
                {
                    code: 'feedback',
                    name: 'Share feedback',
                    url: '/profile/feedback',
                    icon: '/profile/images/carriers/umobile/profile-feedback.png',
                },
                {
                    code: 'changepass',
                    name: 'Change password',
                    url: '/profile/changepass',
                    icon: '/profile/images/carriers/umobile/profile-password.png',
                },
            ],
        },
    },
    lazada: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/profile/images/carriers/lazada/profile-home.png',
                },
                {
                    code: 'techcare',
                    name: 'Tech care preferences',
                    url: '/profile/techcare',
                    icon: '/profile/images/carriers/lazada/profile-preference.png',
                },
                {
                    code: 'communication',
                    name: 'Communication preferences',
                    url: '/profile/notifications',
                    icon: '/profile/images/carriers/lazada/profile-communication.png',
                },
                {
                    code: 'feedback',
                    name: 'Share feedback',
                    url: '/profile/feedback',
                    icon: '/profile/images/carriers/lazada/profile-feedback.png',
                },
                {
                    code: 'changepass',
                    name: 'Change password',
                    url: '/profile/changepass',
                    icon: '/profile/images/carriers/lazada/profile-password.png',
                },
            ],
        },
    },
    smart: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/profile/images/carriers/smart/profile-home.png',
                },
                {
                    code: 'techcare',
                    name: 'Tech care preferences',
                    url: '/profile/techcare',
                    icon: '/profile/images/carriers/smart/profile-preference.png',
                },
                {
                    code: 'communication',
                    name: 'Communication preferences',
                    url: '/profile/notifications',
                    icon: '/profile/images/carriers/smart/profile-communication.png',
                },
                {
                    code: 'feedback',
                    name: 'Share feedback',
                    url: '/profile/feedback',
                    icon: '/profile/images/carriers/smart/profile-feedback.png',
                },
                {
                    code: 'changepass',
                    name: 'Change password',
                    url: '/profile/changepass',
                    icon: '/profile/images/carriers/smart/profile-password.png',
                },
            ],
        },
    },
    maxis: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/profile/images/carriers/maxis/profile-home.png',
                },
            ],
        },
    },
    demo1: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/profile/images/carriers/demo1/profile-home.png',
                },
                {
                    code: 'techcare',
                    name: 'Tech care preferences',
                    url: '/profile/techcare',
                    icon: '/profile/images/carriers/demo1/profile-preference.png',
                },
                {
                    code: 'communication',
                    name: 'Communication preferences',
                    url: '/profile/notifications',
                    icon: '/profile/images/carriers/demo1/profile-communication.png',
                },
                {
                    code: 'feedback',
                    name: 'Share feedback',
                    url: '/profile/feedback',
                    icon: '/profile/images/carriers/demo1/profile-feedback.png',
                },
                {
                    code: 'changepass',
                    name: 'Change password',
                    url: '/profile/changepass',
                    icon: '/profile/images/carriers/demo1/profile-password.png',
                },
            ],
        },
    },
    twopenmarket_synnex: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/profile/images/carriers/twopenmarket_synnex/profile-home.png',
                },
            ],
        },
    },
    cht_home: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/profile/images/carriers/cht_home/profile-home.png',
                },
            ],
        },
    },
    jbhifi: {
        sidebar: {
            items: [
                {
                    code: 'plan-overview',
                    name: 'Plan overview',
                    url: 'plan',
                    icon: '/images/carriers/jbhifi/portal-home.png',
                    iconSlug: 'global-house-favicon',
                },
                {
                    code: 'my-service-requests',
                    name: 'My service requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/jbhifi/portal-preference.png',
                    iconSlug: 'global-preference-favicon',
                },
            ],
        },
    },
    twopenmarket_slw: {
        sidebar: {
            items: [
                {
                    code: 'dashboard',
                    name: 'My Account',
                    url: '/profile',
                    icon: '/profile/images/carriers/cht_home/profile-home.png',
                },
            ],
        },
    }
    
  }
    
