/* eslint-disable no-console */
import {
    SubHeader,
    getCarrierByDomain,
    getTypeByDomain,
} from '@MGPD/myasurion-shared';
import { Box, Image, Skeleton } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CNScripts from '../../Scripts/asurion-script-chinese.json';
import Scripts from '../../Scripts/asurion-script.json';
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import '../../services/api';
import { logGTMEvent, uploadToS3BucketBySignedURL } from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { AssetData } from '../../types/types.ts';
import { GTM_EVENT } from '../../utils/constant.tsx';
import {
    allowImeiDetailsScreen,
    hasMultiDeviceSupport,
    serialNbrScreenWithDeviceDetails,
} from '../../utils/featuresUtills.tsx';
import { getDeviceImageByModel } from '../../utils/get-device-images.tsx';
import { getMappedClientNameVal } from '../../utils/helper.tsx';
import {
    dataURItoBlob,
    getBase64,
    getSignedRequestForUpload,
    getSignedURLForDownload,
} from '../../utils/upload-utils.tsx';
import {
    getGTMData,
    getRedirectUrl,
    isChineseDefaultLanguage,
    saveError,
    showErrorPage,
} from '../../utils/utills.tsx';
import CancelSrPopup from '../myclaims/CancelSrPopup.tsx';
import UpdateDevice from './UpdateDevice.tsx';
import { checkIsApplLoadDisabled } from '../../../utils/config.ts';

function UpdateDeviceDetails() {
  const disableApplLoad = checkIsApplLoadDisabled(getCarrierByDomain()) //useFeatureIsOn('disableApplLoad');
  const updateDeviceScrips = Scripts.UpdateDeviceDetails.UpdateDevice
  const globalState = useCentralStore();
  const navigate = useNavigate();
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [optionVal, setOptionVal] = useState('');
  const [assetData, setAssetData] = useState<any>();
  const [selectedFile, setFile] = useState<any>(undefined);
  const [hideUploadBtn, setHideUploadBtn] = useState<boolean>(false);
  const [moreThan5Mb, setMoreThan5Mb] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>(false);
  const [, setDeviceColor] = useState<any>(''); //deviceColor

    const isTOM =
        globalState?.state?.sessionResponse?.InitializeResponse?.ClientName === 'Asurion_TechCare';
    const isChinese = isChineseDefaultLanguage();
    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
    // const agreementData1: any = findagreement?.FindAgreementsResults?.Agreements?.Agreement[0];

    useEffect(() => {
        let GTMData = getGTMData(
            GTM_EVENT.UPDATE_DEVICE_SCREEN_EVENT,
            globalState?.state?.currentAgreementData,
            getCarrierByDomain(),
            getTypeByDomain(),
            globalState?.state?.currentAgreementData?.CustomerCaseNumber
        );
        logGTMEvent(GTMData);
    }, []);

    useEffect(() => {
        if (getCarrierByDomain()?.toLowerCase() === 'starhub' || getCarrierByDomain()?.toLowerCase() === 'twopenmarket_slw') {
            let option: any =
                isTOM || globalState?.state?.isMaxis
                    ? updateDeviceScrips.updateDeviceOptions
                    : (isChinese
                          ? CNScripts.UpdateDeviceDetails.UpdateDevice.updateDeviceOptions
                          : updateDeviceScrips.updateDeviceOptions.slice(0, -1)) || '';
            setOptionVal(option?.[0]);
            globalState?.dispatch({
                type: ActionTypes.SET_UPDATE_DEVICE_INQ_DESC,
                payload: option?.[0],
            });
        }
    }, [getCarrierByDomain()]);

    useEffect(() => {
        let assetData1: AssetData;
        if (agreementData1) {
            if (
                hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName) &&
                sessionStorage.getItem('dsMdn')
            ) {
                // get selected asset details stored in session
                const selectedAsset: any = JSON.parse(sessionStorage.getItem('dsMdn') || '');
                assetData1 = agreementData1?.Assets.Asset.filter(
                    (a: any) => a.AssetId === selectedAsset?.assetId
                )[0];
            } else {
                // assetData1 = agreementData1?.Assets.Asset.filter(
                //   (a: any) => a.AssetInstance === 'ENROLLED'
                // )[0];
                assetData1 = agreementData1?.Assets.Asset.filter(
                    (a: any) => a.AssetInstance === 'REPLACED'
                )[0];

                if (!assetData1 || assetData1 === undefined) {
                    assetData1 = agreementData1?.Assets.Asset.filter(
                        (a: any) => a.AssetInstance === 'ENROLLED'
                    )[0];
                }
                const deviceColor = assetData1?.AssetCatalog?.AssetCatalogName?.split(' ');
                setDeviceColor(deviceColor);
            }
            setAssetData(assetData1);
        }
    }, [agreementData1 || globalState?.state?.sessionResponse]);

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const onCancelClick = () => {
        // setOpenCancelPopup(!openCancelPopup);
        disableApplLoad
            ? navigate(getRedirectUrl(getTypeByDomain()))
            : (window.location.href = getRedirectUrl(getTypeByDomain()));
        // window.location.href = getRedirectUrl(getTypeByDomain());
    };

    const handleSubmit = () => {
        // if(isTOM || globalState?.state?.isMaxis)
        if (allowImeiDetailsScreen(agreementData1?.ClientOffer?.ClientOfferName)) {
            navigate('/imeiDetails');
        } else if (serialNbrScreenWithDeviceDetails(agreementData1?.ClientOffer?.ClientOfferName)) {
            navigate('/updateAnchorDevice');
            globalState?.dispatch({ type: ActionTypes.FROM_UPDATE_DEVICE_BUTTON, payload: true });
        } else {
            navigate('/serialNumberDetails');
        }
    };

    let documents: any = [];

    const handleFileUpload = async (e: any) => {
        let FileErrors = false;
        setFileError(false);
        const fileToUpload = e.target.files[0];
        //File validation for CHT_HOME
        let regex = /.*\.(bmp|jpg|png|jpe?g|pdf)$/i;
        if (regex.test(fileToUpload.name)) {
            setFileError(false);
            FileErrors = false;
            setMoreThan5Mb(false);
            let fileSizeInKB = fileToUpload.size / 1024;
            if (fileSizeInKB && fileSizeInKB <= 5120) {
                setMoreThan5Mb(false);
                FileErrors = false;
                setFile(fileToUpload);
            } else {
                setMoreThan5Mb(true);
                FileErrors = true;
            }
        } else {
            setFileError(true);
            FileErrors = true;
        }

        if (!FileErrors) {
            setLoading(true);
            let AgreementId = agreementData1?.AgreementId;
            const signedUrl: any = await getSignedRequestForUpload(
                AgreementId,
                interactionLineId,
                'update-device',
                getMappedClientNameVal(
                    globalState?.state?.sessionResponse?.InitializeResponse?.ClientName?.toLowerCase()
                ),
                globalState?.state?.findAgreementSessionData
            ).catch((error) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);
                return;
            });

            const base64Obj = await getBase64(fileToUpload);
            // saveGeneratedFileName(signedUrl, fileToUpload);
            const uploadedFile: any = await uploadToS3BucketBySignedURL(
                signedUrl,
                fileToUpload?.type,
                dataURItoBlob(base64Obj)
            ).catch((error) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);
                return;
            });

            if (uploadedFile && uploadedFile?.status === 200 && uploadedFile.statusText === 'OK') {
                const fileName = signedUrl.split('?')?.[0]?.split('/')?.pop();
                const extension = fileToUpload?.name?.split('.').pop();
                setFile(fileToUpload?.name);
                const params = {
                    FileDownloadRequest: {
                        AgreementId: AgreementId,
                        FileName: `${fileName}.${extension}`,
                        FolderName: 'update-device',
                    },
                };

                const data: any = await getSignedURLForDownload(
                    params,
                    interactionData?.InteractionLine.InteractionLineId,
                    globalState?.state?.findAgreementSessionData
                );
                const { bucketName } = data;

                documents.push({
                    BucketName: bucketName,
                    Path: `${fileName}.${extension}`,
                    Type: fileToUpload?.type,
                    Status: 'uploaded',
                });
                setLoading(false);
                setHideUploadBtn(true);
                globalState?.dispatch({
                    type: ActionTypes.SET_UPDATE_DEVICE_FILE,
                    payload: documents,
                });
            }
        }
    };

    // const convertBase64 = (file: any) => {
    //     return new Promise((resolve, reject) => {
    //         const fileReader = new FileReader();
    //         fileReader.readAsDataURL(file);

    //         fileReader.onload = () => {
    //             resolve(fileReader.result);
    //         };

    //         fileReader.onerror = (err) => {
    //             reject(err);
    //         };
    //     });
    // };

    // const base64URL = baseFile.split(',');
    // const base64String = base64URL[1];
    // let checkPDF
    // base64URL[0] === 'data:application/pdf;base64' ?
    //     checkPDF = 'pdf' : checkPDF = ''

    const handleOptions = (e: any) => {
        setOptionVal(e.target.value);
        globalState?.dispatch({
            type: ActionTypes.SET_UPDATE_DEVICE_INQ_DESC,
            payload: e.target.value,
        });
    };

    const isDisabled =
        optionVal !== '' && selectedFile && !fileError && !moreThan5Mb !== undefined ? false : true;

    const removeFile = () => {
        setHideUploadBtn(false);
        setFile(undefined);
        globalState?.dispatch({ type: ActionTypes.SET_UPDATE_DEVICE_BASE_URL, payload: '' });
        globalState?.dispatch({ type: ActionTypes.SET_UPDATE_DEVICE_FILE, payload: '' });
    };
    const [placeholderText, ] = useState<string>('');
    const [programmeNameLBL,] = useState('');
    const [optionsPlaceholderText, ] = useState<string>('');

    // const getPlaceHolder = async () => {
    //     const PlaceHolderText = await getTextScript(
    //         `${updateDeviceScrips.PageName}/placeHolder`,
    //         updateDeviceScrips.placeHolder,
    //         'en-US',
    //         []
    //     );
    //     setPlaceholderText(PlaceHolderText);
    // };

  return (
    <Box>
      <Box
        w={{ base: 'full', lg: '80%' }}
        margin="auto"
        pb={{ base: 0, lg: '150px' }}
        id={'myasurion-profile-dashboard'}
        paddingY={'54px'}
      // mb='20px'
      >
        <MainStackLayout gap={0} spacing={0}>
        <SubHeader
            backText={'Back'}
            allowBackButtonClick={true}
            showCancelButton={true}
            showBackButton={true}
            cancelText={Scripts.CancelSR.closeBtnTextClose}
            cancelTextKey='CloseBtn1'
            disableGlobalPageName= {true}
            onCancelClick={onCancelClick}
          ></SubHeader>
          <Box
            id={'myasurion-profile-avatar'}
            w={{ base: 'full', lg: '80%' }}
            margin="0 auto"
            h={'auto'}
          // p={'24px 16px'}
          >
            {assetData ? (<UpdateDevice
              title={isChinese? CNScripts.UpdateDeviceDetails.UpdateDevice.title :updateDeviceScrips.title}
              plan={programmeNameLBL || agreementData1?.ClientOffer?.ClientOfferName}
              mdn={`(${assetData?.CountryCallingCode?.split('+')?.[1] || agreementData1?.ContactPoints?.ContactPoint?.[0]?.CountryCallingCode}) ${assetData?.MobileDeviceNumber
                }`}
              model={`${assetData?.AssetCatalog?.AssetCatalogName}`}
              imei={`${assetData?.IMEI}`}
              serialNumber = {`${assetData?.IMEI}`}
              // imageEl={
              //   <Image
              //     height={'80px'}
              //     marginTop={'22px'}
              //     alignSelf={'center'}
              //     src={window.sessionStorage?.pd
              //       ? JSON.parse(window.sessionStorage?.pd)?.imageUrl
              //       : getDeviceImageByModel(
              //         globalState?.state?.deviceImages,
              //         `${assetData?.AssetCatalog.AssetFamily}`,
              //         deviceColor?.[deviceColor?.length - 1],
              //         80
              //       )}
              //   ></Image>
              // }
              imageEl={<Image
                height={'80px'}
                marginTop={'22px'}
                alignSelf={'center'}
                src={getDeviceImageByModel(
                  globalState?.state?.deviceImages,
                  `${assetData?.AssetCatalog.AssetFamily}`,
                  '',
                  80
                )}
              ></Image>}
              updateDeviceText={isChinese? CNScripts.UpdateDeviceDetails.UpdateDevice.updateDeviceText :updateDeviceScrips.updateDeviceText}
              updateDeviceQueLBL={isChinese? CNScripts.UpdateDeviceDetails.UpdateDevice.updateDeviceQue :updateDeviceScrips.updateDeviceQue}
              updateDeviceOptions={(isTOM || globalState?.state?.isMaxis) ? updateDeviceScrips.updateDeviceOptions : (isChinese? CNScripts.UpdateDeviceDetails.UpdateDevice.updateDeviceOptions :updateDeviceScrips.updateDeviceOptions.slice(0, -1))}
              uploadDocTextLBL={isChinese? CNScripts.UpdateDeviceDetails.UpdateDevice.uploadDocText :updateDeviceScrips.uploadDocText}
              placeHolder={placeholderText || (isChinese? CNScripts.UpdateDeviceDetails.UpdateDevice.placeHolder : updateDeviceScrips.placeHolder)}
              optionPlaceHolder={optionsPlaceholderText || (isChinese? CNScripts.UpdateDeviceDetails.UpdateDevice.optionsPlaceHolder : updateDeviceScrips.optionsPlaceHolder)}
              btnSubmitLBL={isChinese? CNScripts.UpdateDeviceDetails.UpdateDevice.btnSubmitLBL :updateDeviceScrips.btnSubmitLBL}
              isDisabled={isDisabled}
              onClickSubmit={() => handleSubmit()}
              handleFileUpload={(e: any) => handleFileUpload(e)}
              handleOptions={(e: any) => handleOptions(e)}
              onClickCancel={undefined}
              fileError={fileError}
              moreThan5Mb={moreThan5Mb}
              uploadFileText={isChinese? CNScripts.UpdateDeviceDetails.UpdateDevice.uploadFileText :updateDeviceScrips.uploadFileText}
              hideUploadBtn={hideUploadBtn}
              uploadedFileName={selectedFile}
              removeFile={() => removeFile()}
              FileErrorText={isChinese? CNScripts.UpdateDeviceDetails.UpdateDevice.FileErrorText :updateDeviceScrips?.FileErrorText}
            />) : (
              <Skeleton minW={'335px'} minH={'150px'} w={'full'} borderRadius={'8px'} />
            )}
            {/* {fileError &&
                <Text color='red' fontSize={'14px'} mt='8px' align={'center'}>Invalid file format</Text>
              }
              {moreThan5Mb &&
                <Text color='red' fontSize={'14px'} mt='8px' align={'center'}>You can upload files maximum of 5 MB per file</Text>
              } */}
                    </Box>
                </MainStackLayout>
                <CancelSrPopup
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
        </Box>
    );
}

export default UpdateDeviceDetails;
