/* eslint-disable no-console */
import {
    SubHeader,
    TextScript,
    getTextScript,
    useConfig,
    useSessionStorage,
    useTextScript,
} from '@MGPD/myasurion-shared';
import { Box, Button, Flex, Image, Link, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import Translationkeys from '../../Scripts/translationsKeys.json';
import barcodeImg from '../../icons/barcode.jpg';
import cardImg from '../../icons/card.png';
import MainStackLayout from '../../layout/MainStackLayout';
import { createECPayPaymentRequest, updateRepairRequest } from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { InvoiceOption } from '../../types/types';
import { CLEANING_SERVICE_INVOICE_TYPE } from '../../utils/constant';
import { getCurrencySymbol } from '../../utils/localization-helper';
import { saveError, showErrorPage } from '../../utils/utills';
import TextInput from '../component/TextInput';
import CancelSrPopup from '../myclaims/CancelSrPopup';
import ECPayStatusAlert from './ECPayStatusAlert';
import PaymentBreakdown from './PaymentBreakDown';
import { ResumeFlowDataKey, currentAgreementKey, serviceRequestKey } from '../../modules/config/constants';
import { isMalfuntionWarrantyEligible } from '../../utils/featuresUtills';

function SelectInvoice() {
    const globalState = useCentralStore();
    const navigate = useNavigate();

    const { isTranslationsLoading, language } = useTextScript();

    const [currencySymbol, setCurrencySymbol] = useState('');

    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [invoiceOption, setInvoiceOption] = useState<InvoiceOption>();
    const [showOtherInvoiceOption, setShowOtherInvoiceOption] = useState<boolean>(false);

    const [citizenDigital, setCitizenDigital] = useState<string>('');
    const [mobileBarcode, setMobileBarcode] = useState<string>('');
    const [placeholder, setInvoicePlaceholder] = useState<string>('');
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isOpenPayment, setOpenPayment] = useState<boolean>(false);
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [serviceRequestData] = useSessionStorage<any>(serviceRequestKey, null)
    const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null)
    const [currentAgreementData] = useSessionStorage<any>(currentAgreementKey, null);
    const [warrantyRepairFee] = useSessionStorage<any>('warrantyRepairFee', null)

    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { bodyTextColor } = colors;

    const SelectInvoiceScripts = Scripts.CleaningService.SelectInvoice;
    const SelectInvoiceTranslationkeys = Translationkeys.CleaningService.SelectInvoice;
    const pageName = SelectInvoiceTranslationkeys.PageName;

    const invoiceOptions: Array<InvoiceOption> = [
        { name: SelectInvoiceScripts.ECPay, type: CLEANING_SERVICE_INVOICE_TYPE.EC_PAY },
        {
            name: SelectInvoiceScripts.MobileBarcode,
            type: CLEANING_SERVICE_INVOICE_TYPE.MOBILE_BARCODE,
        },
        {
            name: SelectInvoiceScripts.CitizenDigital,
            type: CLEANING_SERVICE_INVOICE_TYPE.CITIZEN_DIGITAL,
        },
    ];

    const totalFee = warrantyRepairFee?.find((f: any) =>f.feeType == 'Total')?.fee

    useEffect(() => {
        getCurrency();
        getPlaceholder();
    }, [language, isTranslationsLoading]);

    useEffect(() => {
        if (invoiceOption) {
            if (isECPAYSelected(invoiceOption)) setIsDisabled(false);
            else if (mobileBarcode && isMobileBarcodeSelected()) setIsDisabled(false);
            else if (citizenDigital && isCitizenDigitalSelected()) setIsDisabled(false);
            else setIsDisabled(true);
        }
    }, [invoiceOption, mobileBarcode, citizenDigital]);

    const getCurrency = async () => {
        const currency = await getCurrencySymbol();
        setCurrencySymbol(currency);
    };

    const getPlaceholder = async () => {
        const placeholderLbl = await getTextScript(
            `${pageName}/${SelectInvoiceTranslationkeys.PlaceHolderInvoice}`,
            SelectInvoiceScripts.PlaceHolderInvoice,
            language,
            []
        );

        setInvoicePlaceholder(placeholderLbl);
    };

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const onSubmit = () => {
        // navigate('/cleaningSummary', { replace: true });

        callCreateECPayPaymentAPI();
    };
    
    const callUpdateRepairRequestApi = () => {
        const InitializeResponse = globalState?.state?.sessionResponse?.InitializeResponse
        const interactionData = globalState?.state?.sessionResponse?.Interaction;
        const payload = {
            UpdateRepairRequestParameter: {
                RepairRequestId: ResumeFlowData?.ServiceRequests[0]?.RepairRequest?.RepairRequestId,
                CustomerCaseId: ResumeFlowData?.CustomerCaseId,
                ServiceRequestId: ResumeFlowData?.ServiceRequests[0]?.ServiceRequestId,
                ServiceOrderId: ResumeFlowData?.ServiceRequests[0]?.ServiceOrder?.ServiceOrderId,
                InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
                ClientChannelId: InitializeResponse?.ClientChannelId,
                ClientId: InitializeResponse?.ClientId,
                ClientName: InitializeResponse?.ClientName,
                ServiceProviderId: globalState?.state?.deliveryAddress?.ServiceProviderId,
                // AppointmentDate:isDeviceRefrshRTLC ? '' : formatDate(globalState?.state?.deliveryDate) || formatDate(deliveryDate)
                // AppointmentDate: isDeviceRefrshRTLC
                //     ? ''
                //     : convertDateToUTC(
                //           `${globalState?.state?.deliveryDate || formatDate(deliveryDate)} ${
                //               globalState?.state?.deliveryTime || deliveryTime
                //           }`,
                //           'YYYY-MM-DD HH:mm:ssa',
                //           initializeData?.TimeZone
                //       ),
                // || convertDateToUTC(
                //   `${deliveryDate} ${deliveryTime}`,
                //   'YYYY-MM-DD HH:mm:ssa',
                //   initializeData?.TimeZone
                // ),
                RepairStatus: 'PROCEED WITH REPAIR',
                UpdateAction: 'HOMEPLUSNEWUPDATEACTION',
                RepairRequestType: 'PUR',
            },
        };
    
        setLoading(true);
        updateRepairRequest(
            payload,
            interactionData?.InteractionLine?.InteractionLineId,
            currentAgreementData?.AgreementId,
            globalState?.state?.findAgreementSessionData
        )
            .then(() => {
                setLoading(false);
                navigate('/claimconfirmation', { replace: true });
            })
            .catch((error) => {
                setLoading(false);
                console.error('error', error);
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    const callCreateECPayPaymentAPI = () => {
        const interactionData = globalState?.state?.sessionResponse?.Interaction;

        const cleaningIncidentData =
            globalState?.state?.cleaningIncidentData?.UpdateCleaningServiceRequest    
        const ServiceRequestId = cleaningIncidentData?.ServiceRequest?.ServiceRequestId || serviceRequestData?.ServiceRequest?.ServiceRequestId || ResumeFlowData?.ServiceRequests[0]?.ServiceRequestId
        const CustomerCaseId = cleaningIncidentData?.ServiceRequest?.CustomerCaseId || serviceRequestData?.CustomerCaseId || ResumeFlowData?.CustomerCaseId
        const interactionLineId = interactionData?.InteractionLine?.InteractionLineId

        const CreatePaymentRequest = {
            CreatePaymentRequest: {
                ServiceRequestId: ServiceRequestId,
                CustomerCaseId: CustomerCaseId,
                InteractionLineId: interactionLineId,
                InvoiceMethod: 'Mobile Barcode',
                IdentificationNbr: '12345434555',
            },
        };

        setLoading(true);

        createECPayPaymentRequest(
            CreatePaymentRequest,
            interactionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((data) => {
                setLoading(false);
                insertAndSubmitForm(data?.CreatePaymentResponse?.html_response);
                openAlert();
                if(isMalfuntionWarrantyEligible(currentAgreementData?.ClientOffer?.ClientOfferName)){
                    callUpdateRepairRequestApi();   
                }
                else navigate('/claimconfirmation', { replace: true });
            })
            .catch((error) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);                
                }
            );
    };

    const onCancelClick = () => {
        setOpenCancelPopup(!openCancelPopup);
    };

    const onBackClick = () => {
        if (showOtherInvoiceOption) {
            setShowOtherInvoiceOption(!showOtherInvoiceOption);
            setIsDisabled(true);
        } else if(isMalfuntionWarrantyEligible(currentAgreementData?.ClientOffer?.ClientOfferName)){
            navigate('/deviceoptions', { replace: true });
        } 
       else navigate('/cleaningReview', { replace: true });
    };

    const handleChange = (name: string) => {
        setInvoiceOption(getOptionByName(name));

        setShowOtherInvoiceOption(!isECPAYSelected(getOptionByName(name)));
    };

    const handleInputChange = (type: string, value: string) => {
        if (type === CLEANING_SERVICE_INVOICE_TYPE.MOBILE_BARCODE) {
            setMobileBarcode(value);
        } else if (type === CLEANING_SERVICE_INVOICE_TYPE.CITIZEN_DIGITAL) {
            setCitizenDigital(value);
        }
    };

    const getOptionByName = (name: string) => {
        const invoiceOption = invoiceOptions.find((option) => {
            return option?.name === name;
        });

        return invoiceOption;
    };

    const isECPAYSelected = (option?: InvoiceOption): boolean => {
        return option?.type === CLEANING_SERVICE_INVOICE_TYPE.EC_PAY;
    };

    const isMobileBarcodeSelected = (): boolean => {
        return invoiceOption?.type === CLEANING_SERVICE_INVOICE_TYPE.MOBILE_BARCODE;
    };

    const isCitizenDigitalSelected = (): boolean => {
        return invoiceOption?.type === CLEANING_SERVICE_INVOICE_TYPE.CITIZEN_DIGITAL;
    };

    const insertAndSubmitForm = (htmlString: string) => {
        // Create a container div
        const container = document.createElement('div');
        container.innerHTML = htmlString;

        // Append the container to the body
        document.body.appendChild(container);

        // Get the form from the container and submit it
        const form = container.querySelector('form');
        if (form) {
            form.submit();
        }
    };

    const openAlert = () => {
        setIsAlertOpen(true);
    };

    // Function to close the alert dialog
    const closeAlert = () => {
        setIsAlertOpen(false);
    };

    return (
        <Box paddingTop={'54px'} w="full">
            <MainStackLayout gap={0} spacing={0}>
                <Flex
                    w={{ base: 'full', lg: '80%' }}
                    h={'100%'}
                    margin="0 auto"
                    direction={'column'}
                >
                    <SubHeader
                        backText={'Back'}
                        allowBackButtonClick={false}
                        showCancelButton={true}
                        showBackButton={true}
                        onClick={onBackClick}
                        cancelText={Scripts.CancelSR.cancelBtnText}
                        onCancelClick={onCancelClick}
                    ></SubHeader>
                    {!showOtherInvoiceOption && (
                        <Box px={4} mx={'auto'} w={{ base: 'full', lg: '60%' }}>
                            <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
                                <TextScript
                                    text={SelectInvoiceScripts.HeaderText}
                                    translationKey={`${pageName}/${SelectInvoiceTranslationkeys.HeaderText}`}
                                />
                            </Text>

                            <RadioGroup mt={4} onChange={(name) => handleChange(name)}>
                                <VStack alignItems={'flex-start'}>
                                    {invoiceOptions.map((option, idx) => {
                                        return (
                                            <Box
                                                my={2}
                                                p={4}
                                                w={'100%'}
                                                border={'1px solid #A5AAAF'}
                                            >
                                                <Radio
                                                    borderColor={'#A5AAAF'}
                                                    value={option.name}
                                                    name={option.name}
                                                    key={`option-${idx}`}
                                                >
                                                    <Text fontSize={'xl'}>
                                                        <TextScript
                                                            text={option.name}
                                                            translationKey={`${pageName}/${SelectInvoiceTranslationkeys.InvoiceOption}-${option.name}`}
                                                        />
                                                    </Text>
                                                </Radio>
                                            </Box>
                                        );
                                    })}
                                </VStack>
                            </RadioGroup>
                        </Box>
                    )}

                    {showOtherInvoiceOption && isMobileBarcodeSelected() && (
                        <Box px={4} mx={'auto'} w={{ base: 'full', lg: '60%' }}>
                            <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
                                <TextScript
                                    text={SelectInvoiceScripts.MobileBarcodeHeader}
                                    translationKey={`${pageName}/${SelectInvoiceTranslationkeys.MobileBarcodeHeader}`}
                                />
                            </Text>

                            <Text mt={2} fontSize={'xl'}>
                                <TextScript
                                    text={SelectInvoiceScripts.MobileBarcodeTitle}
                                    translationKey={`${pageName}/${SelectInvoiceTranslationkeys.MobileBarcodeTitle}`}
                                />
                            </Text>

                            <Box my={'1rem'}>
                                <TextInput
                                    type="text"
                                    showIcon={false}
                                    borderColor={'#A5AAAF'}
                                    placeholder={
                                        placeholder || SelectInvoiceScripts.PlaceHolderInvoice
                                    }
                                    value={mobileBarcode}
                                    maxLength={40}
                                    onChange={(e: any) => {
                                        handleInputChange(
                                            CLEANING_SERVICE_INVOICE_TYPE.MOBILE_BARCODE,
                                            e.value
                                        );
                                    }}
                                />
                            </Box>

                            <Box
                                bg={'#F0F0F5'}
                                w={'100%'}
                                p={4}
                                textAlign={'center'}
                                mb={{ base: '45%', lg: '25%' }}
                            >
                                <Text mt={2} color={'#808080'}>
                                    <TextScript
                                        text={SelectInvoiceScripts.ExampleLbl}
                                        translationKey={`${pageName}/${SelectInvoiceTranslationkeys.ExampleLbl}`}
                                    />
                                </Text>

                                <Image
                                    my={'2'}
                                    marginX={'auto'}
                                    minHeight={'100px'}
                                    src={barcodeImg}
                                    alt="barcode"
                                />

                                <Text>
                                    <TextScript
                                        text={SelectInvoiceScripts.DummyBarcode}
                                        translationKey={`${pageName}/${SelectInvoiceTranslationkeys.DummyBarcode}`}
                                    />
                                </Text>
                            </Box>
                        </Box>
                    )}

                    {showOtherInvoiceOption && isCitizenDigitalSelected() && (
                        <Box
                            px={4}
                            mx={'auto'}
                            w={{ base: 'full', lg: '60%' }}
                            mb={{ base: '45%', lg: '25%' }}
                        >
                            <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
                                <TextScript
                                    text={SelectInvoiceScripts.CitizenDigitalHeader}
                                    translationKey={`${pageName}/${SelectInvoiceTranslationkeys.CitizenDigitalHeader}`}
                                />
                            </Text>

                            <Text mt={2} fontSize={'xl'}>
                                <TextScript
                                    text={SelectInvoiceScripts.CitizenDigitalTitle}
                                    translationKey={`${pageName}/${SelectInvoiceTranslationkeys.CitizenDigitalTitle}`}
                                />
                            </Text>

                            <Box my={'1rem'}>
                                <TextInput
                                    type="text"
                                    showIcon={false}
                                    borderColor={'#A5AAAF'}
                                    placeholder={
                                        placeholder || SelectInvoiceScripts.PlaceHolderInvoice
                                    }
                                    value={citizenDigital}
                                    maxLength={40}
                                    onChange={(e: any) => {
                                        handleInputChange(
                                            CLEANING_SERVICE_INVOICE_TYPE.CITIZEN_DIGITAL,
                                            e.value
                                        );
                                    }}
                                />
                            </Box>

                            <Box
                                bg={'#F0F0F5'}
                                w={'100%'}
                                p={4}
                                textAlign={'center'}
                                mb={{ base: '45%', lg: '25%' }}
                            >
                                <Text mt={2} color={'#808080'}>
                                    <TextScript
                                        text={SelectInvoiceScripts.ExampleLbl}
                                        translationKey={`${pageName}/${SelectInvoiceTranslationkeys.ExampleLbl}`}
                                    />
                                </Text>

                                <Image
                                    my={'2'}
                                    marginX={'auto'}
                                    minHeight={'100px'}
                                    src={cardImg}
                                    alt="card"
                                />
                            </Box>
                        </Box>
                    )}

                    <Box
                        w={'full'}
                        p={'16px'}
                        bg={'white'}
                        position={'fixed'}
                        bottom={0}
                        right={0}
                        left={0}
                        pb={{ base: '25%', lg: '2rem' }}
                        zIndex={'3'}
                        style={{ boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.08)' }}
                    >
                        <Flex
                            w={{ base: 'full', lg: '80%' }}
                            direction={'row'}
                            justifyContent={'space-between'}
                            mx={'auto'}
                        >
                            <Box>
                                <Text fontSize={'14px'}>
                                    <TextScript
                                        text={
                                            Scripts.CleaningService.SelectDeviceModel.CleaingFeeText
                                        }
                                        translationKey={`${pageName}/${Translationkeys.CleaningService.SelectDeviceModel.CleaingFeeText}`}
                                    />
                                </Text>
                                <Text lineHeight={'tall'} fontSize={'xl'} as={'b'}>
                                {totalFee ? `${totalFee}` : `${currencySymbol}${globalState?.state?.selectedDeviceModel?.PricewithDiscount}`}                                    
                                </Text>

                                <Link
                                    marginY={'auto'}
                                    marginLeft={'10px'}
                                    fontSize={'14px'}
                                    color={bodyTextColor}
                                    textDecoration={'underline'}
                                    onClick={() => setOpenPayment(!isOpenPayment)}
                                >
                                    <Text>
                                        <TextScript
                                            text={Scripts?.Payment?.lblBreakdown}
                                            translationKey={`${Translationkeys.Payment.PageName}/${Translationkeys.Payment.breakdown}`}
                                            dynamicValues={[
                                                isOpenPayment
                                                    ? Scripts?.Payment?.closeBreakdown
                                                    : Scripts?.Payment?.viewBreakdown,
                                            ]}
                                        />
                                    </Text>
                                </Link>
                            </Box>

                            <Button
                                width={'auto'}
                                textAlign={'center'}
                                colorScheme="primary"
                                color={'white'}
                                isDisabled={isDisabled}
                                onClick={() => onSubmit()}
                            >
                                <TextScript
                                    text={Scripts.Global.Next}
                                    translationKey={`${Translationkeys.Global.PageName}/${Translationkeys.Global.Next}`}
                                />
                            </Button>
                        </Flex>

                        {isOpenPayment && currencySymbol && (
                            <PaymentBreakdown currencySymbol={currencySymbol} />
                        )}
                    </Box>
                </Flex>
            </MainStackLayout>
            <CancelSrPopup
                closeCancelPopup={setOpenCancelPopup}
                openCancelPopup={openCancelPopup}
                isVAS={true}
            />
            <ECPayStatusAlert isOpen={isAlertOpen} onClose={closeAlert} />
        </Box>
    );
}

export default SelectInvoice;
