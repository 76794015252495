import {
    DeviceService,
    SubHeader,
    TextScript,
    getCarrierByDomain,
    getTextScript,
    getTypeByDomain,
    getUrlByTypeAndEnv,
    useConfig,
    useSessionStorage,
    useTextScript,
} from '@MGPD/myasurion-shared';
import { useDisclosure } from '@chakra-ui/react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogOverlay,
    Box,
    Button,
    Checkbox,
    Circle,
    Flex,
    Image,
    Square,
    Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CNScripts from '../Scripts/asurion-script-chinese.json';
import Scripts from '../Scripts/asurion-script.json';
import translationsKeys from '../Scripts/translationsKeys.json';
import Translationkeys from '../Scripts/translationsKeys.json';
import AlertIcon from '../assets/AlertIcon';
import MainStackLayout from '../layout/MainStackLayout';
import {
    ConfirmDeviceKey,
    ResumeFlowDataKey,
    assetDataKey,
    processIncidentKey,
    serviceFeeKey,
} from '../modules/config/constants';
import ActionTypes from '../store/actionTypes';
import { useCentralStore } from '../store/appContext';
import { INCIDENT_TYPES } from '../utils/constant';
import {
    checkIsOnlyRefresh,
    getTncLink,
    hasMultiDeviceSupport,
    hasTnCLink,
    hideIWHeaderText,
    isMalfuntionWarrantyEligible,
} from '../utils/featuresUtills';
// import { INCIDENT_TYPES } from '../utils/constant';
import { getDeviceImageByModel } from '../utils/get-device-images';
import { camelize, getFeeInclusiveTaxText } from '../utils/helper';
import {
    getCurrencySymbol,
    getPerilText,
    getProgrammeName,
    getSwapLBL,
} from '../utils/localization-helper';
import {
    getRedirectUrl,
    getSRFee,
    getServiceRequestFee,
    isBatteryReplacement,
    isChineseDefaultLanguage,
    isDeviceRefresh,
    isDeviceRefreshFree,
    isDeviceRepair,
    isEWSClaim,
    isInWarranty,
    isInWarrantyOEM,
    isMalfunction,
    isScreenRepair,
    isScreenRepairBatReplacement,
    isScreenRepairWarranty,
} from '../utils/utills';
import CancelSrPopup from './myclaims/CancelSrPopup';

export interface AssetData {
    MobileDeviceNumber: string;
    CountryCallingCode: string;
    AssetId: string;
    AssetCatalog: {
        AssetCatalogId: string;
        AssetCatalogName: string;
        AssetFamily: string;
    };
    IMEI: string;
    Model: {
        ModelId: string;
        Name: string;
    };
}

const Repair: React.FC = () => {
    const globalState = useCentralStore();
    const { isTranslationsLoading, language } = useTextScript();

    const carrier = getCarrierByDomain();
    const DPPortal_URL: any = getUrlByTypeAndEnv(
        carrier,
        'device_protection',
        import.meta.env.VITE_DOMAIN_ENV
    );
    const isTOM = carrier?.toUpperCase() === 'ASURION_TECHCARE';
    const RepairKeys = translationsKeys.Repair;
    // const [LFL, setLFL] = useState<MainObject[]>([]);
    const [whatsWrong, setWrong] = useState([]);
    const [openCancelPopup, setOpenCancelPopup] = useState(false);

    const [LFLModel, setLFLModel] = useState<{
        img: string;
        familyName: string;
        srf: string;
    }>({
        img: 'string',
        familyName: '',
        srf: '',
    });

    const [, setLFLModelSession] = useSessionStorage<any>(ConfirmDeviceKey, null);
    const [MultiSession] = useSessionStorage<any>('MultiOptionsData', null);

    const navigate = useNavigate();
    const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);
    const [ServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
    const [assetData] = useSessionStorage<any>(assetDataKey, null);

    const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
    const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
    const R_IncidentType = R_ServiceRequests?.IncidentType;

    const {
        isOpen: isToolTipOpen,
        onOpen: onToolTipOpen,
        onClose: onToolTipClose,
    } = useDisclosure();
    const cancelRef = React.useRef<any>();

    const incidentTypeFromState =
        globalState?.state?.IncidentType?.toUpperCase() === 'REFRESH'
            ? 'Device Refresh'
            : globalState?.state?.IncidentType?.toUpperCase();
    const IncidentType =
        serviceRequestData?.ServiceRequest?.IncidentType || R_IncidentType || incidentTypeFromState;
    const TermsAndConditionsKeys = Translationkeys.TermsAndConditionsPage;

    const [PerilType, setPerilType] = useState('');
    const [AlertSript, setAlertScript] = useState<string[]>([]);
    const [SwapLBL, setSwapLBL] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [programmeNameLBL, setProgrammeNameLBL] = useState('');

    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

    const currencyCode = globalState?.state?.sessionResponse?.InitializeResponse?.CurrencyCode;

    const assetData1 = globalState?.state?.selectedAssetDetails; //agreementData1?.Assets.Asset.filter((a: any) => a.AssetInstance === 'ENROLLED')[0];

    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { primary } = colors;

    // states to get localization scripts
    const [deviceRepairPopuptext1, setDeviceRepairPopuptext1] = useState('');
    const [batteryReplacementPopuptext1, setBatteryReplacementPopuptext1] = useState('');
    const [deviceRefreshPopuptext1, setDeviceRefreshPopuptext1] = useState('');
    const [batScrRplcmtPopuptext1, setBatScrRplcmtPopuptext1] = useState('');
    const [batScrRplcmtPopuptext2, setBatScrRplcmtPopuptext2] = useState('');
    const [screenRepairPopuptext1, setscreenRepairPopuptext1] = useState('');
    const [screenRepairPopuptext2, setscreenRepairPopuptext2] = useState('');
    const [deviceRepairPopuptext2, setDeviceRepairPopuptext2] = useState('');
    const [batteryReplacementPopuptext2, setBatteryReplacementPopuptext2] = useState('');
    const [deviceRefreshPopuptext2, setDeviceRefreshPopuptext2] = useState('');
    const [exploreOptions, setExploreOptions] = useState('');
    const [exploreSSOptions, setExploreSSOptions] = useState('');
    const [descriptionSwap, setDescriptionSwap] = useState('');
    const [SRFtext, setSRFtext] = useState('');
    const [RepairSRFtext, setRepairSRFtext] = useState('');
    const [AcceptText, setAcceptText] = useState('');
    const [termsLink, setTermsLink] = useState('');
    const Model: any = [LFLModel.familyName];
    const getDeviceRepairPopuptext1 = async () => {
        const repairText = await getTextScript(
            `${RepairKeys.PageName}/${RepairKeys.DeviceRepairPopuptext1}`,
            `${Scripts.Repair.DeviceRepairPopuptext1}`
        );
        setDeviceRepairPopuptext1(repairText);
    };

    const getDeviceRepairPopuptext2 = async () => {
        const repairText = await getTextScript(
            `${RepairKeys.PageName}/${RepairKeys.DeviceRepairPopuptext2}`,
            `${Scripts.Repair.DeviceRepairPopuptext2}`
        );
        setDeviceRepairPopuptext2(repairText);
    };

    const getBatteryReplacementPopuptext1 = async () => {
        const repairText = await getTextScript(
            `${RepairKeys.PageName}/${RepairKeys.BatteryReplacementPopuptext1}`,
            Scripts.Repair.BatteryReplacementPopuptext1
        );
        setBatteryReplacementPopuptext1(repairText);
    };

    const getBatteryReplacementPopuptext2 = async () => {
        const repairText = await getTextScript(
            `${RepairKeys.PageName}/${RepairKeys.BatteryReplacementPopuptext2}`,
            Scripts.Repair.BatteryReplacementPopuptext2,
            'en-US',
            [PerilType]
        );
        setBatteryReplacementPopuptext2(repairText);
    };

    const getDeviceRefreshPopuptext1 = async () => {
        const repairText = await getTextScript(
            `${RepairKeys.PageName}/${RepairKeys.DeviceRefreshPopuptext1}`,
            Scripts.Repair.DeviceRefreshPopuptext1,
            'en-US',
            [PerilType]
        );
        setDeviceRefreshPopuptext1(repairText);
    };

    const getDeviceRefreshPopuptext2 = async () => {
        const repairText = await getTextScript(
            `${RepairKeys.PageName}/${RepairKeys.DeviceRefreshPopuptext2}`,
            Scripts.Repair.DeviceRefreshPopuptext2,
            'en-US',
            [PerilType]
        );
        setDeviceRefreshPopuptext2(repairText);
    };

    const getBatScrRplcmtPopuptext1 = async () => {
        const repairText = await getTextScript(
            `${RepairKeys.PageName}/${RepairKeys.BatScrRplcmtPopuptextPopuptext1}`,
            Scripts.Repair.BatScrRplcmtPopuptext1,
            'en-US',
            [PerilType]
        );
        setBatScrRplcmtPopuptext1(repairText);
    };

    const getBatScrRplcmtPopuptext2 = async () => {
        const repairText = await getTextScript(
            `${RepairKeys.PageName}/${RepairKeys.BatScrRplcmtPopuptext2}`,
            Scripts.Repair.BatScrRplcmtPopupDesc,
            'en-US',
            [PerilType]
        );
        setBatScrRplcmtPopuptext2(repairText);
    };

    const getScreenRepairPopuptext1 = async () => {
        const repairText = await getTextScript(
            `${RepairKeys.PageName}/${RepairKeys.screenRepairPopuptext1}`,
            `${Scripts.Repair.ScreenRepairPopuptext1}`
        );
        setscreenRepairPopuptext1(repairText);
    };

    const getScreenRepairPopuptext2 = async () => {
        const repairText = await getTextScript(
            `${RepairKeys.PageName}/${RepairKeys.screenRepairPopuptext2}`,
            `${Scripts.Repair.ScreenRepairPopuptext2}`
        );
        setscreenRepairPopuptext2(repairText);
    };

    const getExploreOptions = async () => {
        const PlaceHolderText = await getTextScript(
            `${translationsKeys.Global.PageName}/${translationsKeys.Global.ExploreYourOptions}`,
            `${Scripts.Global.ExploreYourOptions}`
        );
        setExploreOptions(PlaceHolderText);
    };

    const getExploreSSOptions = async () => {
        const PlaceHolderText = await getTextScript(
            `${translationsKeys.Global.PageName}/${translationsKeys.Global.ExploreOptions}`,
            `${Scripts.Global.ExploreSmartSupportOptions}`,
            'en-US',
            [programmeNameLBL]
        );
        setExploreSSOptions(PlaceHolderText);
    };

    const getDescriptionSwap = async () => {
        const PlaceHolderText = await getTextScript(
            `${RepairKeys.PageName}/${RepairKeys.descriptionSwap}`,
            `${Scripts.Repair.descriptionSwap}`
        );
        setDescriptionSwap(PlaceHolderText);
    };

    const getSRFtext = async () => {
        const PlaceHolderText = await getTextScript(
            `${Translationkeys.Global.PageName}/${Translationkeys.Global.SRFLBL}`,
            Scripts.Global.SRFLBL
        );
        setSRFtext(PlaceHolderText);
    };

    let MultiSelect: any = [];
    useEffect(() => {
        if (MultiSession) {
            let count: any = 0;
            while (count < MultiSession.length) {
                MultiSession.map((a: any) => {
                    count = count + 1;
                    let obj = {
                        // label: a.label,
                        label: a.displayLabel,
                        selected: a.selected,
                        Numbers: count,
                    };
                    MultiSelect.push(obj);
                    setWrong(MultiSelect);
                });
            }
        } else if (isBatteryReplacement(IncidentType)) {
            let count = 1;
            let obj = {
                label: 'Battery health < 80%',
                selected: 'Battery health < 80%',
                Numbers: count,
            };
            MultiSelect.push(obj);
            setWrong(MultiSelect);
        } else if (isScreenRepairBatReplacement(IncidentType)) {
            let count = 0;
            const a: any = ['Screen cracked or chipped', 'Battery Related Issues'];
            while (count < a.length) {
                a.map((value: string) => {
                    count = count + 1;
                    let obj = {
                        label: value,
                        selected: value,
                        Numbers: count,
                    };
                    MultiSelect.push(obj);
                    setWrong(MultiSelect);
                });
            }
        }
        if (hasTnCLink(agreementData1?.ClientOffer?.ClientOfferName)) {
            setTermsLink(getTncLink(IncidentType, agreementData1?.ClientOffer?.ClientOfferName));
        } else setTermsLink('');
    }, []);

    useEffect(() => {
        if (termsLink !== undefined && PerilType) getAcceptText();
    }, [termsLink, PerilType]);

    let repairFee: any;
    if (
        IncidentType?.toUpperCase() ===
        INCIDENT_TYPES.SCREENREPAIR_BATTERYREPLACEMENT?.toUpperCase()
    ) {
        let scrFee =
            ServiceFeeData &&
            ServiceFeeData?.filter(
                (item: any) => item?.IncidentType === INCIDENT_TYPES.SCREEN_REPAIR?.toUpperCase()
            )?.[0]?.Fees?.TotalAmount;
        let batteryRplcmntFee =
            ServiceFeeData &&
            ServiceFeeData?.filter(
                (item: any) =>
                    item?.IncidentType === INCIDENT_TYPES.BATTERY_REPLACEMENT?.toUpperCase()
            )?.[0]?.Fees?.TotalAmount;
        repairFee = (Number(scrFee) + Number(batteryRplcmntFee)).toFixed(2);
    } else {
        repairFee =
            ServiceFeeData &&
            ServiceFeeData?.filter(
                (item: any) =>
                    item?.IncidentType ===
                    (isInWarrantyOEM(serviceRequestData)
                        ? INCIDENT_TYPES.MALFUNCTION_IW
                        : IncidentType?.toUpperCase())
            )?.[0]?.Fees?.TotalAmount;
    }

    const [ServiceRequestFeeText, setServiceRequestFeeText] = useState('');

    useEffect(() => {
        if (!isTranslationsLoading) {
            getCurrency();
            getProgrammeNameLbl();
            getSRFtext();
            getRepairSRFtext();
            getSwapLbl();
            getPerilLbl();
        }
    }, [language, isTranslationsLoading]);

    useEffect(() => {
        if (PerilType && PerilType?.length > 0) {
            getAlertScript();
            getServiceRequestFeeText();
            getDeviceRepairPopuptext1();
            getBatteryReplacementPopuptext1();
            getDeviceRefreshPopuptext1();
            getDeviceRepairPopuptext2();
            getBatteryReplacementPopuptext2();
            getDeviceRefreshPopuptext2();
            getScreenRepairPopuptext1();
            getScreenRepairPopuptext2();
            getExploreOptions();
            // getExploreSSOptions();
            getDescriptionSwap();
            getRefreshTermsText();
            getBatteryIWText();
            getRepairIWText();
            getScreenRepairIWTermsText();
            getMalfunctionsTerms();
            getBatScrRplcmtPopuptext1();
            getBatScrRplcmtPopuptext2();
            getBatScrReplIWTermsText();
            // getAcceptText();
        }
    }, [PerilType]);

    useEffect(() => {
        if (programmeNameLBL && programmeNameLBL?.length > 0) {
            getExploreSSOptions();
        }
    }, [programmeNameLBL]);

    const getPerilLbl = async () => {
        const isIW_OEM = isInWarrantyOEM(serviceRequestData);
        const isEWS = isEWSClaim(serviceRequestData, IncidentType);

        const incidentType = isIW_OEM
            ? INCIDENT_TYPES.MALFUNCTION_IW
            : isEWS
            ? INCIDENT_TYPES.MALFUNCTION_EWS
            : isMalfuntionWarrantyEligible(agreementData1?.ClientOffer?.ClientOfferName)
            ? INCIDENT_TYPES.FAULT_REPAIR
            : IncidentType;
        const defaultIncidentTypeLBL = isIW_OEM
            ? Scripts.Global.MalfunctionIW
            : isEWS
            ? Scripts.Global.MalfunctionEWS
            : IncidentType;

        const peril = await getPerilText(incidentType, defaultIncidentTypeLBL);        
        setPerilType(isIW_OEM || isEWS ? peril : camelize(peril)); // do not camelze for IW-OEM and ESW Peril Type
    };

    const getSwapLbl = async () => {
        const swapLBL = await getSwapLBL();
        setSwapLBL(swapLBL);
    };

    const getAlertScript = () => {
        const alertSript =
            IncidentType.toUpperCase() === 'DEVICE REFRESH'
                ? Scripts.Repair.DeviceRefreshAlert
                : (isDeviceRepair(IncidentType) && !globalState?.state?.isRepairRequestEligible)
                ? Scripts.Repair.DeviceRepairAlert
                : isBatteryReplacement(IncidentType)
                ? Scripts.Repair.BatteryReplacementAlert
                : isScreenRepair(IncidentType)
                ? Scripts.Repair.ScreenRepairAlert
                : isScreenRepairBatReplacement(IncidentType)
                ? Scripts.Repair.ScrRepairBatReplacement
                : [];

        setAlertScript(alertSript);
    };

    const getServiceRequestFeeText = async () => {
        let feeLBL = getFeeText();
        let feeVal = getFee();

        const returnText = await getTextScript(
            `${RepairKeys.PageName}/${RepairKeys.KeyRepairFee}`,
            `{1} <b>{2}{3}</b> {4}`,
            'en-US',
            [feeLBL, currencySymbol, feeVal, getFeeInclusiveTaxText(currencyCode)]
        );

        setServiceRequestFeeText(returnText);
    };

    const getCurrency = async () => {
        const currency = await getCurrencySymbol();
        setCurrencySymbol(currency);
    };

    const getProgrammeNameLbl = async () => {
        const programmeName = await getProgrammeName(agreementData1?.ClientOffer?.ClientOfferName);
        setProgrammeNameLBL(programmeName);
    };

    useEffect(() => {
        // isRepairFlow && saveLFLDevice(undefined, isRepairFlow);
        saveLFLDevice();
    }, []);

    const saveLFLDevice = (/*LFLDevice: any, isRepairFlow = false*/) => {
        const familyname =  assetData ? assetData?.AssetCatalog.AssetFamily
                            : R_ServiceRequests?.EnrolledDevice?.Model.Family
        const color = assetData?.Model?.Color || R_ServiceRequests?.EnrolledDevice?.AssetCatalog?.Color
        const LFLModel = {
            img: getDeviceImageByModel(
                globalState?.state?.deviceImages,
                familyname,
                color ?? '',
                80
            ),
            familyName: assetData?.AssetCatalog?.AssetCatalogName,
            srf: repairFee,
        };
        setLFLModel(LFLModel);
        setLFLModelSession(LFLModel);
        // setActualModel(updatedModel);
        // if (!isRepairFlow) {
        //   setLFL(LFLDevice);
        //   globalState?.dispatch({ type: ActionTypes.SET_LFLDevice, payload: LFLDevice });
        // }
    };

    const onYourProceedClick = () => {
        // Service Order API Call
        // setLoading(true)
        // if (isRepairFlow) {
        globalState?.dispatch({ type: ActionTypes.SRF, payload: repairFee });
        // } else globalState?.dispatch({ type: ActionTypes.SRF, payload: LFL[0].ServiceFee });

        if (isWarranty) {
            navigate('/deliveryoptions', { replace: true });
        } else if (/*isRepairFlow &&*/ assetData1?.Make?.Name?.toUpperCase() === 'APPLE') {
            navigate('/fmip', { replace: true });
        } else navigate('/terms', { replace: true });
    };

    const onCancelClick = () => {
        setOpenCancelPopup(!openCancelPopup);
    };

    const getFee = () => {
        // return isDeviceRefreshFree(agreementData1, IncidentType) ? '0' : isRepairFlow ? repairFee : LFLModel.srf
        if(globalState?.state?.isRepairRequestEligible){
            return ResumeFlowData?.RepairRequestQuatationAmount ?? '0'
        }
        else return isDeviceRefreshFree(agreementData1, IncidentType) ? '0' : repairFee;
    };

    const getFeeText = () => {
        return isDeviceRefresh(IncidentType) ? SRFtext : RepairSRFtext;
    };

    const isWarranty = !isMalfuntionWarrantyEligible(agreementData1?.ClientOffer?.ClientOfferName) &&
        isInWarranty(serviceRequestData) || isEWSClaim(serviceRequestData, IncidentType);
    const [RefreshText, setRefreshTerms] = useState('');
    const [BatteryIWText, setBatteryIWText] = useState('');
    const [RepairIWText, setRepairIWText] = useState('');
    const [ScreenRepairIWText, setScreenRepairIWText] = useState('');
    const [malfunctionTerms, setMalfunctionTerms] = useState('');
    const [scrReplIWTerms, setScrReplIWTerms] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const isChinese = isChineseDefaultLanguage();

    const getRefreshTermsText = async () => {
        const RefreshTermsText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.RefreshTermsText}`,
            `${Scripts.TermsAndConditionsNew.RefreshTerms}`
        );
        setRefreshTerms(RefreshTermsText);
    };

    const getBatteryIWText = async () => {
        let BatteryIWText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.BatteryIWTerms}`,
            `${Scripts.TermsAndConditionsNew.BatteryIW}`
        );
        let LFLSRFFees = getServiceRequestFee(ServiceFeeData, 'Battery Replacement');
        BatteryIWText = BatteryIWText.replace('totalAmount', LFLSRFFees);
        setBatteryIWText(BatteryIWText);
    };

    const getRepairIWText = async () => {
        const RepairIWText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.DeviceRepairIW}`,
            `${Scripts.TermsAndConditionsNew.DeviceRepairIW}`
        );
        setRepairIWText(RepairIWText);
    };

    const getScreenRepairIWTermsText = async () => {
        let RefreshTermsText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.ScreenRepairIWTerms}`,
            `${Scripts.TermsAndConditionsNew.ScreenRepairIWTerms}`
        );
        let LFLSRFFees = getServiceRequestFee(ServiceFeeData, 'Screen Repair');
        RefreshTermsText = RefreshTermsText.replace('totalAmount', LFLSRFFees);
        setScreenRepairIWText(RefreshTermsText);
    };

    const getBatScrReplIWTermsText = async () => {
        let BatScrReplIWTerms = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.BatScrReplIWTerms}`,
            `${Scripts.TermsAndConditionsNew.BatScrReplIWTerms}`
        );
        let LFLSRFFees = getServiceRequestFee(ServiceFeeData, 'Bat Scr Replacement');
        BatScrReplIWTerms = BatScrReplIWTerms.replace('totalAmount', LFLSRFFees);
        setScrReplIWTerms(BatScrReplIWTerms);
    };

    const getMalfunctionsTerms = async () => {
        const returnedText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.malfunctionsTerms}`,
            Scripts.TermsAndConditionsNew.malfunctionsTerms
        );
        setMalfunctionTerms(returnedText);
    };

    const getRepairSRFtext = async () => {
        const PlaceHolderText = await getTextScript(
            `${translationsKeys.Global.PageName}/${translationsKeys.Global.RepairFeeLBL}`,
            Scripts.Global.RepairFeeLBL
        );
        setRepairSRFtext(PlaceHolderText);
    };

    const getAcceptText = async () => {
        let AcceptText;
        if (isTOM && isDeviceRepair(IncidentType)) {
            AcceptText = await getTextScript(
                `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.AcceptTextTOM}`,
                `${Scripts.TermsAndConditionsNew.AcknowledgeTextTOM}`
            );
        } else {
            if (termsLink) {
                AcceptText = await getTextScript(
                    `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.AcceptLinkText}`,
                    `${Scripts.TermsAndConditionsNew.AcceptLinkText}`,
                    language,
                    [`${getIWHeaderText()} ${PerilType}`, termsLink]
                );
            } else {
                AcceptText = await getTextScript(
                    `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.AcceptText}`,
                    `${Scripts.TermsAndConditionsNew.AcknowledgeText}`,
                    language,
                    [`${getIWHeaderText()} ${PerilType}`]
                );
            }
        }
        setAcceptText(AcceptText);
    };

    const getIWHeaderText = () => {
        if (hideIWHeaderText(agreementData1?.ClientOffer?.ClientOfferName)) return '';

        return 'In-Warranty';
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
        // onchange(e.target.checked);
    };

    const handleTakeMeThere = () => {
        if (isTOM || globalState?.state?.isMaxis) {
            window.open(DPPortal_URL, '_blank');
        } else {
            window.location.href = getRedirectUrl(getTypeByDomain());
        }
    };

    return (
        <Box w="full">
            <Box id={'myasurion-triage'} paddingTop={'54px'} w="full">
                <MainStackLayout gap={'4px'} spacing={0} w="full" marginX={'auto'}>
                    <SubHeader
                        backText={''}
                        allowBackButtonClick={false}
                        showCancelButton={true}
                        showBackButton={false}
                        cancelText={Scripts.CancelSR.cancelBtnText}
                        onCancelClick={onCancelClick}
                    ></SubHeader>
                    {
                        /*(isRepairFlow) &&*/ PerilType && !isWarranty ? (
                            <Box
                                paddingBottom={{ base: '0', lg: '120px' }}
                                paddingX={{ base: '1rem', lg: '0' }}
                            >
                                <Flex justify={'space-evenly'}>
                                    <Box marginRight={{ base: '', lg: '9rem' }}>
                                        <Text fontSize={'2rem'}>
                                            <TextScript
                                                text={Scripts.Replacement.HeaderRepair}
                                                translationKey={`${RepairKeys.PageName}/${RepairKeys.KeyHeaderRepair}`}
                                                dynamicValues={[PerilType]}
                                            />
                                        </Text>
                                        <Text fontSize={'1.125rem'}>{LFLModel.familyName}</Text>
                                        <Box my={'4px'} display={{ base: 'none', lg: 'block' }}>
                                            {ServiceRequestFeeText && (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: ServiceRequestFeeText,
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                    <Square maxWidth={'148px'} maxHeight={'148px'} margin={'20px'}>
                                        <Image 
                                        src={LFLModel.img}                                         
                                        alt="your model" px={'0.25rem'} />
                                    </Square>
                                </Flex>
                                <Box w={{ base: 'full', lg: '70%' }} marginX={'auto'} mt={'16px'}>
                                    <Text
                                        as={'u'}
                                        mt={'16px'}
                                        onClick={onToolTipOpen}
                                        cursor={'pointer'}
                                    >
                                        <TextScript
                                            text={Scripts.Repair.RepairQuestionText}
                                            translationKey={`${RepairKeys.PageName}/${RepairKeys.KeyRepairQuestionText}`}
                                            dynamicValues={[PerilType]}
                                        />
                                    </Text>
                                    {((!isDeviceRefresh(IncidentType) ||
                                        isBatteryReplacement(IncidentType) ||
                                        isDeviceRepair(IncidentType)) && !globalState?.state?.isRepairRequestEligible) && (
                                        <Box mt={'24px'}>
                                            <Text as={'b'} mb={'8px'}>
                                                <TextScript
                                                    text={'What’s wrong with your device?'}
                                                    translationKey={`${RepairKeys.PageName}/${RepairKeys.keyLabelWhatsWrong}`}
                                                />
                                            </Text>
                                            {whatsWrong?.length > 0 &&
                                                whatsWrong.map((a: any) => (
                                                    <Flex
                                                        justifyContent={'flex-start'}
                                                        direction={'row'}
                                                    >
                                                        <Circle
                                                            maxWidth={'20px'}
                                                            maxHeight={'20px'}
                                                            padding={'7px'}
                                                            border={'1px solid #000'}
                                                            textAlign={'center'}
                                                            as={'b'}
                                                        >
                                                            {a.Numbers}
                                                        </Circle>
                                                        <Text ml={'8px'}>
                                                            {a.label}
                                                            {/* <TextScript
                              text={a.label}
                              translationKey={`${RepairKeys.PageName}/${RepairKeys.keyLabelWhatsWrong}${key}`}
                            /> */}
                                                        </Text>
                                                    </Flex>
                                                ))}
                                        </Box>
                                    )}
                                    {(isDeviceRefresh(IncidentType) ||
                                        IncidentType.toUpperCase() === 'SCREEN REFRESH') && (
                                        <Text mt={'16px'} fontWeight={'normal'}>
                                            <TextScript
                                                text={Scripts.Repair.DeviceRefreshNote}
                                                translationKey={`${RepairKeys.PageName}/${RepairKeys.KeyDeviceRefreshNote}`}
                                                dynamicValues={[PerilType]}
                                            />
                                        </Text>
                                    )}
                                    {!globalState?.state?.isRepairRequestEligible && <Text mt={'16px'} mb={'8px'} fontWeight={'normal'}>
                                        <TextScript
                                            text={Scripts.Repair.DeviceRepairText}
                                            translationKey={`${RepairKeys.PageName}/${RepairKeys.KeyDeviceRepairText}`}
                                            dynamicValues={[PerilType, SwapLBL, programmeNameLBL]}
                                        />
                                    </Text>}
                                    {AlertSript.map((a, key) => (
                                        <Flex>
                                            <Box flexDirection={'column'}>
                                                <AlertIcon />
                                            </Box>
                                            <Text ml={'8px'}>
                                                <TextScript
                                                    text={a}
                                                    translationKey={`${RepairKeys.PageName}/${
                                                        isBatteryReplacement(IncidentType)
                                                            ? RepairKeys.KeyBatteryReplacementAlert
                                                            : isDeviceRepair(IncidentType)
                                                            ? RepairKeys.KeyDeviceRepairAlert
                                                            : RepairKeys.KeyDeviceRefreshAlert
                                                    }${key}`}
                                                />
                                            </Text>
                                        </Flex>
                                    ))}
                                </Box>

                                <Box my={'24px'} display={{ base: 'block', lg: 'none' }}>
                                    {ServiceRequestFeeText && (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: ServiceRequestFeeText,
                                            }}
                                        />
                                    )}
                                    <Button
                                        variant="outline"
                                        onClick={onYourProceedClick}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        marginX={'auto'}
                                        bg={primary}
                                        textColor={'white'}
                                        width={{ base: 'full', lg: '50%' }}
                                        mt={{ base: '0.5rem', lg: '1rem' }}
                                    >
                                        <TextScript
                                            text={Scripts.Global.Proceed}
                                            translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Proceed}`}
                                        />
                                    </Button>
                                </Box>
                                {(!checkIsOnlyRefresh(
                                    agreementData1?.ClientOffer?.ClientOfferName
                                ) && !globalState?.state?.isRepairRequestEligible) && (
                                    <Box
                                        my={'31px'}
                                        w={{ base: 'full', lg: '70%' }}
                                        marginX={'auto'}
                                    >
                                        <DeviceService
                                            desc2=""
                                            tag={
                                                exploreOptions || Scripts.Global.ExploreYourOptions
                                            }
                                            title={
                                                exploreSSOptions ||
                                                Scripts.Global.ExploreSmartSupportOptions.replace(
                                                    '{1}',
                                                    agreementData1?.ClientOffer?.ClientOfferName
                                                )
                                            }
                                            desc={descriptionSwap}
                                            feeText={`${SRFtext} ${currencySymbol}${getSRFee(
                                                ServiceFeeData,
                                                INCIDENT_TYPES.SWAP
                                            )}`}
                                            deviceServiceButtonProps={{
                                                label: 'Take me there',
                                                onClick: handleTakeMeThere,
                                                disable: false,
                                            }}
                                        ></DeviceService>
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Box margin={'16px'}>
                                <Flex justify={'center'} mb={'24px'}>
                                    <Box marginRight={{ base: '', lg: '2rem' }}>
                                        {PerilType && (
                                            <Text fontSize={'2rem'}>
                                                <TextScript
                                                    text={
                                                        hideIWHeaderText(
                                                            agreementData1?.ClientOffer
                                                                ?.ClientOfferName
                                                        )
                                                            ? Scripts.Replacement.HeaderRepair
                                                            : Scripts.InwarrantyScripts.HeaderText
                                                    }
                                                    translationKey={
                                                        hideIWHeaderText(
                                                            agreementData1?.ClientOffer
                                                                ?.ClientOfferName
                                                        )
                                                            ? `${RepairKeys.PageName}/${RepairKeys.KeyHeaderRepair}`
                                                            : `${RepairKeys.PageName}/${RepairKeys.KeyInwarrantyHeader}`
                                                    }
                                                    dynamicValues={[PerilType]}
                                                />
                                            </Text>
                                        )}
                                        {isChinese ? (
                                            <Text>
                                                <TextScript
                                                    text={
                                                        isChinese
                                                            ? CNScripts.TermsAndConditionsNew
                                                                  .HeaderTextModel
                                                            : Scripts.TermsAndConditionsNew
                                                                  .HeaderTextModel
                                                    }
                                                    translationKey={`${RepairKeys.PageName}/${RepairKeys.KeyModelHeader}`}
                                                    dynamicValues={[Model]}
                                                />
                                            </Text>
                                        ) : (
                                            <Text fontSize={'1.125rem'}>
                                                {LFLModel.familyName}
                                                {/* check here */}
                                                {/* <TextScript
                    text={LFLModel.familyName}
                    translationKey={`${RepairKeys.PageName}/${RepairKeys.keyDeviceFamilyName}`}
                  /> */}
                                            </Text>
                                        )}
                                    </Box>
                                    {!hasMultiDeviceSupport(
                                        agreementData1?.ClientOffer?.ClientOfferName
                                    ) && (
                                        <Square
                                            maxWidth={'148px'}
                                            maxHeight={'148px'}
                                            margin={'20px'}
                                        >
                                            <Image
                                                src={LFLModel.img}                                                
                                                alt="your model"
                                                px={'0.25rem'}
                                            />
                                        </Square>
                                    )}
                                </Flex>
                                <Box>
                                    <Box w="full" m="0">
                                        <Box
                                            className={''}
                                            w={{ base: 'full', lg: '50%' }}
                                            margin={{ base: '0', lg: 'auto' }}
                                            pb={{ base: '0', lg: '0' }}
                                        >
                                            <Text fontSize={'24px'}>
                                                <TextScript
                                                    text="Terms and Conditions"
                                                    translationKey={`${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.HeaderIW}`}
                                                />
                                            </Text>
                                            <Box marginTop={'10px'} mx={'10px'}>
                                                {isDeviceRefresh(IncidentType) ? (
                                                    <Box>
                                                        {RefreshText && (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: RefreshText,
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                ) : isBatteryReplacement(IncidentType) ? (
                                                    <Box>
                                                        {BatteryIWText && (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: BatteryIWText,
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                ) : isDeviceRepair(IncidentType) ? (
                                                    <Box>
                                                        {RepairIWText && (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: RepairIWText,
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                ) : isScreenRepairWarranty(IncidentType) ? (
                                                    <Box>
                                                        {ScreenRepairIWText && (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: ScreenRepairIWText,
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                ) : isMalfunction(IncidentType) ? (
                                                    <Box>
                                                        {malfunctionTerms && (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: malfunctionTerms,
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                ) : isScreenRepairBatReplacement(IncidentType) ? (
                                                    <Box>
                                                        {scrReplIWTerms && (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: scrReplIWTerms,
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                ) : (
                                                    []
                                                )}
                                            </Box>
                                            <Box
                                                display={{ base: 'none', lg: 'block' }}
                                                position="fixed"
                                                bottom={0}
                                                w="full"
                                                bgColor={{ base: '', lg: '#faf9f5' }}
                                            >
                                                <hr
                                                    style={{
                                                        border: 'none',
                                                        borderTop: '2px solid #adaaaa',
                                                    }}
                                                />
                                            </Box>
                                            <Flex
                                                pb={{ base: '0', lg: '100px' }}
                                                mb={'auto'}
                                                w={{ base: 'full', lg: '100%' }}
                                                marginLeft={'auto'}
                                                marginRight={'auto'}
                                                justifyContent={'start'}
                                            >
                                                <Checkbox
                                                    marginBottom={'24px'}
                                                    marginTop={'24px'}
                                                    alignItems={'flex-start'}
                                                    size="lg"
                                                    onChange={handleCheckboxChange}
                                                    colorScheme="primary"
                                                ></Checkbox>
                                                {PerilType && (
                                                    <Text
                                                        marginBottom={'24px'}
                                                        marginTop={'24px'}
                                                        marginLeft={'8px'}
                                                        size={'16px'}
                                                    >
                                                        {/* {`I accept the ${hideIWHeaderText(agreementData1?.ClientOffer?.ClientOfferName) ? '' : 'In-Warranty'} ${PerilType} Terms and Conditions`} */}
                                                        {AcceptText && (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: AcceptText,
                                                                }}
                                                            />
                                                        )}
                                                    </Text>
                                                )}
                                            </Flex>
                                        </Box>
                                        {
                                            <Box display={{ base: 'block', lg: 'none' }}>
                                                <Box marginBottom={'8px'}>
                                                    {''} <b>{''}</b>
                                                </Box>
                                                <Box display="flex" justifyContent="center">
                                                    <Button
                                                        isDisabled={!isChecked}
                                                        color="white"
                                                        colorScheme="primary"
                                                        width="343px"
                                                        mb={'16px'}
                                                        onClick={() => onYourProceedClick()}
                                                    >
                                                        <TextScript
                                                            text={Scripts.Global.Proceed}
                                                            translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Proceed}`}
                                                        />
                                                    </Button>
                                                </Box>
                                            </Box>
                                        }
                                        <Box
                                            display={{ base: 'none', lg: 'block' }}
                                            position="fixed"
                                            bottom={0}
                                            marginTop={'auto'}
                                            w="full"
                                            bgColor={{ base: '', lg: '#faf9f5' }}
                                        >
                                            <hr
                                                style={{
                                                    border: 'none',
                                                    borderTop: '2px solid #adaaaa',
                                                }}
                                            />
                                            <Flex
                                                direction="row"
                                                p={4}
                                                w={'50%'}
                                                mx={'auto'}
                                                justifyContent="end"
                                            >
                                                <Box>
                                                    {' '}
                                                    <Box marginBottom={'8px'}>
                                                        {''} <b>{''}</b>
                                                    </Box>
                                                    <Box display="flex">
                                                        <Button
                                                            isDisabled={!isChecked}
                                                            color="white"
                                                            colorScheme="primary"
                                                            width="200px"
                                                            mb={'16px'}
                                                            onClick={() => onYourProceedClick()}
                                                        >
                                                            <TextScript
                                                                text={'Proceed'}
                                                                translationKey={`${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.BtnProceed}`}
                                                            />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                {/* {isWarranty?.length === 0  && (
                        <Box marginBottom={'8px'}>
                          <Text fontSize="14px">{getFeeLabel()}</Text>
                          <Text fontWeight="bold" fontSize="24px">
                            {LFLModel.srf}
                          </Text>
                        </Box>
                      )} */}
                                                {/* <Box> */}
                                                {/* <Box display="flex" justifyContent="center">
              <Button
                isDisabled={!isChecked}
                color="white"
                colorScheme="primary"
                //onClick={onSubmit}
                width="200px"
              >
                <TextScript
                  text={'Submit'}
                  translationKey={`${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.FeeText}`}
                />
              </Button>
            </Box> */}

                                                <Box></Box>
                                            </Flex>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    }
                    <Box>
                        <AlertDialog
                            motionPreset="slideInBottom"
                            leastDestructiveRef={cancelRef}
                            onClose={onToolTipClose}
                            isOpen={isToolTipOpen}
                            isCentered
                            //  colorScheme={'#F0F0F5'}
                        >
                            <AlertDialogOverlay />
                            <AlertDialogContent margin={'32px'} background={'#F0F0F5'}>
                                <AlertDialogCloseButton />
                                <AlertDialogBody px={'8px'} mt={'16px'} fontSize={'14px'}>
                                    <Text as={'b'}>
                                        <TextScript
                                            text={'What’s included?'}
                                            translationKey={`${RepairKeys.PageName}/${RepairKeys.keyLabelWhatsIncluded}`}
                                        />
                                    </Text>
                                    <Text mt={'4px'} mb={'20px'}>
                                        {isDeviceRepair(IncidentType)
                                            ? deviceRepairPopuptext1
                                            : isBatteryReplacement(IncidentType)
                                            ? batteryReplacementPopuptext1
                                            : isScreenRepair(IncidentType)
                                            ? screenRepairPopuptext1
                                            : isScreenRepairBatReplacement(IncidentType)
                                            ? batScrRplcmtPopuptext1
                                            : deviceRefreshPopuptext1}
                                    </Text>

                                    <Text as={'b'}>
                                        <TextScript
                                            text={
                                                isBatteryReplacement(IncidentType)
                                                    ? 'Battery Replacement Warranty'
                                                    : isDeviceRepair(IncidentType)
                                                    ? 'Repair Warranty'
                                                    : isScreenRepair(IncidentType)
                                                    ? 'Screen Repair Warranty'
                                                    : isScreenRepairBatReplacement(IncidentType)
                                                    ? 'Screen Repair and Battery Replacement Warranty'
                                                    : ''
                                            }
                                            translationKey={`${RepairKeys.PageName}/${
                                                isBatteryReplacement(IncidentType)
                                                    ? RepairKeys.keyLabelBatReplacementWarranty
                                                    : isScreenRepair(IncidentType)
                                                    ? RepairKeys.keyLabelScreenRepairWarranty
                                                    : isScreenRepairBatReplacement(IncidentType)
                                                    ? RepairKeys.keyLabelBatScrReplacementWarranty
                                                    : RepairKeys.keyLabelRepairWarranty
                                            }`}
                                        />
                                    </Text>

                                    <Text>
                                        {isDeviceRepair(IncidentType)
                                            ? `${deviceRepairPopuptext2}`
                                            : isBatteryReplacement(IncidentType)
                                            ? `${batteryReplacementPopuptext2}`
                                            : isScreenRepair(IncidentType)
                                            ? `${screenRepairPopuptext2}`
                                            : isScreenRepairBatReplacement(IncidentType)
                                            ? batScrRplcmtPopuptext2
                                            : deviceRefreshPopuptext2}
                                    </Text>
                                </AlertDialogBody>
                            </AlertDialogContent>
                        </AlertDialog>
                    </Box>
                </MainStackLayout>
                <CancelSrPopup
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
            {
                /*isRepairFlow &&*/ !isWarranty && (
                    <Flex
                        w="full"
                        p={5}
                        justifyContent="space-evenly"
                        alignItems={'center'}
                        display={{ base: 'none', lg: 'flex' }}
                        position={{ base: 'relative', lg: 'fixed' }}
                        bottom="0"
                        bgColor={{ base: '', lg: '#faf9f5' }}
                    >
                        <Button
                            variant="outline"
                            onClick={onYourProceedClick}
                            display={'flex'}
                            justifyContent={'center'}
                            bg={primary}
                            textColor={'white'}
                            width={'10%'}
                        >
                            <TextScript
                                text={Scripts.Global.Proceed}
                                translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Proceed}`}
                            />
                        </Button>
                    </Flex>
                )
            }
        </Box>
    );
};

export default Repair;
