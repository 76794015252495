import { SubHeader, TextScript, useConfig, useSessionStorage, getCarrierByDomain } from '@MGPD/myasurion-shared';
import { Box, Button, Center, Image, Stack, Text } from '@chakra-ui/react';
import { auto } from '@popperjs/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import source1 from '../../assets/images/Step1.png';
import source2 from '../../assets/images/Step2.png';
import source3 from '../../assets/images/Step3.png';

import FindMyIphone1 from '../../assets/images/jb_fmip_instructions/01-Find_My_Iphone.svg';
import FindMyIphone2 from '../../assets/images/jb_fmip_instructions/02-Find_My_Iphone.svg';
import FindMyIphone3 from '../../assets/images/jb_fmip_instructions/03-Find_My_Iphone.svg';

import CancelSrPopup from '../myclaims/CancelSrPopup';
import useContentfulImage from '../../hooks/useContentfulImage';
import MainStackLayout from '../../layout/MainStackLayout';
import { submitRequestKey } from '../../modules/config/constants';
import { CONTENTFUL_SLUG } from '../../utils/constant';
import { isJBHifiCarrier } from '@/utils/config';

function FmipInstructions() {
    const navigate = useNavigate();

  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const FmipInstructionKeys = translationsKeys.fmipInstructions
  const [setSRSubmits] = useSessionStorage<any>(submitRequestKey, null);

  const carrier = getCarrierByDomain();
  const isJBHifiUpgrade = isJBHifiCarrier(carrier);

    const fmip1 = useContentfulImage(CONTENTFUL_SLUG.FMIP_STEP1);
    const fmip2 = useContentfulImage(CONTENTFUL_SLUG.FMIP_STEP2);
    const fmip3 = useContentfulImage(CONTENTFUL_SLUG.FMIP_STEP3);

    const onGoBack = () => {
        if (setSRSubmits === 'Yes') {
            navigate('/claimconfirmation', { replace: true });
        } else {
            navigate('/fmip', { replace: true });
        }
    };

    const onCancelClick = () => {
        setOpenCancelPopup(!openCancelPopup);
    };
    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { accent } = colors;

    return (
        <Center>
            <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'} w="full">
                <MainStackLayout gap={0} spacing={0}>
                    {/* <Box id={'myasurion-profile-avatar'} w={'full'} h={'auto'} p={'24px 18px'}>
            <Breadcrumb spacing="8px" separator={<ChevronRightIcon />}>
              <BreadcrumbItem>
                <BreadcrumbLink href="#">Service request</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href="#">Turn off Find my Device</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Box> */}
          <Box
            w={{ base: 'full', lg: '80%' }}
            marginX={{ base: '', lg: 'auto' }}
            mb={{ base: '0', lg: '20px' }}
          >
            <SubHeader
              backText={'Back'}
              onClick={onGoBack}
              allowBackButtonClick={false}
              showCancelButton={true}
              showBackButton={true}
              cancelText={Scripts.CancelSR.cancelBtnText}
              onCancelClick={onCancelClick}
            ></SubHeader>
          </Box>
          <Box
            id={'myasurion-profile-avatar'}
            alignItems="center"
            h={'auto'}
            p={'16px 18px'}
            w={{ base: 'full', lg: '40%' }}
            marginX={{ base: '', lg: 'auto' }}
          >
            <Text fontSize={'24px'} font-weight={'300'} line-height={'31.68px'} mb="10px">
            <TextScript
                  text={'How to turn off FMIP'}
                  translationKey={`${FmipInstructionKeys.PageName}/${FmipInstructionKeys.HeaderText}`}/>
            </Text>
            <Text mb="8px" fontSize={'24px'} as="b">
            <TextScript
                  text={isJBHifiUpgrade ? 'Step 1': 'step 1'}
                  translationKey={`${FmipInstructionKeys.PageName}/${FmipInstructionKeys.step1}`}/>
            </Text>
            <Text mb="24px">
            <TextScript
                  text={'To switch off the ‘Find my iPhone’ option, tap the Settings icon on your phone.'}
                  translationKey={`${FmipInstructionKeys.PageName}/${FmipInstructionKeys.step1Text}`}/>
            </Text>
            <Box mb="24px">
                <Image src={isJBHifiUpgrade ? FindMyIphone1 : fmip1?.imageUrl || source1} margin="auto" alt="no-image" />
            </Box>
            <Text mb="8px" fontSize={'24px'} as="b">
            <TextScript
                  text={isJBHifiUpgrade ? 'Step 2': 'step 2'}
                  translationKey={`${FmipInstructionKeys.PageName}/${FmipInstructionKeys.step2}`}/>
            </Text>
            <Text mb="24px">
            <TextScript
                  text={'Then tap Account Settings. It usually has your name, then iCloud.'}
                  translationKey={`${FmipInstructionKeys.PageName}/${FmipInstructionKeys.step2Text}`}/>
            </Text>
            <Box mb="24px">
              <Image src={isJBHifiUpgrade ? FindMyIphone2 : fmip2?.imageUrl || source2} margin={auto} alt="no-image" />
            </Box>
            <Text mb="8px" fontSize={'24px'} as="b">
            <TextScript
                  text={isJBHifiUpgrade ? 'Step 3': 'step 3'}
                  translationKey={`${FmipInstructionKeys.PageName}/${FmipInstructionKeys.step3}`}/>
            </Text>
            <Text mb="24px">
            <TextScript
                  text={'Scroll down till you find the ‘Find my iPhone’ toggle then deselect it.'}
                  translationKey={`${FmipInstructionKeys.PageName}/${FmipInstructionKeys.step3Text}`}/>
            </Text>
            <Box mb="24px">
              <Image src={isJBHifiUpgrade ? FindMyIphone3 : fmip3?.imageUrl || source3} margin={auto} alt="no-image" />
            </Box>
            <Stack minWidth="max-content" alignItems="center" gap="2">
              {/* <Button
              colorScheme={primary}
              color={primary}
              variant="outline"
              width={'343px'}
              height="40px"
              onClick={() => 'z'}
            >
              Retry
            </Button> */}
                            {/* <Button
            as={'u'}
              colorScheme={primary}
              color={primary}
              variant="link"
              height="40px"
              onClick={() => 'y'}
            >
              How to turn off Find my iPhone
            </Button> */}
                            <Button
                                as={'u'}
                                colorScheme={accent}
                                color={accent}
                                variant="link"
                                height="40px"
                                mb={'13px'}
                                onClick={onGoBack}
                                cursor="pointer"
                            >
                                <TextScript
                                    text={'Go Back'}
                                    translationKey={`${FmipInstructionKeys.PageName}/${FmipInstructionKeys.Gobackbtnlbl}`}
                                />
                            </Button>
                        </Stack>
                    </Box>
                </MainStackLayout>
                <CancelSrPopup
                closeCancelPopup={setOpenCancelPopup}
                openCancelPopup={openCancelPopup}
            />
            </Box>        
        </Center>
    );
}

export default FmipInstructions;
