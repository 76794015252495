/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    DeliveryOptions,
    SubHeader,
    TextScript,
    getTextScript,
    useAccount,
    useConfig,
    useSessionStorage,
} from '@MGPD/myasurion-shared';
import { Box, Button } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import DeliveryOptionsScripts from '../Scripts/asurion-script.json';
import translationsKeys from '../Scripts/translationsKeys.json';
import {
    BGTokenKey,
    InWarrantyDataKey,
    LFLStockKey,
    ResumeFlowDataKey,
    assetDataKey,
    currentAgreementKey,
    deliveryTypeKey,
    processIncidentKey, // interactionLineKey,
} from '../modules/config/constants';
import {
    getClientToken,
    getSecurityTokenApi,
    shippingmethodsv1Api,
    shippingmethodsv2Api,
    shippingmethodsv3Api,
} from '../services/api';
import ActionTypes from '../store/actionTypes';
import { useCentralStore } from '../store/appContext';
import { deliveryOption } from '../types/types.ts';
import { INCIDENT_TYPES, LOGISTICS_TYPES } from '../utils/constant.tsx';
import features from '../utils/features.json';
import {
    callShippingMethodv1,
    getDeliveryOptions,
    getDeliverySlot,
    hasBrainTreePaymentGateway,
    isMalfuntionWarrantyEligible,
} from '../utils/featuresUtills';
import { camelize, getMaid, getPickupSlot, isClaimWithinCutoff } from '../utils/helper';
import {
    isBatteryReplacement,
    isDeviceRefresh,
    isDeviceRepair,
    isEWSClaim,
    isInWarranty,
    isInWarrantyOEM,
    isMalfunction,
    isPUR,
    isReplacement,
    isScreenRepair,
    isScreenRepairBatReplacement,
    isScreenRepairWarranty,
    isSwap,
    isWalkIn,
    saveError,
    showErrorPage,
} from '../utils/utills.tsx';
import CancelSrPopup from './myclaims/CancelSrPopup.tsx';

const DeliveryOptionsContainer = () => {
    const navigate = useNavigate();
    const globalState = useCentralStore();
    const [account] = useAccount({ firstname: 'firstName', lastname: 'lastName', email: 'email' });
    const deliveryOptionsScripts = DeliveryOptionsScripts?.DeliveryOptions;

    // const [createServiceOrderRequest] = useSessionStorage<any>(createServiceOrderDataKey, null);
    // const [interactionLineId] = useSessionStorage<any>(interactionLineKey, null);
    // const [startServiceRequestResponse] = useSessionStorage<any>(serviceRequestKey, null);
    const [find_agreement] = useSessionStorage<any>(currentAgreementKey, null);

    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const initializeData = globalState?.state?.sessionResponse?.InitializeResponse;
    const isTOM = initializeData?.ClientName === 'Asurion_TechCare';
    const isBraintree = initializeData?.isBraintree;

    const [InWarrantyData] = useSessionStorage<any>(InWarrantyDataKey, null);
    const [assetData] = useSessionStorage<any>(assetDataKey, null);
    const [, setSecurityTokenData] = useSessionStorage<any>(BGTokenKey, null);
    const [ResumeFlowData, setResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
    const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);
    const [processIncidentResponse, setProcessIncidentResponse] = useState<any>();
    const [acceptClick, setAcceptClick] = useState(false);
    const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
    const R_ServiceRequestId = R_ServiceRequests?.ServiceRequestId;
    // const R_CustomerCaseId = R_ServiceRequests?.CustomerCaseId;
    const R_IncidentType = R_ServiceRequests?.IncidentType;
    const R_ServiceOrderId = R_ServiceRequests?.ServiceOrder?.ServiceOrderId;
    const fullFillmentOption =
        InWarrantyData?.FulfillmentOption || R_ServiceRequests?.FulfillmentOption;
    const IncidentType = globalState?.state?.IncidentType?.toUpperCase();
    const maid = getMaid(IncidentType || R_IncidentType, isBraintree);

    const R_FulfillmentOption = isPUR(fullFillmentOption)
        ? LOGISTICS_TYPES.PUR
        : isWalkIn(fullFillmentOption)
        ? LOGISTICS_TYPES.WALKIN
        : fullFillmentOption;

    // const { ServiceRequest } = startServiceRequestResponse;
    const agreementData: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const ServiceOrderId =
        globalState?.state?.serviceOrderData?.CreateServiceOrderResults?.ServiceOrderId ||
        R_ServiceOrderId;

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const [, setSRDeliveryType] = useSessionStorage<any>(deliveryTypeKey, null);

    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [, setShippingMethodResponse] = useState<any>();

    const [deliveryType, setDeliveryType] = useState<deliveryOption>();
    const [deliveryTypeList, setDeliveryTypeList] = useState<deliveryOption[]>([]);
    // const isSS4 = find_agreement?.IsSmartSupport4
    const enableButton = deliveryType ? true : false;
    const [, setShippingMethod] = useSessionStorage<any>('shippingMethodResponse', null);
    // const [, setShippingMethodV2] = useSessionStorage<any>('shippingMethodV2', null);
    const incidentTypeUpperCase = globalState?.state?.IncidentType?.toUpperCase();
    const incidentTypeFromState =
        incidentTypeUpperCase === 'REFRESH' ? 'Device Refresh' : incidentTypeUpperCase;

    const processIncData = globalState?.state?.processIncData || serviceRequestData;
    // const isSwapEligible = globalState?.state?.SwapConversion
    const incType = camelize(
        globalState?.state?.processIncData?.ServiceRequest?.IncidentType ||
            R_IncidentType ||
            incidentTypeFromState
    );

    const [LFLStock] = useSessionStorage<any>(LFLStockKey, null);
    const isBackOrder =
        (isTOM || globalState?.state?.isMaxis) &&
        (R_ServiceRequests?.ServiceOrder?.ServiceOrderStatus?.toUpperCase() === 'BORD' ||
            LFLStock === false);

    const IsWarranty = isInWarranty(serviceRequestData) || isEWSClaim(serviceRequestData, incType);
    const isSwapConversion = globalState?.state?.SwapConversion;
    // const _deliveryTypes =
    //   incType?.toLowerCase() === 'device refresh' ||
    //     incType?.toLowerCase() === 'fault repair' ||
    //     isBatteryReplacement(incType)
    //     ? ['Doorstep pick-up and return', 'Walk-in to your preferred location']
    //     : ['Delivery', 'Walk-in'];

    // const assetData1 = agreementData?.Assets?.Asset?.filter(
    //   (a: any) => a?.AssetInstance === 'ENROLLED'
    // )?.[0];

    let assetData1 = agreementData?.Assets?.Asset?.filter(
        (asset: any) => asset?.AssetInstance === 'ENROLLED'
    )?.[0];
    if (assetData1 === undefined) {
        assetData1 = agreementData?.Assets?.Asset?.filter(
            (asset: any) => asset?.AssetInstance === 'REPLACED'
        )?.[0];
    }

    const PageName = 'DeliveryOptions';

    useEffect(() => {
        if (deliveryTypeList.length === 1) {
            handleDeliveryType(deliveryTypeList[0]?.key);
        }
    }, [deliveryTypeList]);

    useEffect(() => {
        if (deliveryTypeList.length === 1) {
            setAcceptClick(true);
            handleOnClick();
        }
    }, [deliveryType]);

    useEffect(() => {
        getDeliveryTypeList();
    }, [incType]);

    const getDeliveryText = async () => {
        const returnedText1: string = await getTextScript(
            `${translationsKeys.Global.PageName}/${translationsKeys.Global.Delivery}`,
            DeliveryOptionsScripts.Global.Delivery
        );
        return returnedText1;
    };

    const getDeliveryTypeList = async () => {
        if (isSwapConversion) {
            const returnedText1: string = await getDeliveryText();
            let deliveryoption = { key: LOGISTICS_TYPES.DELIVERY, label: returnedText1 };
            setDeliveryTypeList([...deliveryTypeList, deliveryoption]);
        } else {
            /*else if (isRepairRefresh) {
      const returnedText1: string = await getTextScript(
        `${PageName}/DoorstepPUR`,
        'Doorstep pick-up and return',        
      );

      deliveryoption = [{key: LOGISTICS_TYPES.PUR, label: returnedText1}]

      // for maxis device repair we are not showing Walk-in option
      if(isDeviceRepair(incType) && !globalState?.state?.isMaxis){
        const returnedText2: string = await getTextScript(
          `${PageName}/walkinToLocation`,
          'Walk-in to your preferred location',        
        );
        deliveryoption.push({key: LOGISTICS_TYPES.WALKIN, label: returnedText2})
      }
  
      setDeliveryTypeList(deliveryoption)
    }*/
            const incidentType = isInWarrantyOEM(serviceRequestData)
                ? INCIDENT_TYPES.MALFUNCTION_IW
                : isEWSClaim(serviceRequestData, incType)
                ? INCIDENT_TYPES.MALFUNCTION_EWS
                : incType;

            const deliveryOptions = getDeliveryOptions(
                incidentType,
                find_agreement?.ClientOffer?.ClientOfferName,
                features
            );
            let deliveryoption: deliveryOption[] = [];

            if (deliveryOptions?.SupportDelivery) {
                const returnedText1: string = await getDeliveryText();
                deliveryoption.push({ key: LOGISTICS_TYPES.DELIVERY, label: returnedText1 });
            }

            if (deliveryOptions?.SupportPUR) {
                const returnedText1: string = await getTextScript(
                    `${PageName}/DoorstepPUR`,
                    'Doorstep pick-up and return'
                );
                deliveryoption.push({ key: LOGISTICS_TYPES.PUR, label: returnedText1 });
            }

            if (deliveryOptions?.SupportWalkIn && !isBackOrder) {
                const returnedText1: string = await getTextScript(
                    `${PageName}/${isRepairRefresh ? 'walkinToLocation' : 'walkin'}`, // for repair-refresh walk-in text is different
                    `${isRepairRefresh ? 'Walk-in to your preferred location' : 'Walk-in'}`
                );

                deliveryoption.push({ key: LOGISTICS_TYPES.WALKIN, label: returnedText1 });
            }

            setDeliveryTypeList(deliveryoption);
        }
    };

    const handleDeliveryType = (dType: any) => {
        if (
            dType?.toUpperCase() === 'DOORSTEP PICK-UP AND RETURN' ||
            dType?.toUpperCase() === LOGISTICS_TYPES.PUR
        ) {
            setDeliveryType({
                label: deliveryOptionsScripts.PickupAndReturn,
                key: LOGISTICS_TYPES.PUR,
            });
        } else if (
            dType?.toUpperCase() === 'WALK-IN TO YOUR PREFERRED LOCATION' ||
            dType?.toUpperCase() === LOGISTICS_TYPES.WALKIN
        ) {
            setDeliveryType({ label: deliveryOptionsScripts.WalkIn, key: LOGISTICS_TYPES.WALKIN });
        } else {
            setDeliveryType({ label: dType, key: dType });
        }
    };

    useEffect(() => {
        if (isTOM) {
            callGetSecurityToken();
        }
        if (!globalState?.state?.processIncData || globalState?.state.processIncData == '') {
            globalState?.dispatch({
                type: ActionTypes.SET_PROCESS_INC_DATA,
                payload: serviceRequestData,
            });
        }
        if (globalState?.state?.sessionResponse?.InitializeResponse?.CurrencyCode !== 'SGD') {
        }
        // if ((isTOM || globalState?.state?.isMaxis) && !globalState?.state?.citiesData) {
        //       // setLoading(true)
        //       getCities(interactionData?.InteractionLine?.InteractionLineId)
        //         .then((data) => {
        //           globalState?.dispatch({
        //             type: ActionTypes.SET_CITIES_DATA,
        //             payload: data?.GetCitiesResponse,
        //           });
        //           // setLoading(false);
        //         })
        //         .catch((error) => {
        //           // setLoading(false);
        //           saveError(error, globalState);
        //           showErrorPage(navigate);
        //         });
        //     }
    }, []);

    const isRepairRefresh =
        isDeviceRefresh(incType) ||
        isDeviceRepair(incType) ||
        isBatteryReplacement(incType) ||
        isScreenRepair(incType) ||
        isScreenRepairBatReplacement(incType) ||
        isScreenRepairWarranty(incType) ||
        (isMalfunction(incType) && isInWarrantyOEM(serviceRequestData)) ||
        isEWSClaim(serviceRequestData, incType);

    const getDeliveryTypes = (deliveryOpt: Array<deliveryOption>) => {
        if (isRepairRefresh && R_FulfillmentOption) {
            return deliveryOpt.filter((val) => val.key === R_FulfillmentOption);
        } else {
            return deliveryOpt;
        }
    };

    const callshippingmethodapi = () => {
        const isShippingMethodV1 = callShippingMethodv1(
            incType,
            find_agreement?.ClientOffer?.ClientOfferName
        );
        // if((isTOM /*|| globalState?.state?.isMaxis*/)){ // for maxis we don't need API call here
        if (isShippingMethodV1) {
            setLoading(true);
            shippingmethodsv1Api(
                {
                    LookupShippingMethodsParameters: {
                        ServiceRequestId:
                            processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
                        ReceiverAddress: {
                            Address1: find_agreement?.Address?.Address1,
                            Address2: find_agreement?.Address?.Address2,
                            Address3: find_agreement?.Address?.Address3,
                            City: find_agreement?.Address?.City,
                            StateProvinceCode: find_agreement?.Address?.StateProvinceCode,
                            CountryCode: find_agreement?.Address?.CountryCode,
                            PostalCode: find_agreement?.Address?.PostalCode,
                            LocationType: 'Residential',
                        },
                        CustomerType: 'RETAIL',
                        Warehouse: 'BKK',
                        Simulation: true,
                        getDeliverySlots: getDeliverySlot(
                            find_agreement?.ClientOffer?.ClientOfferName
                        )
                            ? true
                            : false,
                        AlternateDays: 7,
                    },
                    ServiceOrderId: ServiceOrderId,
                    InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
                },
                ServiceOrderId,
                interactionData?.InteractionLine?.InteractionLineId,
                globalState?.state?.findAgreementSessionData
            )
                .then((data) => {
                    setLoading(false);
                    const ShippingMethodResults = data?.ShippingMethod;
                    setShippingMethod(ShippingMethodResults);
                    setShippingMethodResponse(ShippingMethodResults);
                    globalState?.dispatch({
                        type: ActionTypes.SET_SHIPPING_METHOD_RESPONSE,
                        payload: ShippingMethodResults,
                    });
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('error', error);
                    saveError(error, globalState);
                    showErrorPage(navigate);
                });
        } else {
            shippingmethodsv3Api(
                {
                    LookupShippingMethodsParameters: {
                        ServiceRequestId:
                            processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
                        getDeliverySlots: true,
                        AlternateDays: 7,
                        ServiceOrderId: ServiceOrderId,
                        InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
                    },
                },
                interactionData?.InteractionLine?.InteractionLineId,
                globalState?.state?.findAgreementSessionData
            )
                .then((data) => {
                    setLoading(false);
                    if (data) {
                        if (IsWarranty) {
                            let NightDeliverySlot = data?.ShippingMethod?.map((a: any) => {
                                return a.DeliverySlots.pop('7pm - 10pm:19-22');
                            });
                            console.log(NightDeliverySlot);
                            let ShippingMethodResults = data?.ShippingMethod;
                            setShippingMethod(ShippingMethodResults);
                            setShippingMethodResponse(ShippingMethodResults);
                        } else {
                            const ShippingMethodResults = data?.ShippingMethod;
                            setShippingMethod(ShippingMethodResults);
                            setShippingMethodResponse(ShippingMethodResults);
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('error', error);
                    saveError(error, globalState);
                    showErrorPage(navigate);
                });
        }
    };

    const callshippingmethodV2Api = () => {
        setLoading(true);
        shippingmethodsv2Api(
            {
                LookupShippingMethodsParameters: {
                    ServiceRequestId:
                        processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
                    ReceiverAddress: {
                        Address1: find_agreement?.Address?.Address1,
                        Address2: find_agreement?.Address?.Address2,
                        Address3: find_agreement?.Address?.Address3,
                        City: find_agreement?.Address?.City,
                        StateProvinceCode: find_agreement?.Address?.StateProvinceCode,
                        CountryCode: find_agreement?.Address?.CountryCode,
                        PostalCode: find_agreement?.Address?.PostalCode,
                        LocationType:
                            isTOM || globalState?.state?.isMaxis ? 'Residential' : undefined,
                        Simulation: isTOM || globalState?.state?.isMaxis ? true : undefined,
                    },
                    Mode: isDeviceRepair(incType)
                        ? deliveryType?.key === LOGISTICS_TYPES.PUR
                            ? 'pickup'
                            : 'delivery'
                        : 'pickup',
                    getDeliverySlots: true,
                },
            },
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((data) => {
                setLoading(false);
                if (data) {
                    const pickUpShippingMethodResults = data?.ShippingMethodResults;
                    const slots = getPickupSlot();
                    const shipingMethods = pickUpShippingMethodResults.map(
                        (item: any, index: number) => {
                            const claimWithinCutoff = isClaimWithinCutoff(initializeData?.TimeZone);
                            const estimatedDeliveryDate = item?.pickUpDate;

                            let deliverySlots;
                            if (index === 0) {
                                // For first date,if claim filed before 2 PM, then `slots`, else `slots[1]`
                                deliverySlots =
                                    (isTOM || globalState?.state?.isMaxis) && claimWithinCutoff
                                        ? slots
                                        : [slots[1]];
                            } else {
                                deliverySlots = slots;
                            }

                            return {
                                EstimatedDeliveryDate: estimatedDeliveryDate,
                                DeliverySlots: item?.pickUpSlot || deliverySlots,
                            };
                        }
                    );
                    setShippingMethod(shipingMethods);
                    globalState?.dispatch({
                        type: ActionTypes.SET_SHIPPING_METHOD_V2,
                        payload: shipingMethods,
                    });
                    globalState?.dispatch({
                        type: ActionTypes.SET_RAW_SHPPINGMETHOD_RES,
                        payload: pickUpShippingMethodResults,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    const onCancelClick = () => {
        setOpenCancelPopup(!openCancelPopup);
    };

    const updateDataTransferAddOn = () => {
        // update data transfer add on flag If delivery type is not walk-in for swap and malfunction
        if (
            deliveryType?.key &&
            !(isTOM || globalState?.state?.isMaxis) &&
            !isWalkIn(deliveryType?.key) &&
            (isSwap(incType) || isMalfunction(incType))
        ) {
            globalState?.dispatch({
                type: ActionTypes.SET_DOOR_STEP_DATA_TRANSFER_ADD_ON,
                payload: { hasAddOnAcquired: false, fee: '0' },
            });

            if (ResumeFlowData) {
                ResumeFlowData.IsDataTransfer = false;
                setResumeFlowData(ResumeFlowData);
            }
        }
    };

    const checkProcessIncident = () => {
        const data: any = sessionStorage.getItem(processIncidentKey) || ResumeFlowData;
        setProcessIncidentResponse(data);
        if (acceptClick && processIncidentResponse) {
            setAcceptClick(false);
            setLoading(false);
            {
                deliveryType?.key !== LOGISTICS_TYPES.WALKIN &&
                    !(
                        globalState?.state?.isMaxis ||
                        isMalfuntionWarrantyEligible(agreementData?.ClientOffer?.ClientOfferName)
                    ) &&
                    callshippingmethodV2Api();
            }
            navigate('/logisticsrefresh', { replace: true });
        }
    };

    useEffect(() => {
        checkProcessIncident(); // check ProceessIncident response and call shippingMethodV2
    }, [() => sessionStorage.getItem(processIncidentKey)]);

    useQuery(
        ['getClientTokenResponse'],
        async () => {
            // showLoader(true)
            return await getClientToken(
                maid,
                interactionData?.InteractionLine.InteractionLineId,
                agreementData?.AgreementId,
                globalState?.state?.findAgreementSessionData
            );
        },
        {
            enabled: hasBrainTreePaymentGateway(find_agreement?.ClientOffer?.ClientOfferName),
            onSuccess: (data: any) => {
                // setBTClientTokenResp(data.clientToken)
                globalState?.dispatch({
                    type: ActionTypes.SET_BT_CLIENT_TOKEN,
                    payload: data.clientToken,
                });
                // showLoader(false)
            },
            onError: (error) => {
                saveError(error, globalState);
                showErrorPage(navigate);
            },
        }
    );

    function generateUniqueId() {
        return uuid();
    }

    const callGetSecurityToken = () => {
        let GetSecurityTokenRequest = {
            UseRecurringBillingProgram: true,
            version: '4.5',
            Culture: 'th-TH',
            CurrencyCode: 'THB',
            FirstName: account?.firstname,
            LastName: account?.lastname,
            Phone: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
            Email: account?.email,
            InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
            AgreementRequestId: undefined,
            AgreementId: agreementData?.AgreementId,
            BillingProfileId: generateUniqueId().replace(/-/g, '')?.toUpperCase(),
            FeeType: 'SWAPFEE',
        };
        getSecurityTokenApi(
            { GetSecurityTokenRequest },
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((data) => {
                setSecurityTokenData(data?.GetSecurityTokenResponse);
            })
            .catch((error) => {
                saveError(error, globalState);
            });
    };

    const handleOnClick = () => {
        if (deliveryType) {
            updateDataTransferAddOn();
            setSRDeliveryType(deliveryType.key);
            if (
                (isSwap(incType) ||
                    isReplacement(incType) ||
                    (isMalfunction(incType) &&
                        !isInWarrantyOEM(serviceRequestData) &&
                        !isEWSClaim(serviceRequestData, incType))) && //In warranty flow swap/replacement becomes malfunction
                deliveryType?.key == 'Delivery'
            ) {
                if (
                    !globalState?.state?.isMaxis ||
                    isMalfuntionWarrantyEligible(agreementData?.ClientOffer?.ClientOfferName)
                )
                    // for maxis we don't need API call here
                    callshippingmethodapi();
                navigate('/logisticsoptions', { replace: true });
            } else if (
                isRepairRefresh ||
                isMalfuntionWarrantyEligible(agreementData?.ClientOffer?.ClientOfferName)
            ) {
                setLoading(true);
                checkProcessIncident(); // check ProceessIncident response and call shippingMethodV2
            } else {
                navigate('/logisticsoptions', { replace: true });
            }
        }
    };

    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { primary } = colors;

    return (
        <Box w="full" m="0 auto">
            <Box mt={16} w={{ base: 'full', lg: '80%' }} mx="auto">
                <SubHeader
                    backText={''}
                    allowBackButtonClick={true}
                    showCancelButton={true}
                    showBackButton={false}
                    cancelText={DeliveryOptionsScripts.CancelSR.cancelBtnText}
                    onCancelClick={onCancelClick}
                ></SubHeader>
                <Box p={4} w={{ base: 'full', lg: '60%' }} mx="auto">
                    <DeliveryOptions
                        question={deliveryOptionsScripts?.HeaderText}
                        deliveryTypes={getDeliveryTypes(deliveryTypeList)}
                        onNextClick={(e) => {
                            handleDeliveryType(e);
                            // navigate('/logisticsoptions', { replace: true });
                        }}
                    />
                    <Box mt={6} w={{ base: 'full', lg: '45%' }} marginX="auto">
                        <Button
                            py={3}
                            px={5}
                            id={'walkingStoreDetails'}
                            bgColor={primary}
                            color="white"
                            w="full"
                            fontSize={'md'}
                            isDisabled={!enableButton}
                            onClick={() => {
                                setAcceptClick(true);
                                handleOnClick();
                            }}
                        >
                            <TextScript
                                text={DeliveryOptionsScripts.Global.Continue}
                                translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Continue}`}
                            />
                        </Button>
                    </Box>
                </Box>
                <CancelSrPopup
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
        </Box>
    );
};

export default DeliveryOptionsContainer;
