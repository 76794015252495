import { NPS, useSessionStorage } from '@MGPD/myasurion-shared';
import { Box, Center } from '@chakra-ui/react';
import { Buffer } from 'buffer';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import MainStackLayout from '../../layout/MainStackLayout';
import {
    ResumeFlowDataKey,
    assetDataKey,
    cloudCheryyTokenKey,
    currentAgreementKey,
    processIncidentKey,
} from '../../modules/config/constants';
import { getLoginTokenApi } from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { SurveyConfig } from '../../types/types';
import { hasCloudcherrySupport } from '../../utils/featuresUtills';
import {
    getCustomerNameFromAgreement,
    getMDNFromAgreement,
    saveError,
    showErrorPage,
} from '../../utils/utills';
import { NPS as NPSStarhub } from '../nps/NPS';

function NpsRating() {
    const navigate = useNavigate();
    const globalState = useCentralStore();
    const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);
    const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
    const [, setCloudCherryToken] = useSessionStorage<any>(cloudCheryyTokenKey, null);
    const [find_agreement] = useSessionStorage<any>(currentAgreementKey, null);
    const [assetData] = useSessionStorage<any>(assetDataKey, null);
    const R_ServiceRequests = ResumeFlowData?.ServiceRequests?.[0];
    const cleaningIncidentData =
        globalState?.state?.cleaningIncidentData?.UpdateCleaningServiceRequest;
    const serviceRequestId =
        cleaningIncidentData?.ServiceRequest?.ServiceRequestId ||
        serviceRequestData?.ServiceRequest?.ServiceRequestId ||
        R_ServiceRequests?.ServiceRequestId;
    const customerCaseNumber =
        cleaningIncidentData?.CustomerCase?.CustomerCaseNumber?.toString() ||
        serviceRequestData?.CustomerCaseNumber?.toString() ||
        R_ServiceRequests?.CustomerCaseNumber?.toString();
    const IncidentType =
        serviceRequestData?.ServiceRequest?.IncidentType || R_ServiceRequests?.IncidentType;

    const agreementData: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

  const config: SurveyConfig = {
    baseUrl: import.meta.env.BASE_URL,
    headers: {
      'x-api-key': import.meta.env.VITE_X_API_KEY,
    },
    actions: {
      getSurvey: {
        method: 'POST',
        path: 'claim/api/survey',
      },
      submitSurvey: {
        method: 'POST',
        path: 'claim/api/survey/submit',
      },
    },
  };

    useEffect(() => {
        if (
            hasCloudcherrySupport(
                find_agreement?.ClientOffer?.ClientOfferName ||
                    agreementData?.ClientOffer?.ClientOfferName
            )
        ) {
            getLoginToken();
        }
    }, [agreementData]);

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const getLoginToken = () => {
        setLoading(true);
        getLoginTokenApi()
            .then((token) => {
                setLoading(false);
                setCloudCherryToken(token);
            })
            .catch((error) => {
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    const handleQualtricsNavigation = () => {
        const payload = getQualtricsSurveyPayload();
        window.open(`/survey?payload=${payload}`, '_blank');
    };

    const getQualtricsSurveyPayload = () => {
        let qualtricsSurveyPayload = {
            LOCATION: 'SmartSupport-Online',
            ORDER_REFERENCE_NUMBER: `${customerCaseNumber}`,
            TRANSACTION_TYPE: `${IncidentType}`,
            MOBILE_NUMBER: `${getMDNFromAgreement(find_agreement)}`,
            CUSTOMER_NAME: `${getCustomerNameFromAgreement(find_agreement)}`,
            PRICE_PLAN: `${find_agreement?.ClientOffer?.ClientOfferName}`,
            DIVISION: `${find_agreement?.AgreementPurchase?.StoreCode}`,
            AGENT_ID: 'horizon.online',
            AGENT_NAME: `${find_agreement?.AgreementPurchase?.SalesPersonName}`,
            CPE_MAKE: `${assetData?.Make?.Name}`,
            CPE_MODEL: `${assetData?.Model?.Name}`,
            SERVICE_NUMBER: `${assetData?.IMEI}`,
            CONTRACT_END_DATE: 'N/A',
            CONTRACT_START_DATE: `${moment(find_agreement?.StartDate).format('MM/DD/YYYY hh:mm')}`,
            START_TIME: `${moment(
                globalState?.state?.sessionResponse?.Interaction?.UpdatedDate
            ).format('MM/DD/YYYY hh:mm')}`,
            ENDTIME: `${moment(new Date()).format('MM/DD/YYYY hh:mm')}`,
            TRANSACTION_DATE: `${moment(find_agreement?.AgreementPurchase?.PurchaseDate).format(
                'MM/DD/YYYY hh:mm'
            )}`,
            SOURCE: 'Online',
            CONN_ID: `${uuid()}`,
            TICKET_NUMBER: `${globalState?.state?.sessionResponse?.Interaction?.InteractionLine?.InteractionLineId}`,
            SERVICE_ID: `${serviceRequestId}`,
        };
        const stringifyObj = JSON.stringify(qualtricsSurveyPayload);

        return Buffer.from(stringifyObj).toString('base64');
    };

  return (
    <Center>
      <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'} w="full">
        <MainStackLayout gap={0} spacing={0}>
          <Box
            w={{ base: 'full', lg: '80%' }}
            marginX={{ base: '', lg: 'auto' }}
            mb={{ base: '0', lg: '20px' }}
            h="full"
          >
            {hasCloudcherrySupport(find_agreement?.ClientOffer?.ClientOfferName) ? (
              <NPSStarhub
              onSubmit={async () => {
                // handleCloudCherryNavigation();
                await handleQualtricsNavigation();
                navigate('/thanksmessage', { replace: true });
              }}
                clientId={globalState?.state?.sessionResponse?.InitializeResponse?.ClientId}
                config={config}
                onError={() => null}
                surveyType="NPS"
                serviceRequestId={serviceRequestId}
                customerCaseNumber={customerCaseNumber}
                defaultScore={7}
              ></NPSStarhub>
            ) : (
              <NPS
                onSubmit={() => {
                  navigate('/thanksmessage', { replace: true });
                }}
                clientId={globalState?.state?.sessionResponse?.InitializeResponse?.ClientId}
                config={config}
                onError={() => {}}
                surveyType="NPS"
                serviceRequestId={serviceRequestId}
                customerCaseNumber={customerCaseNumber}
                useBrandStyle={true}
              ></NPS>
            )}
          </Box>
        </MainStackLayout>
      </Box>
    </Center>
  );
}

export default NpsRating;
