import { useConfig } from '@MGPD/myasurion-shared';
import {
    Box,
    BoxProps,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Image,
    SimpleGrid,
    Text,
} from '@chakra-ui/react';
import { PropsWithChildren, useState } from 'react';

export interface CardContent {
    img: string;
    familyName: string;
    srf: string;
}

export interface CardsProps {
    cardContent: Array<CardContent>;
    onClickCallback?: (args: CardContent) => void;
    actualModel?: {
        img: string;
        familyName: string;
        srf: string;
    };
}

export const Cards: React.FC<PropsWithChildren<BoxProps & CardsProps>> = ({
    onClickCallback,
    children,
    cardContent,
    actualModel,
    ...props
}) => {
    const [visibleCards, setVisibleCards] = useState(6);

    const handleLoadMore = () => {
        setVisibleCards((prevVisibleCards) => prevVisibleCards + 6);
    };
    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { primary, accent } = colors;

    return (
        <Box {...props}>
            <SimpleGrid
                spacing={2}
                marginBottom={'16px'}
                templateColumns={['repeat(2, 1fr)', 'repeat(auto-fill, minmax(10rem, 1fr))']}
            >
                {cardContent?.map((val, index) => {
                    if (index < visibleCards) {
                        return (
                            <center key={index}>
                                <Card
                                    size={'sm'}
                                    height={'100%'}
                                    borderWidth={
                                        val?.familyName === actualModel?.familyName ? '1px' : '1px'
                                    }
                                    borderColor={
                                        val?.familyName === actualModel?.familyName
                                            ? 'black'
                                            : '#A5AAAF'
                                    }
                                    borderRadius={'8px'}
                                    onClick={() => {
                                        onClickCallback && onClickCallback(val);
                                    }}
                                    style={{
                                        position: 'relative',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {(val?.familyName && val?.familyName === actualModel?.familyName) && (
                                        <Box
                                            position="absolute"
                                            top={'-12px'}
                                            left={'50%'}
                                            transform={'translateX(-50%)'}
                                            right={0}
                                            bg="white"
                                            fontWeight="bold"
                                            borderColor={'black'}
                                            borderWidth={'1px'}
                                            zIndex={2}
                                            paddingX={'8px'}
                                            paddingY={'2px'}
                                            // width="100px"
                                            fontSize={'12px'}
                                            textAlign="center"
                                            borderRadius={'4px'}
                                        >
                                            {'Your current model'}
                                        </Box>
                                    )}
                                    <CardHeader paddingTop={'15px'}>
                                        <Image src={val.img} alt="loading" />
                                    </CardHeader>
                                    <CardBody paddingBottom={'4px'}>
                                        <Text fontWeight={'bold'} fontSize={'15px'}>
                                            {val?.familyName}
                                        </Text>
                                    </CardBody>
                                    <CardFooter
                                        display={'flex'}
                                        justifyContent={'center'}
                                        marginTop={'-10px'}
                                        fontSize={'15px'}
                                    >
                                        {val.srf}
                                    </CardFooter>
                                </Card>
                            </center>
                        );
                    }
                    return null;
                })}
            </SimpleGrid>
            {visibleCards < cardContent?.length && (
                <center>
                    <Button
                        mt="4"
                        onClick={handleLoadMore}
                        display={'flex'}
                        justifyContent={'center'}
                        color={primary}
                        variant="outline"
                        paddingX={'12px'}
                        marginBottom={'30px'}
                    >
                        <Text color={accent}>Load more options</Text>
                    </Button>
                </center>
            )}
        </Box>
    );
};

export default Cards;
