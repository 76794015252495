/* eslint-disable no-console */
import {
    FulfillmentOptionKey,
    LFLStockKey,
    ResumeFlowDataKey,
    assetDataKey,
    createServiceOrderDataKey,
    deliveryAddressKey,
    deliveryDateKey,
    deliveryTimeCodeKey,
    deliveryTimeKey,
    deliveryTypeKey,
    interactionLineKey,
    processIncidentKey,  
    proxyUserDataKey,
    returnAddressKey,
    shippingMethodResponseKey,
    storeAddressKey,
    storeDateKey,
    storeTimeKey,
  } from '../modules/config/constants';
  import { useCentralStore } from '../store/appContext';
  // getPickUpDeliveryWindow, 
  import { isBatteryReplacement, isMalfunction, isScreenRepair, isScreenRepairBatReplacement, isScreenRepairWarranty, isSwap, saveError, showErrorPage, isDelivery as isDeliveryType, isPUR, isWalkIn, isReplacement, isInWarrantyOEM, isDeviceRefresh, isDeviceRepair, getDateAndDay, isEWSClaim, getGTMData, isUpgrade } from '../utils/utills';
  import { SubHeader, useSessionStorage, TextScript, getTextScript, useConfig, getCarrierByDomain, getTypeByDomain } from '@MGPD/myasurion-shared';
  import { Box, Button, Flex, FormErrorMessage, Stack, Text } from '@chakra-ui/react';
  import { FormControl } from '@chakra-ui/react';
  import { useEffect, useState } from 'react';
  import { useNavigate } from 'react-router-dom';
  
  import RepresentativeformScripts from '../Scripts/asurion-script.json';
  // import { v4 as uuid } from 'uuid';
  import { createServiceOrder, getUpdateShippingDetails, logGTMEvent, setFulfillmentMethodApi } from '../services/api';
  import ActionTypes from '../store/actionTypes';
  import TextInput from './component/TextInput';
  import CancelSrPopup from './myclaims/CancelSrPopup';
  import translationsKeys from '../Scripts/translationsKeys.json'
  import { getFulfillmentMethodType, callShippingMethodv1, pickUpDeliveryWindow, returnDeliveryWindow, getAlternateMobileLength, hasAddRepresentativeFeature, getDeliverySlot, isJBUpgradeFlow, isMalfuntionWarrantyEligible } from '../utils/featuresUtills';
  import { FULLFILLMENT_METHOD_TYPE, GTM_EVENT, INCIDENT_TYPES } from '../utils/constant';
  import { camelize } from '../utils/helper';
  
  const RepresentativeForm = () => {
    const navigate = useNavigate();
    const globalState = useCentralStore();
    const isTOM = globalState?.state?.sessionResponse?.InitializeResponse?.ClientName === "Asurion_TechCare"
    const RepsentativeTranslations = translationsKeys.RepsentativeForm;
    const GlobalTranslations = translationsKeys.Global;
  
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [fulfillmentOption, setFulfillmentOption] = useSessionStorage<string>(FulfillmentOptionKey, '')
    const representativeformScripts = RepresentativeformScripts?.Representativeform;
    const [shippingMethodResponse] = useSessionStorage<any>(shippingMethodResponseKey, null);
    const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
    const [LFLStock] = useSessionStorage<any>(LFLStockKey, null);
    const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);
  
    const processIncData = globalState?.state?.processIncData || serviceRequestData;
    const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
    const R_ServiceRequestId = R_ServiceRequests?.ServiceRequestId;
    const R_CustomerCaseId = R_ServiceRequests?.CustomerCaseId;
    const R_IncidentType = R_ServiceRequests?.IncidentType;
    const R_ServiceOrderId = R_ServiceRequests?.ServiceOrder?.ServiceOrderId;
    const R_FulfillmentOptionExist = 
      (ResumeFlowData?.FulfillmentOptionExist === true && R_ServiceRequests?.FulfillmentOption)
      || fulfillmentOption;
  
    const agreementData1: any = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const isUpgradeFlow = isJBUpgradeFlow(agreementData1?.ClientOffer?.ClientOfferName)
  
    const incType = camelize(processIncData?.ServiceRequest?.IncidentType || R_IncidentType);
    const isRepairRefresh =
      isDeviceRefresh(incType) ||
      isDeviceRepair(incType) ||
      isBatteryReplacement(incType)||
      isScreenRepair(incType) ||
      isScreenRepairBatReplacement(incType)||
      isScreenRepairWarranty(incType) ||
      (isMalfunction(incType) && isInWarrantyOEM(processIncData)) ||
      isEWSClaim(processIncData, incType) ||
      (isUpgrade(incType) && isUpgradeFlow) ||
      (isMalfunction(incType) &&
            isMalfuntionWarrantyEligible(agreementData1?.ClientOffer?.ClientOfferName));
  
    const setLoading = (val: boolean) => {
      globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };
    const [, setUpdateShippingOrderResponse] = useSessionStorage<any>(
      'updateShippingOrderResponse',
      null
    );
    const [createServiceOrderRequest] = useSessionStorage<any>(createServiceOrderDataKey, null);
    const [interactionLineId] = useSessionStorage<any>(interactionLineKey, null);
  
    const [selectedStoreDetails] = useSessionStorage<any>(storeAddressKey, null);
  
    const find_agreement = globalState?.state?.currentAgreementData;
    const alternateContactNumber = find_agreement?.ContactPoints?.ContactPoint?.filter(
      (val: any) => val?.ContactPointType === 'MOBILE'
    )?.[0]?.PhoneNumber
    const pickupDate = globalState?.state?.pickupDate;
    const returnDate = globalState?.state?.returnDate;
    const pickupTime = globalState?.state?.pickupTime;
    const returnTime = globalState?.state?.returnTime;
    const walkingAddress = selectedStoreDetails && selectedStoreDetails;
    const ServiceRequest = processIncData && processIncData?.ServiceRequest;
    const ServiceOrderId =
      globalState?.state?.serviceOrderData?.CreateServiceOrderResults?.ServiceOrderId ||
      createServiceOrderRequest?.CreateServiceOrderResults?.ServiceOrderId ||
      R_ServiceOrderId;
  
    const [clickYes, setClickYes] = useState(false);
    const [invalidName, setInvalidName] = useState(false);
    const [invalidMobile, setInvalidMobile] = useState(false);
    const [invalidFin, setInvalidFin] = useState(false);
  
    const [namePlaceholder, setNamePlaceholder] = useState('');
    const [mobilePlaceholder, setMobilePlaceholder] = useState('');
    const [nricPlaceholder, setNRICPlaceholder] = useState('');
  
    const [updateShippingOrderRes, setUpdateShippingOrderRes] = useState<any>();
  
    const [confirmDetails, setConfirmDetails] = useState(false);
    const [, setProxyUserData] = useSessionStorage<any>(proxyUserDataKey, null);
  
    const [form, setForm] = useState({ name: '', mobile: '', FIN: '' });
  
    const [srDeliveryType] = useSessionStorage<any>(deliveryTypeKey, null);
    const [deliveryAddress] = useSessionStorage<any>(deliveryAddressKey, null);
    const [deliveryDate] = useSessionStorage<any>(deliveryDateKey, null);
    const [DeliveryTimeSlotCode] = useSessionStorage<any>(deliveryTimeCodeKey, null);
    const [deliveryTime] = useSessionStorage<any>(deliveryTimeKey, null);
  
    const [walkingDate] = useSessionStorage<any>(storeDateKey, null);
    const [walkingTime] = useSessionStorage<any>(storeTimeKey, null);
    const [assetData] = useSessionStorage<any>(assetDataKey, null);
  
    const isDelivery = isDeliveryType(srDeliveryType);
    const isWalking = isWalkIn(srDeliveryType);
    const isPickup = isPUR(srDeliveryType);
    const tomDeliveryWindow = DeliveryTimeSlotCode == '19' ? '17H' : '6H';
  
    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { primary, bodyTextColor } = colors;
    let validLength = getAlternateMobileLength(find_agreement?.ClientOffer?.ClientOfferName);
    const hasDTAddOnAcquired =
      globalState?.state?.doorStepAddOnData?.hasAddOnAcquired || ResumeFlowData?.IsDataTransfer;
  
    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const carrierNumber = globalState?.state?.sessionResponse?.InitializeResponse?.DeliveryCarrier;
  
  
    const ServiceRequestId =
      processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId;
  
    const [returnAddress] = useSessionStorage<any>(returnAddressKey, null);
  
    useEffect(() => {
      getNamePlaceholder();
      getMobilePlaceholder();
      getNRICPlaceholder();
  
      if(!hasAddRepresentativeFeature(agreementData1?.ClientOffer?.ClientOfferName)){
        navigate('/reviewsubmission', { replace: true });
        handleNoclick();
      }
  
      let GTMData = getGTMData(GTM_EVENT.REPRESENTATIVE_SCREEN_EVENT, globalState?.state?.currentAgreementData,
        getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)
        logGTMEvent(GTMData);
  
    }, []);
  
    const fetchFulfillmentMethodType = () => {
      // if(isBatteryReplacement(incType))
      //   return 'BTRREP';
      // else if(isScreenRepair(incType) || isScreenRepairWarranty(incType))
      //   return 'SCRNREP';
      // else if (isMalfunction(incType) && isInWarrantyOEM(serviceRequestData))
      //   return 'ADVEXCH';
      // else
      //   return 'REPAIR';
      const incidentType = isInWarrantyOEM(serviceRequestData) ? INCIDENT_TYPES.MALFUNCTION_IW : isEWSClaim(serviceRequestData, incType) ? INCIDENT_TYPES.MALFUNCTION_EWS : incType;
      return getFulfillmentMethodType(incidentType, agreementData1?.ClientOffer?.ClientOfferName) || FULLFILLMENT_METHOD_TYPE.REPAIR;
    }
  
    const isAdvanceExch = () => {
      return fetchFulfillmentMethodType() === FULLFILLMENT_METHOD_TYPE.ADVEXCH;
    }
  
    const getServiceOrderParams = () => {
      return {
        InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
        ApprovedServiceFeeChange: false,
        ServiceOrder: {
          FulfillmentMethodType: fetchFulfillmentMethodType(),
          ServiceRequestId:
            processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
          CustomerCaseId: processIncData.CustomerCaseId || R_CustomerCaseId,
          ServiceOrderLines: {
            ServiceOrderLine: [
              {
                ServiceOrderLineType: assetData?.AssetCategory?.AssetCategoryName || 'PHN',
                Quantity: 1,
                AssetCatalogId:
                  assetData?.AssetCatalog?.AssetCatalogId ||
                  R_ServiceRequests?.EnrolledDevice?.AssetCatalog?.AssetCatalogId,
                VendorItemId: assetData?.ItemId || 'S830-0420-IPH125G128BLK',
                Priority: 'MNDTRY',
                VenderItemType: assetData?.AssetCategory?.AssetCategoryName || 'PHN',
                VenderItemDescription: assetData?.AssetCategory?.AssetCategoryName || 'PHONE',
                VendorData: {
                  IsLikeForLike: true,
                  IsSimCard: false,
                  IsInKit: false,
                  IsInStock: true,
                },
              },
            ],
          },
        },
      };
    }
  
    const getNamePlaceholder = async() => {
      const returnedText = await getTextScript(
        `${RepsentativeTranslations.PageName}/PlaceholderName`,
        'Name'
      );
  
      setNamePlaceholder(returnedText);
    }
  
    const getMobilePlaceholder = async() => {
      const returnedText = await getTextScript(
        `${RepsentativeTranslations.PageName}/PlaceholderMobile`,
        'Mobile Number'
      );
  
      setMobilePlaceholder(returnedText);
    }
  
    const getNRICPlaceholder = async() => {
      const returnedText = await getTextScript(
        `${RepsentativeTranslations.PageName}/PlaceholderNRIC`,
        'NRIC/FIN Number (Last 4 char)'
      );
  
      setNRICPlaceholder(returnedText);
    }
  
    const callSetFulfillmentApi = async () => {
      setLoading(true);
      // navigate('/reviewsubmission');
  
      const InteractionLineId = interactionData?.InteractionLine?.InteractionLineId;
      const FulfillmentOption = (isUpgrade(incType) && isUpgradeFlow) ? 'RPLCMNT' : isPickup ? 'PUR' : 'WALKIN';
  
        setFulfillmentMethodApi(InteractionLineId, FulfillmentOption, ServiceRequestId, globalState?.state?.findAgreementSessionData)
        .then((response) => {
          globalState?.dispatch({
            type: ActionTypes.SET_FULFILLMENT_DATA,
            payload: response?.FulfillmentResponse,
          });
          // createServiceOrderRequestApi()
          // setLoading(false);
          setFulfillmentOption(FulfillmentOption)
          const GTMData = getGTMData(GTM_EVENT.SET_FULFILLMENT_EVENT, globalState?.state?.currentAgreementData, 
            getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)
            logGTMEvent(GTMData)
          const CreateServiceOrderParameters = getServiceOrderParams();
        
          createServiceOrder(CreateServiceOrderParameters, interactionLineId, globalState?.state?.findAgreementSessionData)
            .then((response) => {
              let GTMData = getGTMData(GTM_EVENT.SERVICE_ORDER_EVENT, agreementData1, getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)                
              logGTMEvent(GTMData);
              if(hasAddRepresentativeFeature(agreementData1?.ClientOffer?.ClientOfferName))
                navigate('/reviewsubmission');
              
                globalState?.dispatch({
                type: ActionTypes.SET_SERVICE_ORDER_DATA,
                payload: response,
              });
              const userAddress = find_agreement?.Address;
              const returnAdd = isWalking ? walkingAddress : returnAddress ? returnAddress : userAddress;
              const pickUpAdd = isWalking ? walkingAddress : deliveryAddress ? deliveryAddress : userAddress;
             
              const UpdateShippingOrderParameters: any = {
                UpdateShippingOrderParameters: {
                  EntityUpdateOptions: {
                    ReturnEntity: true,
                  },
                  IsDataTransfer: hasDTAddOnAcquired,
                  InteractionLineId: interactionLineId,
                  ServiceRequestId: ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
                  ShippingOrder: {
                    ShippingMethodType: 'Same Day',
                    CarrierNumber: carrierNumber,
                    SignatureRequired: true,
                    RequestedShipmentDate: (isUpgrade(incType) && isUpgradeFlow) ? undefined : isPickup
                      ? pickupDate
                      : isDelivery
                      ? deliveryDate
                      : walkingDate, // for repair we are passing exiting data from get claim api
                    ExpectedDeliveryDate: (isUpgrade(incType) && isUpgradeFlow) ? undefined : isPickup
                      ? pickupDate
                      : isDelivery
                      ? deliveryDate
                      : walkingDate, // for repair we are passing exiting data from get claim api 
                    // DeliveryWindow: (isPickup && globalState?.state?.isMaxis) 
                    // ? getPickUpDeliveryWindow(pickupTime)
                    // : isPickup
                    //   ? pickupTime
                    //   : isDelivery
                    //   ? DeliveryTimeSlotCode
                    //   : walkingTime, // for repair we are passing exiting data from get claim api
                    DeliveryWindow: (isUpgrade(incType) && isUpgradeFlow) ? undefined : pickUpDeliveryWindow(pickupTime, find_agreement?.ClientOffer?.ClientOfferName) ? pickUpDeliveryWindow(pickupTime, find_agreement?.ClientOffer?.ClientOfferName) : isPickup ? pickupTime : isDelivery ? DeliveryTimeSlotCode : walkingTime,
                    NationalId: find_agreement?.IdentificationNumber.substr(
                      find_agreement?.IdentificationNumber.length - 4
                    ),
                    Remark: clickYes
                      ? `Repesentative Name:${form.name}; NRIC: ${form.FIN}; number: ${form.mobile}; fmip:`
                      : '',
                    AlternateContactNumber: globalState?.state?.alternateContactNumber || alternateContactNumber,
                    ReceiverName: find_agreement?.Customers?.Customer?.[0]?.FullName,
                    ReceiverAddress: {
                      LocationType: 'Residential',
                      BusinessName: find_agreement?.Customers?.Customer?.[0]?.FullName,
                      Standardized: true,
                      IsOverridden: false,
                      StandardizedSourceName: 'QAS',
                      BaseAddressHeaderId: 'c7730733f6bea936403e881fed322e26',
                      IsScrubbed: true,
                      Address1: pickUpAdd?.Address1,
                      ...pickUpAdd,
                    },
                  },
                  InboundShippingOrder: isAdvanceExch() ? undefined : {
                    //return
                    ShippingMethodType: 'Same Day',
                    CarrierNumber: carrierNumber,
                    SignatureRequired: true,
                    RequestedShipmentDate: returnDate,
                    ExpectedDeliveryDate: returnDate,
                    DeliveryWindow: getDeliveryWindow() || undefined,
                    NationalId: find_agreement?.IdentificationNumber,
                    Remark: '',
                    AlternateContactNumber: globalState?.state?.alternateContactNumber || alternateContactNumber,
                    ReceiverName: find_agreement?.Customers?.Customer?.[0]?.FullName,
                    ReceiverAddress: {
                      LocationType: 'Residential',
                      BusinessName: find_agreement?.Customers?.Customer?.[0]?.FullName,
                      Standardized: true,
                      IsOverridden: false,
                      StandardizedSourceName: 'QAS',
                      BaseAddressHeaderId: 'c7730733f6bea936403e881fed322e26',
                      IsScrubbed: true,
                      Address1: returnAdd?.Address1,
                      ...returnAdd,
                    },
                  },
                },
              };
              
                getUpdateShippingDetails(
                  UpdateShippingOrderParameters,
                  response?.CreateServiceOrderResults?.ServiceOrderId,
                  interactionData?.InteractionLine?.InteractionLineId,
                  globalState?.state?.findAgreementSessionData
                )
                .then((responseShipping) => {
                  setUpdateShippingOrderResponse(responseShipping);
                  setUpdateShippingOrderRes(responseShipping);
                  setLoading(false);
                  let GTMData = getGTMData(GTM_EVENT.UPDATE_SHIPPING_EVENT, agreementData1, getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)                
                  logGTMEvent(GTMData);
                  if (!clickYes) {
                    // navigate('/reviewsubmission');
                    globalState?.dispatch({ type: ActionTypes.ENABLE_REVIEW_BTN, payload: true });
                    globalState?.dispatch({ type: ActionTypes.SET_SHIPPING_ORDER_UPDATES_RESULT, payload: responseShipping });
                  }
                })
                .catch((error) => {
                  console.error(error);
                  setLoading(false);
                  saveError(error, globalState);
                  showErrorPage(navigate);
                });
            })
            .catch((error) => {
              console.error(error);
              setLoading(false);
              saveError(error, globalState);
              showErrorPage(navigate);
            });
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          saveError(error, globalState);
          showErrorPage(navigate);
        });
    };
  
    const getDeliveryWindow = () => {
      if (returnTime) {
        // if (returnTime === '12:00AM - 6:00PM' || returnTime === '2:00PM - 6:00PM') {
        //   return '18H';
        // } else if (returnTime === '12:00AM - 8:00PM') {
        //   return '20H';
        if(returnDeliveryWindow(returnTime, find_agreement?.ClientOffer?.ClientOfferName)){
          return returnDeliveryWindow(returnTime, find_agreement?.ClientOffer?.ClientOfferName)
        } else {
          return returnTime;
        }
      } else if (isDelivery) {
        return DeliveryTimeSlotCode;
      } else {
        return walkingTime;
      }
    };
  
    const getSelectedShippingMethod = () => {
      const selectedShippingMethodType = shippingMethodResponse?.filter((val: any) => {
        return val?.EstimatedDeliveryDate === deliveryDate;
      });
  
      return selectedShippingMethodType?.[0];
    }
  
    const getMaxisSwapDeliveryWindow = () => {
      // first fetch ShippingMethodType
      const selectedShippingMethodType = getSelectedShippingMethod();
  
      // check ShippingMethodType if same day then 3H else Next Day 18H
      if(selectedShippingMethodType?.ShippingMethodType === "Same Day")
        return '3H'
      else
        return '18H'
    }
  
    const getDPDeliveryWindowSS3 = () => {
      if(deliveryDate === getDateAndDay(new Date(), 'YYYY-MM-DD'))
        return '4H'
      else
        return deliveryTime === '8am - 12pm' ? '12H' : '18H'
    }
  
    const getDPDeliveryWindow = () => {
      if (isTOM && !LFLStock) {
        return tomDeliveryWindow
      } else if (globalState?.state?.isMaxis) {
        return getMaxisSwapDeliveryWindow();
      } else if(callShippingMethodv1(incType, find_agreement?.ClientOffer?.ClientOfferName) && !getDeliverySlot(find_agreement?.ClientOffer?.ClientOfferName)) {
        return getDPDeliveryWindowSS3() // SS3.0
      } else {
        return DeliveryTimeSlotCode;
      }
    }
  
    const callUpdateShipingOrderAPI = () => {
      setLoading(true);
      
      if(hasAddRepresentativeFeature(agreementData1?.ClientOffer?.ClientOfferName))
        navigate('/reviewsubmission');
  
      const userAddress = find_agreement?.Address;
      const returnAdd = isWalking ? walkingAddress : returnAddress ? returnAddress : userAddress;
      const pickUpAdd = isWalking ? walkingAddress : deliveryAddress ? deliveryAddress : userAddress;
  
      const getAddress = () => {
        if (isDelivery) {
          return deliveryAddress;
        } else if (isWalking && (isSwap(incType) || isReplacement(incType)) && !globalState?.state?.isMaxis) {
          return userAddress;
        } else if (walkingAddress || (isWalking && (isSwap(incType) || isReplacement(incType)) && globalState?.state?.isMaxis)) {
          return walkingAddress;
        } else {
          userAddress;
        }
      };
  
      const getShippingOrderRemarks = () => {
        let remarks = '';
  
        if((isTOM || globalState?.state?.isMaxis) && !LFLStock){
          remarks = 'WBKO';
        }
  
        if (clickYes) {
          remarks = `Repesentative Name:${form.name}; NRIC: ${form.FIN}; number: ${form.mobile}; fmip:`;
        }
  
        if (isWalking && (isSwap(incType) || isMalfunction(incType))) {
          remarks += ` Device Data Transfer: ${hasDTAddOnAcquired ? 'Yes' : 'No'}; Walk-in;`;
        }
  
        return remarks;
      };
  
      const UpdateShippingOrderParameters = {
        UpdateShippingOrderParameters: {
          EntityUpdateOptions: {
            ReturnEntity: true,
          },
          IsDataTransfer: hasDTAddOnAcquired,
          InteractionLineId: interactionLineId,
          ServiceRequestId: ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
          FulfilmentMethod:
            (isSwap(incType) || isReplacement(incType)) &&
              isWalking
                ? 'INTLC'
                : undefined,
          ShippingOrder: {
            ShippingMethodType: isDelivery ? getSelectedShippingMethod()?.ShippingMethodType || 'Same Day' : 'Same Day',
            CarrierNumber: carrierNumber,
            SignatureRequired: true,
            // RequestedShipmentDate: isDelivery ? ((isTOM || globalState?.state?.isMaxis) && !LFLStock ? shippingMethodResponse?.[0]?.EstimatedDeliveryDate : deliveryDate) : walkingDate, // for repair we are passing exiting data from get claim api
            RequestedShipmentDate: isDelivery ? ((isTOM || globalState?.state?.isMaxis) && !LFLStock ? shippingMethodResponse?.[0]?.EstimatedDeliveryDate : getSelectedShippingMethod()?.EstimatedShipmentDate ) : walkingDate,
            ExpectedDeliveryDate: isDelivery ? ((isTOM || globalState?.state?.isMaxis) && !LFLStock ? shippingMethodResponse?.[0]?.EstimatedDeliveryDate : deliveryDate) : walkingDate, // for repair we are passing exiting data from get claim api
            // DeliveryWindow: isDelivery ? ((isTOM || globalState?.state?.isMaxis) && !LFLStock ? tomDeliveryWindow : DeliveryTimeSlotCode) : walkingTime, // for repair we are passing exiting data from get claim api
            DeliveryWindow: isDelivery ? getDPDeliveryWindow() : walkingTime, // for repair we are passing exiting data from get claim api
            NationalId: find_agreement?.IdentificationNumber?.substr(
              find_agreement?.IdentificationNumber.length - 4
            ),
            // Remark: clickYes
            //   ? `Repesentative Name:${form.name}; NRIC: ${form.FIN}; number: ${form.mobile}; fmip:`
            //   : '',
            Remark: getShippingOrderRemarks(),
            AlternateContactNumber: globalState?.state?.alternateContactNumber || alternateContactNumber,
            ReceiverName: find_agreement?.Customers?.Customer?.[0]?.FullName,
            ReceiverAddress: {
              LocationType: 'Residential',
              BusinessName: find_agreement?.Customers?.Customer?.[0]?.FullName,
              Standardized: true,
              IsOverridden: false,
              StandardizedSourceName: 'QAS',
              BaseAddressHeaderId: 'c7730733f6bea936403e881fed322e26',
              IsScrubbed: true,
              Address1: (isWalking || isPickup) && getAddress()?.AddressLine1,
              ...getAddress(),
            },
          },
        },
      };
      const UpdateShippingOrderParameters2 = {
        UpdateShippingOrderParameters: {
          EntityUpdateOptions: {
            ReturnEntity: true,
          },
          IsDataTransfer: hasDTAddOnAcquired,
          InteractionLineId: interactionLineId,
          ServiceRequestId: ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
          FulfilmentMethod: isSwap(incType) && isWalking ? 'INTLC' : undefined,
          InboundShippingOrder: isAdvanceExch() ? undefined : {
            ShippingMethodType: 'Same Day',
            CarrierNumber: carrierNumber,
            SignatureRequired: true,
            RequestedShipmentDate: returnDate,
            ExpectedDeliveryDate: returnDate,
            DeliveryWindow: getDeliveryWindow() || undefined,
            NationalId: find_agreement?.IdentificationNumber,
            Remark: '',
            AlternateContactNumber: globalState?.state?.alternateContactNumber || alternateContactNumber,
            ReceiverName: find_agreement?.Customers?.Customer?.[0]?.FullName,
            ReceiverAddress: {
              LocationType: 'Residential',
              BusinessName: find_agreement?.Customers?.Customer?.[0]?.FullName,
              Standardized: true,
              IsOverridden: false,
              StandardizedSourceName: 'QAS',
              BaseAddressHeaderId: 'c7730733f6bea936403e881fed322e26',
              IsScrubbed: true,
              Address1: returnAdd?.Address1,
              ...returnAdd,
            },
          },
  
          ShippingOrder: {
            ShippingMethodType: 'Same Day',
            CarrierNumber: carrierNumber,
            SignatureRequired: true,
            RequestedShipmentDate: isPickup ? pickupDate : isDelivery ? deliveryDate : walkingDate,
            ExpectedDeliveryDate: isPickup ? pickupDate : isDelivery ? deliveryDate : walkingDate,
            // DeliveryWindow: (isPickup && globalState?.state?.isMaxis) ? getPickUpDeliveryWindow(pickupTime) : isPickup ? pickupTime : isDelivery ? DeliveryTimeSlotCode : walkingTime,
            DeliveryWindow: pickUpDeliveryWindow(pickupTime, find_agreement?.ClientOffer?.ClientOfferName) ? pickUpDeliveryWindow(pickupTime, find_agreement?.ClientOffer?.ClientOfferName) : isPickup ? pickupTime : isDelivery ? DeliveryTimeSlotCode : walkingTime,
            NationalId: find_agreement?.IdentificationNumber.substr(
              find_agreement?.IdentificationNumber.length - 4
            ),
            Remark: clickYes
              ? `Repesentative Name:${form.name}; NRIC: ${form.FIN}; number: ${form.mobile}; fmip:`
              : '',
            AlternateContactNumber: globalState?.state?.alternateContactNumber || alternateContactNumber,
            ReceiverName: find_agreement?.Customers?.Customer?.[0]?.FullName,
            ReceiverAddress: {
              LocationType: 'Residential',
              BusinessName: find_agreement?.Customers?.Customer?.[0]?.FullName,
              Standardized: true,
              IsOverridden: false,
              StandardizedSourceName: 'QAS',
              BaseAddressHeaderId: 'c7730733f6bea936403e881fed322e26',
              IsScrubbed: true,
              Address1: pickUpAdd?.Address1,
              ...pickUpAdd,
            },
          },
        },
      };
      setLoading(true);
      getUpdateShippingDetails(
        isRepairRefresh ? UpdateShippingOrderParameters2 : UpdateShippingOrderParameters,
        ServiceOrderId,
        interactionData?.InteractionLine?.InteractionLineId,
        globalState?.state?.findAgreementSessionData
      )
        .then((data: any) => {
          setLoading(false);
          setUpdateShippingOrderResponse(data);
          setUpdateShippingOrderRes(data);
          let GTMData = getGTMData(GTM_EVENT.UPDATE_SHIPPING_EVENT, agreementData1, getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)                
                  logGTMEvent(GTMData);
          globalState?.dispatch({ type: ActionTypes.SET_SHIPPING_ORDER_UPDATES_RESULT, payload: data?.data?.ShippingOrderUpdateResults });
          globalState?.dispatch({ type: ActionTypes.ENABLE_REVIEW_BTN, payload: true });
          if (!clickYes) {
            // if (
            //   incType.toLowerCase() === 'replacement' &&
            //   ShippingOrderUpdateResults?.Holds.length > 0
            // ) {
            //   navigate('../reviewhold', { replace: true });
            // } else {
            //   navigate('/reviewsubmission');
            // }
          }
        })
        .catch((error: any) => {
          setLoading(false);
          saveError(error, globalState);
          showErrorPage(navigate);
        });
    };
  
    const onConfirmOrNextClick = () => {
      setClickYes(false);
      setConfirmDetails(true);
      // callUpdateShipingOrderAPI();
      if (isRepairRefresh && (!R_FulfillmentOptionExist || !ServiceOrderId)) {
        callSetFulfillmentApi();
      } else {
        callUpdateShipingOrderAPI();
      }
      setProxyUserData({ ...form });
      
    };
  
    const handleBack = () => {
      setForm({ ...form, name: '', mobile: '', FIN: '' });
      setClickYes(false);
    };
  
    // function checkIfEmpty(strEntry: any) {
    //   const dontrun = false;
    //   if (dontrun) {
    //     setForm({ ...form })
    //   }
    //   if (strEntry) {
    //     debugger
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }
  
    const handleNameInput = (e: any) => {
      if (e.value?.length > 0 && /^[a-z\d\-_\s]+$/i.test(e.value)) {
        setInvalidName(false);
        setForm({ ...form, name: e.value });
      } else {
        // setInvalidName(true);
        setForm({ ...form, name: '' });
      }
    };
  
    const handleMobileInput = (e: any) => {        
      if (e.value?.length === validLength && /^[0-9]*$/.test(e.value)) {
        setInvalidMobile(false);
        setForm({ ...form, mobile: e.value });
      } else {
        // setInvalidMobile(true);
        setForm({ ...form, mobile: '' });
      }
    };
  
    const handleFinInput = (e: any) => {
      if (e.value?.length === 4 && /^[a-zA-Z0-9\u0E00-\u0E7F]*$/.test(e.value)) {
        setInvalidFin(false);
        setForm({ ...form, FIN: e.value });
      } else {
        // setInvalidFin(true);
        setForm({ ...form, FIN: '' });
      }
    };
  
    const validateMobile = () => {
      if (form.mobile?.length < validLength) {
        setInvalidMobile(true);
      }
    };
  
    const validatename = () => {
      if (form.name?.length < 1) {
        setInvalidName(true);
      }
    };
  
    const validateFin = () => {
      if (form.FIN?.length < 4) {
        setInvalidFin(true);
      }
    };
  
    const handleNoclick = async () => {
      if (isRepairRefresh && (!R_FulfillmentOptionExist || !ServiceOrderId)) {
        callSetFulfillmentApi();
      } else {
        callUpdateShipingOrderAPI();
      }
      setForm({ ...form, name: '', mobile: '', FIN: '' });
      setProxyUserData({ ...form });
    };
  
    const handleProceedClick = () => {
      navigate('/reviewsubmission')
      globalState?.dispatch({ type: ActionTypes.ENABLE_REVIEW_BTN, payload: true });
      globalState?.dispatch({ type: ActionTypes.SET_SHIPPING_ORDER_UPDATES_RESULT, payload: updateShippingOrderRes });
      // const ShippingOrderUpdateResult: any = updateShippingOrderRes;
      // if (
      //   incType.toLowerCase() === 'replacement' &&
      //   ShippingOrderUpdateResult?.ShippingOrderUpdateResults?.Holds.length > 0
      // ) {
      //   navigate('../reviewhold', { replace: true });
      // } else {
      //   navigate('/reviewsubmission');
      // }
    };
  
    const onCancelClick = () => {
      setOpenCancelPopup(!openCancelPopup);
    };
  
    return (
      <Box w="full">
        <Box mt={16} w={{ base: 'full', lg: '80%' }} marginX={{ base: '', lg: 'auto' }}>
          <SubHeader
            backText={'Back'}
            allowBackButtonClick={false}
            showCancelButton={true}
            showBackButton={clickYes}
            cancelText={RepresentativeformScripts.CancelSR.cancelBtnText}
            onCancelClick={onCancelClick}
            onClick={handleBack}
          ></SubHeader>
          {/* <Box w={'full'} h={'auto'} py={4} mt={1}>
          <BackButton>
            <Text>{representativeformScripts?.DevicecareLBL}</Text>
          </BackButton>
        </Box> */}
          <Box
            p={4}
            w={{ base: 'full', lg: '60%' }}
            margin={{ base: '0', lg: 'auto' }}
            mt={{ base: 0, lg: 5 }}
          >
            {!clickYes && !confirmDetails ? (
              <>
                <Box mb={6}>
                  <Text fontSize="lg" mb={5}>
                    <TextScript 
                      text={representativeformScripts?.Content1}
                      translationKey={`${RepsentativeTranslations.PageName}/${RepsentativeTranslations.Content1}`}
                    />
                  </Text>
                  <Text fontSize="lg" mb={5}>
                    <TextScript 
                      text={representativeformScripts?.Content2}
                      translationKey={`${RepsentativeTranslations.PageName}/${RepsentativeTranslations.Content2}`}
                    />
                  </Text>
                  <Text fontSize="lg" mb={6}>
                    <TextScript 
                      text={representativeformScripts?.Ques1}
                      translationKey={`${RepsentativeTranslations.PageName}/${RepsentativeTranslations.Ques1}`}
                    />
                  </Text>
                </Box>
                {/* <Box display="flex" alignItems="center"> */}
                <Flex direction="row" justifyContent={{ base: 'space-between', lg: 'start' }}>
                  <Button
                    py={3}
                    px={5}
                    colorScheme={bodyTextColor}
                    color={bodyTextColor}
                    borderColor={bodyTextColor}
                    variant="outline"
                    w={{ base: '40%', lg: '20%' }}
                    mr={{ base: '0', lg: '5' }}
                    onClick={handleNoclick}
                  >
                    <TextScript 
                      text={RepresentativeformScripts.Global.No}
                      translationKey={`${GlobalTranslations.PageName}/${GlobalTranslations.No}`}
                    />
                  </Button>
                  <Button
                    py={3}
                    px={5}
                    colorScheme={bodyTextColor}
                    color={bodyTextColor}
                    borderColor={bodyTextColor}
                    variant="outline"
                    w={{ base: '40%', lg: '20%' }}
                    onClick={() => {
                      setClickYes(true);
                    }}
                  >
                    <TextScript 
                      text={RepresentativeformScripts.Global.Yes}
                      translationKey={`${GlobalTranslations.PageName}/${GlobalTranslations.Yes}`}
                    />
                  </Button>
                </Flex>
                {/* </Box> */}
              </>
            ) : (
              !confirmDetails && (
                <Box mb={6}>
                  <Text color={'#000000'} fontSize="2xl" mb={5}>
                    <TextScript 
                      text={representativeformScripts?.ConfirmText}
                      translationKey={`${RepsentativeTranslations.PageName}/${RepsentativeTranslations.ConfirmText}`}
                    />
                  </Text>
                  <Box>
                    <FormControl isRequired={true} isInvalid={invalidName} mb={4}>
                      <TextInput
                        showIcon={false}
                        type="text"
                        borderColor={'#A5AAAF'}
                        placeholder={namePlaceholder || ''}
                        onChange={(e: any) => handleNameInput(e)}
                        onBlur={validatename}
                        value={form.name}
                        name="name"
                        id="name"
                        maxLength={40}
                      />
                        {invalidName && (
                        <FormErrorMessage>
                          <TextScript
                            text={'*Please enter name'}
                            translationKey={`${RepsentativeTranslations.PageName}/nameError`}
                          />
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isRequired={true} isInvalid={invalidMobile} mb={4}>
                      <TextInput
                        minLength={1}
                        maxLength={validLength}
                        showIcon={false}
                        type="tel"
                        borderColor={'#A5AAAF'}
                        placeholder={mobilePlaceholder || ''}
                        onChange={(e: any) => handleMobileInput(e)}
                        onBlur={validateMobile}
                        value={form.mobile}
                        name="mobile"
                        id="mobile"
                      />
                        {invalidMobile && (
                          <FormErrorMessage>
                            <TextScript
                              text={'*Please enter valid mobile number'}
                              translationKey={`${RepsentativeTranslations.PageName}/MobileNoError`}
                            />
                          </FormErrorMessage>
                        )}
                    </FormControl>
                    <FormControl isRequired={true} isInvalid={invalidFin} mb={4}>
                      <TextInput
                        maxLength={4}
                        showIcon={false}
                        borderColor={'#A5AAAF'}
                        type="text"
                        placeholder={nricPlaceholder || ''}
                        onChange={(e: any) => handleFinInput(e)}
                        onBlur={validateFin}
                        value={form.FIN}
                        name="FIN"
                        id={'FIN'}
                      />
                        {invalidFin && (
                          <FormErrorMessage>
                            <TextScript
                              text={'*Please enter valid NRIC/FIN'}
                              translationKey={`${RepsentativeTranslations.PageName}/NRICError`}
                            />
                          </FormErrorMessage>
                        )}
                    </FormControl>
                  </Box>
                  <Flex
                    w={{ base: 'full', lg: '75%' }}
                    position={{ base: 'relative', lg: 'fixed' }}
                    direction={{ base: 'column', lg: 'row-reverse' }}
                    justifyContent={{ base: 'start', lg: 'space-between' }}
                    bottom="0"
                    alignItems="center"
                    gap="2"
                    my={4}
                  >
                    {/* <hr style={{
                      border: 'none',
                      borderTop: '2px solid #adaaaa'
                  }} /> */}
                    <Box></Box>
                    <Button
                      py={3}
                      px={5}
                      isDisabled={!(form.FIN && form.mobile && form.name)}
                      bgColor={primary}
                      color="white"
                      w={{ base: 'full', lg: '20%' }}
                      onClick={() => {
                        //Here we have call Navigate function for nex page
                        onConfirmOrNextClick();
                        return;
                      }}
                    >
                      <TextScript 
                        text={representativeformScripts?.ConfirmDetailsLBL}
                        translationKey={`${RepsentativeTranslations.PageName}/${RepsentativeTranslations.btnConfirmDetailsLBL2}`}
                      />
                    </Button>
  
                    {/* <Button
                    colorScheme={primary}
                    as={'u'}
                    variant="link"
                    height="40px"
                    onClick={() => {
                      handleBack();
                    }}
                    cursor='pointer'
                  >
                    {representativeformScripts?.GoBackLBL}
                  </Button> */}
                  </Flex>
                </Box>
              )
            )}
            {confirmDetails && (
              <Box w={{ base: 'auto', lg: '70%' }} margin={{ base: '0', lg: 'auto' }}>
                <Text color={'#000000'} fontSize="2xl" mb={6}>
                  <TextScript 
                      text={representativeformScripts?.RepresentativeUpdated}
                      translationKey={`${RepsentativeTranslations.PageName}/${RepsentativeTranslations.RepresentativeUpdated}`}
                    />
                </Text>
                <Stack minWidth="max-content" alignItems="center" gap="2" mt={4}>
                  <Button
                    py={3}
                    px={5}
                    bgColor={primary}
                    color="white"
                    w={{ base: 'full', lg: '50%' }}
                    margin={{ base: '0', lg: 'auto' }}
                    onClick={handleProceedClick}
                  >
                    <TextScript 
                      text={RepresentativeformScripts.Global.Proceed}
                      translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Proceed}`}
                    />
                  </Button>
                </Stack>
              </Box>
            )}
          </Box>
          <CancelSrPopup closeCancelPopup={setOpenCancelPopup} openCancelPopup={openCancelPopup} />
        </Box>
      </Box>
    );
  };
  
  export default RepresentativeForm;