import {
    DeviceService,
    TextScript,
    getTextScript,
    getTypeByDomain,
    useSessionStorage,
} from '@MGPD/myasurion-shared';
import { Box, Center, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import Scripts from '../Scripts/asurion-script.json';
import translationsKeys from '../Scripts/translationsKeys.json';
import MainStackLayout from '../layout/MainStackLayout';
import { serviceFeeKey } from '../modules/config/constants';
import { useCentralStore } from '../store/appContext';
import { getCurrencySymbol, getProgrammeName } from '../utils/localization-helper';
import { getRedirectUrl, isSwap } from '../utils/utills';

function InwarrantyNotEligible() {
    const globalState = useCentralStore();
    const WarrantyPageKeys = translationsKeys.InWarrantyNotEligiblePage;
    const [exploreOptions, setExploreOptions] = useState('');
    const [exploreSSOptions, setExploreSSOptions] = useState('');
    const [SRFtext, setSRFtext] = useState('');
    const [descriptionSwap, setDescriptionSwap] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [programmeNameLBL, setProgrammeNameLBL] = useState('');
    const [serviceFees] = useSessionStorage<any>(serviceFeeKey, null);
    const [ServiceFee, setServicefees] = useState<any>();
    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

    const getExploreOptions = async () => {
        const PlaceHolderText = await getTextScript(
            `${translationsKeys.Global.PageName}/${translationsKeys.Global.ExploreYourOptions}`,
            `${Scripts.Global.ExploreYourOptions}`
        );
        setExploreOptions(PlaceHolderText);
    };

    const getExploreSSOptions = async () => {
        const PlaceHolderText = await getTextScript(
            `${translationsKeys.Global.PageName}/${translationsKeys.Global.ExploreOptions}`,
            Scripts.Global.ExploreSmartSupportOptions,
            'en-US',
            [programmeNameLBL]
        );
        setExploreSSOptions(PlaceHolderText);
    };

    const getDescriptionSwap = async () => {
        const PlaceHolderText = await getTextScript(
            `${WarrantyPageKeys.PageName}/${WarrantyPageKeys.descriptionSwap}`,
            `${Scripts.InWarrantyNotEligiblePage.descriptionSwap}`
        );
        setDescriptionSwap(PlaceHolderText);
    };

    const getSRFtext = async () => {
        const PlaceHolderText = await getTextScript(
            `${translationsKeys.Global.PageName}/${translationsKeys.Global.SRFLBL}`,
            Scripts.Global.SRFLBL
        );
        setSRFtext(PlaceHolderText);
    };

    const getCurrency = async () => {
        const currency = await getCurrencySymbol();
        setCurrencySymbol(currency);
    };

    const getProgrammeNameLbl = async () => {
        const programmeName = await getProgrammeName(agreementData1?.ClientOffer?.ClientOfferName);
        setProgrammeNameLBL(programmeName);
    };

    useEffect(() => {
        getProgrammeNameLbl();
        getCurrency();
        getExploreOptions();
        // getExploreSSOptions();
        getDescriptionSwap();
        getSRFtext();
        getserviceFee();
    }, []);

    useEffect(() => {
        if (programmeNameLBL && programmeNameLBL?.length > 0) {
            getExploreSSOptions();
        }
    }, [programmeNameLBL]);

    const getserviceFee = () => {
        const fee = serviceFees?.filter((a: any) => {
            return isSwap(a.IncidentType);
        });
        setServicefees(fee);
    };

    return (
        <Center>
            <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'} w="full">
                <MainStackLayout gap={0} spacing={0}>
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '60%' }}
                        margin={{ base: '0', lg: '0 auto' }}
                        h={'auto'}
                        p={'24px 16px'}
                    >
                        <Text fontSize={'32px'} mb="36px">
                            <TextScript
                                text={Scripts.InWarrantyNotEligiblePage.deviceInEligible}
                                translationKey={`${WarrantyPageKeys.PageName}/${WarrantyPageKeys.deviceInEligible}`}
                            />
                        </Text>
                        <Box
                            pt={'24px'}
                            pb={'24px'}
                            w={{ base: 'full', lg: '80%' }}
                            margin={{ base: 0, lg: 'auto' }}
                        >
                            {ServiceFee && (
                                <DeviceService
                                    tag={exploreOptions || Scripts.Global.ExploreYourOptions}
                                    desc2=""
                                    title={
                                        exploreSSOptions ||
                                        Scripts.Global.ExploreSmartSupportOptions
                                    }
                                    desc={descriptionSwap}
                                    feeText={`${
                                        SRFtext || Scripts.Global.SRFLBL
                                    } ${currencySymbol}${ServiceFee?.[0]?.Fees?.TotalAmount}`}
                                    deviceServiceButtonProps={{
                                        label: 'Take me there',
                                        onClick: () =>
                                            (window.location.href = getRedirectUrl(
                                                getTypeByDomain()
                                            )),
                                        disable: false,
                                    }}
                                ></DeviceService>
                            )}
                        </Box>
                    </Box>
                </MainStackLayout>
            </Box>
        </Center>
    );
}

export default InwarrantyNotEligible;
