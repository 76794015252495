/* eslint-disable no-console */
import {
    SubHeader,
    TextScript,
    getCarrierByDomain,
    getTextScript,
    getTypeByDomain,
    useConfig,
    useSessionStorage,
} from '@MGPD/myasurion-shared';
import { Box, Button, Checkbox, Flex, Text } from '@chakra-ui/react';
import { Link } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../Scripts/asurion-script.json';
import Translationkeys from '../Scripts/translationsKeys.json';
import MainStackLayout from '../layout/MainStackLayout';
import { LFLStockKey, currentAgreementKey, processIncidentKey } from '../modules/config/constants';
import { ResumeFlowDataKey, serviceFeeKey } from '../modules/config/constants';
import { logGTMEvent } from '../services/api';
import { useCentralStore } from '../store/appContext';
import { GTM_EVENT, INCIDENT_TYPES } from '../utils/constant';
import features from '../utils/features.json';
import {
    getBatteryWarranty,
    getTncLink,
    hasDataTransferFeature,
    hasTnCLink,
    isMalfuntionWarrantyEligible,
    getDPClaimWarranty
} from '../utils/featuresUtills';
import { camelize, getFeeInclusiveTaxText } from '../utils/helper';
import { getCurrencySymbol, getPerilText, getProgrammeName } from '../utils/localization-helper';
import {
    getGTMData,
    getServiceRequestFee,
    isBatteryReplacement,
    isDeviceRefresh,
    isDeviceRefreshFree,
    isDeviceRepair,
    isInWarranty,
    isMalfunction,
    isReplacement,
    isScreenRepair,
    isScreenRepairBatReplacement,
    isSwap,
} from '../utils/utills';
import CancelSrPopup from './myclaims/CancelSrPopup';

// import { INCIDENT_TYPES } from '../utils/constant';
// import ActionTypes from '../store/actionTypes';

function TermsAndConditions() {
    const globalState = useCentralStore();
    // const currencyCode = globalState?.state?.sessionResponse?.InitializeResponse?.CurrencyCode;
    const isTOM =
        globalState?.state?.sessionResponse?.InitializeResponse?.ClientName === 'Asurion_TechCare';
    const TermsAndConditionsKeys = Translationkeys.TermsAndConditionsPage;
    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const [openCancelPopup, setOpenCancelPopup] = useState(false);

    const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
    const [currentAgreementData] = useSessionStorage<any>(currentAgreementKey, null);
    const [,setWarrantyRepairFee] = useSessionStorage<any>('warrantyRepairFee', null)

    const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
    const R_IncidentType = R_ServiceRequests?.IncidentType;
    // const isSS4 = agreementData1?.IsSmartSupport4
    const [ServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
    const [LFLStockData] = useSessionStorage<any>(LFLStockKey, true);
    const [isChecked, setIsChecked] = useState(false);
    const [termsLink, setTermsLink] = useState('');

    // const LFLSRFFees =
    //   ServiceFeeData &&
    //   ServiceFeeData?.filter((item: any) => item?.IncidentType === R_IncidentType?.toUpperCase())?.[0]
    //     ?.Fees?.TotalAmount;

    // do not show backorder terms and conditions for starhub
    const isBackOrder =
        (!LFLStockData || R_ServiceRequests?.ServiceOrder?.ServiceOrderStatus === 'BORD') &&
        (isTOM || globalState?.state?.isMaxis);

    // const [account] = useAccount({ firstName: '', lastName: '' });
    const navigate = useNavigate();
    const [ProcessIncidentResponse] = useSessionStorage<any>(processIncidentKey, null);

    const incidentTypeFromState =
        globalState?.state?.IncidentType?.toUpperCase() === 'REFRESH'
            ? 'Device Refresh'
            : globalState?.state?.IncidentType?.toUpperCase();

    const IncidentType = isMalfuntionWarrantyEligible(agreementData1?.ClientOffer?.ClientOfferName)
    ? INCIDENT_TYPES.FAULT_REPAIR : camelize(
        ProcessIncidentResponse?.ServiceRequest?.IncidentType ||
            R_IncidentType ||
            incidentTypeFromState
    );
    let LFLSRFFees = getServiceRequestFee(ServiceFeeData, IncidentType);

    const [PerilType, setPerilType] = useState('');

    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { accent } = colors;

    const [BackOrderHeaderText, setBackOrderHeaderText] = useState('');
    const [HeaderText, setHeaderText] = useState('');
    const [SwapTermsText, setSwapTerms] = useState('');
    const [BackorderNewTerms, setBackorderNewTerms] = useState('');
    const [ReplacementTerms, setReplacementTerms] = useState('');
    const [SRFText, setSRFText] = useState('');
    // const [BatteryText, setBatteryText] = useState('');
    // const [RefreshText, setRefreshText] = useState('');
    const [RepairText, setRepairText] = useState('');
    // const [RepairAcceptText, setRepairAcceptText] = useState('');
    const [AcceptText, setAcceptText] = useState('');
    const [RefreshTerms, setRefreshTerms] = useState('');
    const [RepairTerms, setRepairTerms] = useState('');
    const [BatteryTerms, setBatteryTerms] = useState('');
    const [ScreenRepairTerms, setScreenRepairTerms] = useState('');
    const [ScrnRepairBatReplaceTerms, setScrnRepairBatReplaceTerms] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [programmeNameLBL, setProgrammeNameLBL] = useState('');
    const [inclusiveText, setInclusiveText] = useState('');
    const [malfunctionsTerms, setmalfunctionsTerms] = useState('');
    const [malfuntionWarrantyEligible, setMalfuntionWarrantyEligible] = useState(false);

    useEffect(() => {
        getCurrency();
        getPerilLbl();
        getProgrammeNameLbl();
        getInclusiveTaxText();
        getmalfunctionsTerms();
        let GTMData = getGTMData(
            GTM_EVENT.TNC_SCREEN_EVENT,
            globalState?.state?.currentAgreementData,
            getCarrierByDomain(),
            getTypeByDomain(),
            globalState?.state?.currentAgreementData?.CustomerCaseNumber
        );
        logGTMEvent(GTMData);
    }, []);

    const getmalfunctionsTerms = async () => {
        // const returnedText = await getTextScript(
        //   `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.malfunctionsTerms}`,
        //   Scripts.TermsAndConditionsNew.malfunctionsTerms
        // );
        const returnedText: string = Scripts.TermsAndConditionsNew.malfunctionsTerms;
        setmalfunctionsTerms(returnedText);
        setMalfuntionWarrantyEligible(
            isMalfuntionWarrantyEligible(agreementData1?.ClientOffer?.ClientOfferName) &&
            !globalState?.state?.isRepairRequestEligible
        );
    };

    useEffect(() => {
        if (PerilType && PerilType?.length > 0) {
            getHeaderText();
            getBackOrderHeader();
            // getReplacementTerms();
            // getSwapTerms();
            getSRFText();
            // getBatteryText();
            // getRefreshText();
            getRepairText();
            // getRepairAcceptText();
            // getAcceptText();
            getRefreshTermsText();
            getRepairTerms();
            // getBatteryTerms();
            // getBackorderNewTerms();
            getScreenRepairTerms();
            getScrnRepairBatReplaceTerms();
            // getProgrammeNameLbl();
        }
    }, [PerilType]);

    useEffect(() => {
        if (programmeNameLBL && programmeNameLBL?.length > 0) {
            getSwapTerms();
            getBatteryTerms();
            getReplacementTerms();
            getBackorderNewTerms();
        }
    }, [programmeNameLBL]);

    useEffect(() => {
        if (hasTnCLink(agreementData1?.ClientOffer?.ClientOfferName)) {
            setTermsLink(getTncLink(IncidentType, agreementData1?.ClientOffer?.ClientOfferName));
        } else setTermsLink('');
    }, [agreementData1]);

    useEffect(() => {
        if (termsLink !== undefined && PerilType) getAcceptText();
    }, [termsLink, PerilType]);

    const getProgrammeNameLbl = async () => {
        const programmeName = await getProgrammeName(agreementData1?.ClientOffer?.ClientOfferName);
        setProgrammeNameLBL(programmeName);
    };

    const getCurrency = async () => {
        const currency = await getCurrencySymbol();
        setCurrencySymbol(currency);
    };

    const getPerilLbl = async () => {
        const peril = await getPerilText(IncidentType?.toUpperCase(), IncidentType);
        setPerilType(peril);
    };

    const getBackorderNewTerms = async () => {
        let returnedText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.BackorderNewTerms}`,
            `${
                isTOM || globalState?.state?.isMaxis
                    ? Scripts.TermsAndConditionsNew.BackOrdertermsTOM
                    : Scripts.TermsAndConditionsNew.BackOrderterms
            }`,
            'en-US',
            [programmeNameLBL]
        );
        setBackorderNewTerms(returnedText);
    };

    const getBatteryTerms = async () => {
        const returnedText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.BatteryTermsScript}`,
            `${Scripts.TermsAndConditionsNew.BatteryReplacementTerms}`,
            'en-US',
            [
                programmeNameLBL,
                getBatteryWarranty(agreementData1?.ClientOffer?.ClientOfferName, features),
            ]
        );
        setBatteryTerms(returnedText);
    };
    const getScreenRepairTerms = async () => {
        const returnedText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.ScreenRepair}`,
            `${Scripts.TermsAndConditionsNew.ScreenRepairTerms}`
        );
        setScreenRepairTerms(returnedText);
    };

    const getScrnRepairBatReplaceTerms = async () => {
        const returnedText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.BatScreenRepairTerm}`,
            `${Scripts.TermsAndConditionsNew.BatScreenRepairTerm}`
        );
        setScrnRepairBatReplaceTerms(returnedText);
    };

    const getReplacementTerms = async () => {
        const returnedText = await getTextScript(
          `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.ReplacementTerms}`,
          Scripts.TermsAndConditionsNew.ReplacementTerms,
          'en-US',
          [programmeNameLBL, getDPClaimWarranty(agreementData1?.ClientOffer?.ClientOfferName, features)]
        );
        setReplacementTerms(returnedText);
      };
      
    const getRepairTerms = async () => {
        let RepairReturnedText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.RepairTerms}`,
            `${
                isTOM || globalState?.state?.isMaxis
                    ? Scripts.TermsAndConditionsNew.RepairTermsTom
                    : Scripts.TermsAndConditionsNew.RepairTerms
            }`
        );
        setRepairTerms(RepairReturnedText);
    };

    const getSwapTerms = async () => {
        let SwaptermsText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.SwapTerms}`,
            isTOM || globalState?.state?.isMaxis
                ? Scripts.TermsAndConditionsNew.SwapTermsTom
                : Scripts.TermsAndConditionsNew.SwapTerms,
            'en-US',
            [programmeNameLBL]
        );
        setSwapTerms(SwaptermsText);
    };

    const getBackOrderHeader = async () => {
        let BackOrderHeaderText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.BackOrderHeaderText}`,
            `${
                isTOM || globalState?.state?.isMaxis
                    ? Scripts.TermsAndConditionsNew.BackorderHeaderTOM
                    : Scripts.TermsAndConditions.BackOrder.header
            }`
        );
        setBackOrderHeaderText(BackOrderHeaderText);
    };
    const getHeaderText = async () => {
        let HeaderText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.HeaderText}`,
            `${
                isTOM || globalState?.state?.isMaxis
                    ? Scripts.TermsAndConditionsNew.HeaderTOM
                    : 'Terms and Conditions'
            }`
        );
        setHeaderText(HeaderText);
    };

    const getRefreshTermsText = async () => {
        const RefreshTermsText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.RefreshTermsText}`,
            Scripts.TermsAndConditionsNew.RefreshTerms,
            'en-US',
            [PerilType]
        );
        setRefreshTerms(RefreshTermsText);
    };
    const getSRFText = async () => {
        const SRFText = await getTextScript(
            `${Translationkeys.Global.PageName}/${Translationkeys.Global.SRFLBL}`,
            Scripts.Global.SRFLBL
        );
        setSRFText(SRFText);
    };
    // const getBatteryText = async () => {
    //   const BatteryText = await getTextScript(
    //     `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.BatteryFeeText}`,
    //     'Battery Replacement Fee'
    //   );
    //   setBatteryText(BatteryText);
    // };
    // const getRefreshText = async () => {
    //   const RefreshText = await getTextScript(
    //     `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.RefreshText}`,
    //     `Device Refresh Fee`
    //   );
    //   setRefreshText(RefreshText);
    // };
    const getRepairText = async () => {
        const RepairText = await getTextScript(
            `${Translationkeys.Global.PageName}/${Translationkeys.Global.RepairFeeLBL}`,
            Scripts.Global.RepairFeeLBL
        );
        setRepairText(RepairText);
    };
    // const getRepairAcceptText = async () => {
    //   const RepairAcceptText = await getTextScript(
    //     `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.RepairAcceptText}`,
    //     `I accept the Device {1} Terms and Conditions`,
    //     'en-US',
    //     [camelize(PerilType)]
    //   );
    //   setRepairAcceptText(RepairAcceptText);
    // };
    const getAcceptText = async () => {
        let AcceptText;
        if ((isTOM || globalState?.state?.isMaxis) && isDeviceRepair(IncidentType)) {
            AcceptText = await getTextScript(
                `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.AcceptTextTOM}`,
                `${Scripts.TermsAndConditionsNew.AcknowledgeTextTOM}`
            );
        } else {
            if (termsLink) {
                AcceptText = await getTextScript(
                    `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.AcceptLinkText}`,
                    `${Scripts.TermsAndConditionsNew.AcceptLinkText}`,
                    'en-US',
                    [camelize(PerilType), termsLink]
                );
            } else {
                AcceptText = await getTextScript(
                    `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.AcceptText}`,
                    `${Scripts.TermsAndConditionsNew.AcknowledgeText}`,
                    'en-US',
                    [camelize(PerilType)]
                );
            }
        }
        console.log('AcceptText', AcceptText);

        setAcceptText(AcceptText);
    };

    const onSubmit = () => {
        //Backorder changes
        // globalState?.dispatch({ type: ActionTypes.SRF, payload: LFLSRFFees });
        if (!isBackOrderPendingAction && !LFLStockData && !(isTOM || globalState?.state?.isMaxis)) {
            navigate('/reviewhold', { replace: true });
        } else if (
            hasDataTransferFeature(
                IncidentType,
                agreementData1?.ClientOffer?.ClientOfferName,
                features
            )
        ) {
            navigate('/datatransfer', { replace: true });
        } else if(globalState?.state?.isRepairRequestEligible){
            if(isMalfuntionWarrantyEligible(agreementData1?.ClientOffer?.ClientOfferName)){
                let deliveryFee: any = 0;
                let fee: any = [];
            let handlingFee: any = 0;
            if(currentAgreementData?.RemainingDeliveryCap){
                deliveryFee = parseInt(ResumeFlowData?.HandlingFee || 0); 
                fee = [
                    ...fee,
                    {feeType: 'Delivery Fee',
                    fee : `${currencySymbol}${deliveryFee}`    // delivery fee
                }]
            }
            if(currentAgreementData?.RemainingWarrantyCap){
                handlingFee = parseInt(ResumeFlowData?.RepairRequestQuatationAmount || 0);        // repair fee
                    fee = [
                        ...fee,
                        {
                        feeType: 'Repair Fee',
                        fee: `${currencySymbol}${handlingFee}` 
                    }]
            }
            const totalFee: any = deliveryFee + handlingFee;
            fee = [
                ...fee,
                {
                    feeType: 'Total',
                    fee: `${currencySymbol}${totalFee}`
                }
            ]
            setWarrantyRepairFee(fee);
            // setTotalFee(`${totalFee}`)
            totalFee ? navigate('/cleaningInvoice') : navigate('/claimconfirmation')        
            }
            else navigate('/selectInvoice', { replace: true });
        } 
        else {
            navigate('/deliveryoptions', { replace: true });
        }
    };

    const getFeeLabel = () => {
        return isSwap(IncidentType) ||
            isReplacement(IncidentType) ||
            isMalfunction(IncidentType) ||
            isDeviceRefresh(IncidentType)
            ? SRFText
            : RepairText;

        // let fee = '';

        // switch (IncidentType?.toUpperCase()) {
        //   case 'FAULT REPAIR':
        //     fee = RepairText;
        //     break;
        //   case 'DEVICE REFRESH':
        //     fee = RefreshText;
        //     break;
        //   case INCIDENT_TYPES.BATTERY_REPLACEMENT.toUpperCase():
        //     fee = BatteryText;
        //     break;
        //   default:
        //     fee = SRFText;
        //     break;
        // }
        // return fee;
    };

    const onCancelClick = () => {
        setOpenCancelPopup(!openCancelPopup);
    };
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
        // onchange(e.target.checked);
    };
    const FeeText = getFeeLabel();

    const getInclusiveTaxText = async () => {
        const currencyCode = globalState?.state?.sessionResponse?.InitializeResponse?.CurrencyCode;
        let defaultText = getFeeInclusiveTaxText(currencyCode);
        const InclusiveText = await getTextScript(
            `${TermsAndConditionsKeys.PageName}/${defaultText}`,
            defaultText
        );
        setInclusiveText(InclusiveText);
    };

    const [processIncidentData] = useSessionStorage<any>(processIncidentKey, null);
    const isWarranty = malfuntionWarrantyEligible && isInWarranty(processIncidentData);

    const srf = `${currencySymbol}${
        (isDeviceRefreshFree(agreementData1, IncidentType) || isWarranty)
            ? '0'
            : globalState?.state?.isRepairRequestEligible ? ResumeFlowData?.RepairRequestQuatationAmount
            : (globalState?.state?.srf ||
              (ResumeFlowData?.ServiceFee?.ServiceFee &&
                  parseFloat(ResumeFlowData?.ServiceFee?.ServiceFee)?.toFixed(2)) ||
              LFLSRFFees)
    } ${inclusiveText}`;

    const isBackOrderPendingAction =
        ResumeFlowData?.CustomerCaseStatus?.toUpperCase() === 'WORKING' &&
        ResumeFlowData?.IsWBKODeviceAvailable;

    return (
        <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'} px={4} w="full">
            <MainStackLayout gap={0} spacing={0}>
                <Box
                    w={{ base: 'full', lg: '80%' }}
                    mx={{ base: '0', lg: '0px' }}
                    mb={{ base: '0px', lg: '20px' }}
                >
                    <SubHeader
                        backText={''}
                        allowBackButtonClick={false}
                        showCancelButton={true}
                        showBackButton={false}
                        cancelText={Scripts.CancelSR.cancelBtnText}
                        onCancelClick={onCancelClick}
                    ></SubHeader>
                </Box>
                <Box w="full" m="0">
                    <Box
                        className={''}
                        w={{ base: 'full', lg: '50%' }}
                        margin={{ base: '0', lg: 'auto' }}
                        pb={{ base: '0', lg: '0' }}
                    >
                        <Box marginTop={{ base: '24px', lg: '0px' }}>
                            <Text fontSize={'24px'}>{`${
                                isBackOrder
                                    ? `${BackOrderHeaderText}`
                                    : `${PerilType} ${HeaderText}`
                            }`}</Text>
                        </Box>
                        <Box marginTop={'10px'} mx={'10px'}>
                            {isBackOrder ? (
                                <Box>
                                    {BackorderNewTerms && (
                                        <div
                                            dangerouslySetInnerHTML={{ __html: BackorderNewTerms }}
                                        />
                                    )}
                                </Box> //BAckorder
                            ) : isReplacement(IncidentType) ? (
                                <Box>
                                    {ReplacementTerms && (
                                        <div
                                            dangerouslySetInnerHTML={{ __html: ReplacementTerms }}
                                        />
                                    )}
                                </Box>
                            ) : isSwap(IncidentType) ? (
                                <Box>
                                    {SwapTermsText && (
                                        <div dangerouslySetInnerHTML={{ __html: SwapTermsText }} />
                                    )}
                                </Box>
                            ) : isDeviceRepair(IncidentType) ? (
                                <Box>
                                    {RepairTerms && (
                                        <div dangerouslySetInnerHTML={{ __html: RepairTerms }} />
                                    )}
                                </Box>
                            ) : isDeviceRefresh(IncidentType) ? (
                                <Box>
                                    {RefreshTerms && (
                                        <div dangerouslySetInnerHTML={{ __html: RefreshTerms }} />
                                    )}
                                </Box>
                            ) : isBatteryReplacement(IncidentType) ? (
                                <Box>
                                    {BatteryTerms && (
                                        <div dangerouslySetInnerHTML={{ __html: BatteryTerms }} />
                                    )}
                                </Box>
                            ) : isScreenRepair(IncidentType) ? (
                                <Box>
                                    {ScreenRepairTerms && (
                                        <div
                                            dangerouslySetInnerHTML={{ __html: ScreenRepairTerms }}
                                        />
                                    )}
                                </Box>
                            ) : isScreenRepairBatReplacement(IncidentType) ? (
                                <Box>
                                    {ScrnRepairBatReplaceTerms && (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: ScrnRepairBatReplaceTerms,
                                            }}
                                        />
                                    )}
                                </Box>
                            ) : IncidentType?.toUpperCase() === 'MALFUNCTION' ? (
                                <Box>
                                    {malfunctionsTerms && (
                                        <div
                                            dangerouslySetInnerHTML={{ __html: malfunctionsTerms }}
                                        />
                                    )}
                                </Box>
                            ) : (
                                []
                            )}
                        </Box>
                    </Box>
                    <Box
                        display={{ base: 'none', lg: 'block' }}
                        position="fixed"
                        bottom={0}
                        w="full"
                        bgColor={{ base: '', lg: '#faf9f5' }}
                    >
                        <hr
                            style={{
                                border: 'none',
                                borderTop: '2px solid #adaaaa',
                            }}
                        />
                    </Box>
                    <Flex
                        pb={{ base: '0', lg: '100px' }}
                        mb={'auto'}
                        w={{ base: 'full', lg: '50%' }}
                        marginLeft={'auto'}
                        marginRight={'auto'}
                        justifyContent={'start'}
                    >
                        <Checkbox
                            marginBottom={'24px'}
                            marginTop={'24px'}
                            alignItems={'flex-start'}
                            size="lg"
                            onChange={handleCheckboxChange}
                            colorScheme="primary"
                        ></Checkbox>
                        <Text
                            marginBottom={'24px'}
                            marginTop={'24px'}
                            marginLeft={'8px'}
                            size={'16px'}
                        >
                            {
                                isBackOrder && !(isTOM || globalState?.state?.isMaxis)
                                    ? Scripts.TermsAndConditions.BackOrder.checkBoxLbl
                                    : AcceptText && (
                                          <div dangerouslySetInnerHTML={{ __html: AcceptText }} />
                                      )
                                // IncidentType?.toUpperCase() === 'DEVICE REFRESH' ||
                                //   IncidentType?.toUpperCase() === 'FAULT REPAIR' ||
                                //   isBatteryReplacement(IncidentType) ||
                                //   isScreenRepair(IncidentType)
                                // ? `${AcceptText}`
                                // : `${RepairAcceptText}`
                            }
                        </Text>
                    </Flex>
                </Box>
                <Box display={{ base: 'block', lg: 'none' }}>
                    {srf !== '' && FeeText !== '' && !malfuntionWarrantyEligible && (
                        <Box marginBottom={'8px'}>
                            {FeeText} <b>{srf}</b>
                        </Box>
                    )}
                    <Box display="flex" justifyContent="center">
                        <Button
                            isDisabled={!isChecked}
                            color="white"
                            colorScheme="primary"
                            width="343px"
                            mb={'16px'}
                            onClick={onSubmit}
                        >
                            <TextScript
                                text={'Next'}
                                translationKey={`${Translationkeys.Global.PageName}/${Translationkeys.Global.Next}`}
                            />
                        </Button>
                    </Box>
                    <Box display="none" justifyContent="center">
                        <Link
                            color={accent}
                            marginTop="20px"
                            textDecoration="underline"
                            // onClick={onCancel}
                        >
                            <TextScript
                                text={Scripts?.Global?.Cancel}
                                translationKey={`${Translationkeys.Global.PageName}/${Translationkeys.Global.Cancel}`}
                            />
                        </Link>
                    </Box>
                </Box>
                <Box
                    display={{ base: 'none', lg: 'block' }}
                    position="fixed"
                    bottom={0}
                    marginTop={'auto'}
                    w="full"
                    bgColor={{ base: '', lg: '#faf9f5' }}
                >
                    <hr
                        style={{
                            border: 'none',
                            borderTop: '2px solid #adaaaa',
                        }}
                    />
                    <Flex direction="row" p={4} justifyContent="space-between">
                        <Box></Box>
                        {srf !== '' && FeeText !== '' && !malfuntionWarrantyEligible && (
                            <Box marginBottom={'8px'}>
                                <Text fontSize="14px">{FeeText}</Text>
                                <Text fontWeight="bold" fontSize="24px">
                                    {srf}
                                </Text>
                            </Box>
                        )}
                        {/* <Box> */}
                        <Box display="flex" justifyContent="center">
                            <Button
                                isDisabled={!isChecked}
                                color="white"
                                colorScheme="primary"
                                onClick={onSubmit}
                                width="200px"
                            >
                                <TextScript
                                    text={'Next'}
                                    translationKey={`${Translationkeys.Global.PageName}/${Translationkeys.Global.Next}`}
                                />
                            </Button>
                        </Box>
                        {/* <Box display="flex" justifyContent="center">
                        <Link
                            color="#09411D"
                            marginTop="20px"
                            textDecoration="underline"
                            onClick={onCancel}
                        >
                            Cancel
                        </Link>
                    </Box> */}
                        {/* </Box> */}
                    </Flex>
                </Box>
            </MainStackLayout>
            <CancelSrPopup
                closeCancelPopup={setOpenCancelPopup}
                openCancelPopup={openCancelPopup}
            />
        </Box>
    );
}

export default TermsAndConditions;
