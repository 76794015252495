/* eslint-disable no-console */
import {
    DeviceCard,
    SubHeader,
    TextScript,
    getCarrierByDomain,
    getTextScript,
    getTypeByDomain,
    useConfig,
    useSessionStorage,
} from '@MGPD/myasurion-shared';
import {
    Box,
    Button,
    Center,
    Flex,
    Image,
    Skeleton,
    Stack,
    Tag,
    Text,
    Textarea,
} from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../Scripts/asurion-script.json';
import translationsKeys from '../Scripts/translationsKeys.json';
import {
    InWarrantyDataKey,
    assetDataKey,
    currentAgreementKey,
    interactionLineKey,
    processIncidentKey,
    serviceFeeKey,
    serviceRequestKey,
    submitRequestKey,
} from '../modules/config/constants';
import { createServiceRequestApi, logGTMEvent, processIncidentApi } from '../services/api';
import ActionTypes from '../store/actionTypes';
import { useCentralStore } from '../store/appContext';
import { AssetData, MultiSelectTriageOption } from '../types/types';
import { GTM_EVENT, INCIDENT_TYPES, TRIAGE_QUESTION_TYPE } from '../utils/constant';
import { hasMultiDeviceSupport } from '../utils/featuresUtills';
import { getDeviceImageByModel } from '../utils/get-device-images';
import { getProgrammeName } from '../utils/localization-helper';
import {
    clearSessionData,
    getDateAndDay,
    getGTMData,
    getRedirectUrl,
    isBatteryReplacement,
    isClaimEligible,
    isDeviceRefresh,
    isDeviceRepair,
    isMalfunction,
    isReplacement,
    isScreenRepair,
    isScreenRepairBatReplacement,
    isSwap,
    saveError,
    showErrorPage,
} from '../utils/utills';
import { QueAns, TriageQuestion } from './TriageQuestion';

const HowCanWeHelpPageInWarranty: React.FC<PropsWithChildren> = () => {
    const globalState = useCentralStore();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const domainType = getTypeByDomain();
    const carrier = getCarrierByDomain();
    const [Placeholdertexts, setPlaceholderText] = useState<any>('');
    const [, setSRSubmits] = useSessionStorage<any>(submitRequestKey, null);
    // const [sessionAssetData, setSessionAssetData] = useState<any>(undefined);

    const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width: 850px)').matches);
    // const [CompletedSession] = useSessionStorage<any>('completedSession', null);
    // const IncidentTypes = CompletedSession?.[0]?.IncidentType;
    const HowCanWeHelpPageKeys = translationsKeys.HowCanWeHelpPage;

    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { primary, bodyTextColor } = colors;

    const [InWarrantyData] = useSessionStorage<any>(InWarrantyDataKey, null);
    const IncidentTypes = InWarrantyData?.IncidentType;

    const PerilType =
        isSwap(IncidentTypes) || isReplacement(IncidentTypes)
            ? INCIDENT_TYPES.MALFUNCTION
            : IncidentTypes;

    const isRepairFlow =
        isDeviceRefresh(IncidentTypes) ||
        isDeviceRepair(IncidentTypes) ||
        isBatteryReplacement(IncidentTypes) ||
        isScreenRepair(IncidentTypes) ||
        isScreenRepairBatReplacement(IncidentTypes);

    const [assetData, setAssetData] = useState<any>();
    const [triagestatus, setTriageStatus] = useState<any>(true);
    // const [offeredPeril, setOfferedPeril] = useState<any>('');
    // const [offeredServiceFee, setOfferedserviceFee] = useState<any>();

    const [MultiOptions, setMultiOptions] = useState<MultiSelectTriageOption[]>([
        {
            label: 'Cosmetic Damage',
            selected: false,
            scriptKey: 'CosmeticDamage',
            displayLabel: 'Cosmetic Damage',
        },
        {
            label: 'Camera Issues',
            selected: false,
            scriptKey: 'CameraIssues',
            displayLabel: 'Camera Issues',
        },
        {
            label: 'Audio Issues',
            selected: false,
            scriptKey: 'AudioIssues',
            displayLabel: 'Audio Issues',
        },
        {
            label: 'Connectivity Issues (Network / WiFi)',
            selected: false,
            scriptKey: 'ConnectivityIssues',
            displayLabel: 'Connectivity Issues (Network / WiFi)',
        },
        {
            label: 'Cannot Power Off / Device Hang',
            selected: false,
            scriptKey: 'CannotPowerDeviceHang',
            displayLabel: 'Cannot Power Off / Device Hang',
        },
        {
            label: 'Other Issues',
            selected: false,
            scriptKey: 'OtherIssues',
            displayLabel: 'Other Issues',
        },
    ]);
    let [otherText, setOtherText] = useState('');
    const [Otherselected, setOtherSelected] = useState('');
    const [selectedMultiOptions, setSelectedOptions] = useState([]);
    const [triageList, setTriageList] = useState<Array<QueAns>>();
    const [disableNext, setDisableNext] = useState<boolean>(true);
    const [programmeNameLBL, setProgrammeNameLBL] = useState('');
    // const [, setServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
    const interactionData = globalState?.state?.sessionResponse?.Interaction;

    // const [serviceFee] = useSessionStorage<any>(serviceFeeKey, null);
    const [, setSelectedAssetData] = useSessionStorage<any>(assetDataKey, null);
    const [, setCurrentAgreementData] = useSessionStorage<any>(currentAgreementKey, null);
    const [, setServiceRequestData] = useSessionStorage<any>(serviceRequestKey, null);
    const [, setProcessIncidentData] = useSessionStorage<any>(processIncidentKey, null);
    const [, setInteractionLineData] = useSessionStorage<any>(interactionLineKey, null);
    const [, setMultiOptionsData] = useSessionStorage<any>('MultiOptionsData', null);
    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

    const [, setServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
    useEffect(() => {
        if (globalState?.state?.serviceFeeDetailsData) {
            setServiceFeeData(globalState?.state?.serviceFeeDetailsData);
        }
    }, [globalState?.state?.serviceFeeDetailsData]);
    // const servicefeeData = globalState?.state?.serviceFeeDetailsData

    const triages: any = {
        TriageAccepted: true,
        TriageRefusalReason: 'Triage refused by customer',
        Notes: '',
    };
    // CREATE SERVICE REQUEST API
    const {
        refetch: createServiceRequest,
        data: serviceRequestData,
        isFetching: serviceRequestLoading,
    } = useQuery(
        ['createServiceRequest'],
        async () =>
            await createServiceRequestApi(
                interactionData?.InteractionLine.InteractionLineId,
                agreementData1?.AgreementId,
                globalState?.state?.findAgreementSessionData
            ),
        {
            enabled: false,
            onSuccess: (data) => {
                clearSessionData(false);
                setServiceRequestData(data);
                queryClient.setQueryData(['startServiceRequest'], data);
                globalState?.dispatch({ type: ActionTypes.SET_CREATE_SERVICE_DATA, payload: data });

                const GTMData = {
                    event: GTM_EVENT.CLAIM_NO_EVENT,
                    CaseNo: data?.CustomerCaseNumber,
                    Partner: carrier,
                    Program: agreementData1?.ClientProductSkuNbr,
                    MainSubscriberMDN: agreementData1?.ContactPoints?.ContactPoint?.filter(
                        (val: any) => val?.PhoneNumber
                    )?.[0]?.PhoneNumber,
                    EmailAddress: agreementData1?.ContactPoints?.ContactPoint?.filter(
                        (val: any) => val?.EmailAddress
                    )?.[0]?.EmailAddress,
                    Skill: domainType,
                };
                logGTMEvent(GTMData);
            },
            onError: (error) => {
                console.log('serviceRequest error ==> ', error);
            },
        }
    );

    // PROCESS INCIDENT API
    const { isFetching: processIncidentLoading } = useQuery(
        ['processIncident'],
        async () => {
            return await processIncidentApi(
                interactionData?.InteractionLine.InteractionLineId,
                serviceRequestData?.ServiceRequest.ServiceRequestId,
                serviceRequestData?.CustomerCaseId,
                agreementData1?.ClientOffer.ClientOfferId,
                assetData!.MobileDeviceNumber,
                agreementData1?.AgreementId,
                agreementData1?.Customers?.Customer?.[0]?.CustomerId, //customerId
                isSwap(PerilType) || isReplacement(PerilType)
                    ? INCIDENT_TYPES.MALFUNCTION
                    : isScreenRepair(PerilType)
                    ? INCIDENT_TYPES.SCREEN_REPAIR_WARRANTY
                    : PerilType,
                `${FailureDescriptiveText?.trim()} ${Otherselected && '|'} ${
                    Otherselected && otherText
                }`?.trim(),
                getDateAndDay(new Date(), 'YYYY-MM-DD'),
                assetData!.AssetCatalog.AssetCatalogId,
                assetData!.AssetId,
                assetData!.IMEI,
                triages,
                isRepairFlow ? getTriageAnswers() : undefined,
                globalState?.state?.findAgreementSessionData
            );
        },
        {
            enabled: !!serviceRequestData?.ServiceRequest.ServiceRequestId,
            onSuccess: (data) => {
                if (isClaimEligible(data)) {
                    setProcessIncidentData(data);
                    setSelectedAssetData(assetData);
                    setInteractionLineData(interactionData?.InteractionLine.InteractionLineId);
                    globalState?.dispatch({
                        type: ActionTypes.SET_PROCESS_INC_DATA,
                        payload: data,
                    });
                    let GTMData = getGTMData(
                        GTM_EVENT.INCIDENT_TYPE_EVENT,
                        agreementData1,
                        carrier,
                        domainType,
                        data?.CustomerCaseNumber
                    );
                    GTMData.ClaimEligibilty = true;
                    logGTMEvent(GTMData);

                    // check for holds and hold navigation
                    if (data.Holds && data.Holds.length > 0) {
                        handleHold(data);
                        return;
                    }

                    if (isRepairFlow) {
                        logGTMEvent({
                            event: GTM_EVENT.TRIAGE_SELECTION_EVENT,
                            IncidentType: data?.ServiceRequest?.IncidentType,
                            Partner: carrier,
                            Program: agreementData1?.ClientProductSkuNbr,
                            MainSubscriberMDN: agreementData1?.ContactPoints?.ContactPoint?.filter(
                                (val: any) => val?.PhoneNumber
                            )?.[0]?.PhoneNumber,
                            EmailAddress: agreementData1?.ContactPoints?.ContactPoint?.filter(
                                (val: any) => val?.EmailAddress
                            )?.[0]?.EmailAddress,
                            Skill: domainType,
                            ClaimEligibilty: true,
                            SeletedTriage: getTriageAnswers(),
                        });

                        navigate('../repair', { replace: true });
                    } else {
                        navigate('../replacement', { replace: true });
                    }
                } else {
                    // claim is not eligible show claim reveiw page
                    let GTMData = getGTMData(
                        GTM_EVENT.INCIDENT_TYPE_EVENT,
                        agreementData1,
                        carrier,
                        domainType,
                        data?.CustomerCaseNumber
                    );
                    GTMData.ClaimEligibilty = false;
                    logGTMEvent(GTMData);
                    navigate('../reviewhold', { replace: true });
                }
            },
            onError: (error) => {
                saveError(error, globalState);
                showErrorPage(navigate);
            },
        }
    );

    // const { refetch: getserviceFeeApi } = useQuery(
    //   ['servicefee'],
    //   async () => {
    //     const AssetId = agreementData1?.Assets?.Asset?.filter(
    //       (asset: any) => asset?.AssetInstance === 'ENROLLED'
    //     )?.[0]?.AssetId;
    //     return await servicefeeApi(
    //       agreementData1?.AgreementId,
    //       AssetId,
    //       interactionData?.InteractionLine.InteractionLineId
    //     );
    //   },
    //   {
    //     enabled: !!agreementData1?.AgreementId,
    //     onSuccess: (data: any) => {
    //       setServiceFeeData(data);
    //     },
    //     onError: (error) => {
    //       saveError(error, globalState);
    //       showErrorPage(navigate);
    //     },
    //   }
    // );

    const handleHold = (data: any) => {
        const isVideoHold =
            data.Holds &&
            data.Holds.filter((val: any) => {
                return val.HoldType === 'VIDEO' && val.HoldStatus === 'OPEN';
            })?.length > 0
                ? true
                : false;

        const isDocumentHold =
            data.Holds &&
            data.Holds.filter((val: any) => {
                return val.HoldType === 'DOCUMENT' && val.HoldStatus === 'OPEN';
            })?.length > 0
                ? true
                : false;

        if (isVideoHold) {
            // redirect to video declaration
            navigate('../submitdeclaration', { replace: true });
        } else if (isDocumentHold) {
            // redirect document uplodescreen
            navigate('../econfirmation', { replace: true });
        } else {
            // redirect to Hard StopPage for any other hold
            navigate('../reviewhold', { replace: true });
        }
    };

    const getTriageAnswers = () => {
        const triageAnswers = triageList?.map((val: any) => {
            return {
                Que: val.Que,
                Ans: val.ans,
                key:
                    isSwap(IncidentTypes) || isReplacement(IncidentTypes)
                        ? INCIDENT_TYPES.MALFUNCTION
                        : IncidentTypes,
            };
        });

        return triageAnswers;
    };

    const loadTriageQuestions = () => {
        let IncidentType =
            isSwap(IncidentTypes) || isReplacement(IncidentTypes)
                ? INCIDENT_TYPES.MALFUNCTION
                : IncidentTypes?.toUpperCase();
        const TriageQuestionsFilter =
            globalState?.state?.sessionResponse.InitializeResponse?.TriageQuestions?.filter(
                (a: any) => {
                    return (
                        a?.FaultDetection?.toUpperCase() === IncidentType?.toUpperCase() &&
                        a.MultiSelect === 0 &&
                        (isMalfunction(IncidentType) ||
                            a?.QuestionType === TRIAGE_QUESTION_TYPE.IN_WARRANTY ||
                            a?.QuestionType === TRIAGE_QUESTION_TYPE.IN_WARRANTY_OTHER)
                    );
                }
            );

        const Questions = TriageQuestionsFilter?.map((val: any) => {
            return {
                Que: val.Question,
                ans: '',
                expAns: val.Answer,
                type: val.QuestionType,
                key: IncidentTypes,
                displayOrder: val.DisplayOrder,
            };
        });

        // sort questions as per display order
        Questions.sort((a: QueAns, b: QueAns) => {
            return a?.displayOrder - b?.displayOrder;
        });

        setTriageList(Questions);
    };

    const isAllQuestionAnswered = (Questions?: Array<QueAns>) => {
        const answers = Questions?.filter((item) => {
            return item.ans != undefined && item.ans?.trim().length > 0;
        });

        const state = answers?.length != undefined && answers?.length === triageList?.length;

        return state;
    };

    const isOtherAnswered = () => {
        if (Otherselected?.length > 0) {
            if (otherText?.length > 0) return true;
            else return false;
        } else if (selectedMultiOptions?.length > 0 || isBatteryReplacement(PerilType)) {
            return true;
        }
        return false;
    };

    const setOtherAns = (otherText: any) => {
        // find InWarrantyOther questions and set as answer
        const questions = triageList?.map((item) => {
            if (item?.type === 'InWarrantyOther') {
                item.ans = otherText;
            }
            return item;
        });

        setTriageList(questions);
    };

    useEffect(() => {
        const isDisable = isAllQuestionAnswered(triageList) && isOtherAnswered();
        if (isDisable) {
            triageDecisionMatrix();
        }
        setDisableNext(!isDisable);

        // set Other selected
        if (Otherselected.length > 0 && isBatteryReplacement(IncidentTypes)) {
            if (otherText.length > 0) setOtherAns(otherText);
            else setOtherAns('Yes');
        }
    }, [otherText, Otherselected]);

    useEffect(() => {
        window
            .matchMedia('(min-width: 850px)')
            .addEventListener('change', (e) => setIsDesktop(e.matches));
    }, []);

    useEffect(() => {
        if (agreementData1) {
            // get programme name
            getProgrammeNameLbl();
            setCurrentAgreementData(agreementData1);
            globalState?.dispatch({
                type: ActionTypes.SET_CURRENT_AGREEMENT_DATA,
                payload: agreementData1,
            });
            queryClient.setQueryData(['findAgreement'], agreementData1);
            let assetData1: AssetData;

            // for multi device support get selected device from session
            if (
                hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName) &&
                sessionStorage.getItem('dsMdn')
            ) {
                // get selected asset details stored in session
                const selectedAsset: any = JSON.parse(sessionStorage.getItem('dsMdn') || '');
                assetData1 = agreementData1?.Assets.Asset.filter(
                    (a: any) => a.AssetId === selectedAsset?.assetId
                )[0];
            } else {
                assetData1 = agreementData1?.Assets.Asset.filter(
                    (a: any) => a.AssetInstance === 'REPLACED'
                )[0];

                if (!assetData1 || assetData1 === undefined) {
                    // if there is no replaced device check for enrolled device
                    assetData1 = agreementData1?.Assets.Asset.filter(
                        (a: any) => a.AssetInstance === 'ENROLLED'
                    )[0];
                }
            }

            setAssetData(assetData1);
            setSelectedAssetData(assetData1);
            queryClient.setQueryData(['assetData'], assetData1);

            // load triageQuestions
            loadTriageQuestions();
        }
    }, [agreementData1]);

    useEffect(() => {
        setSRSubmits(null);
        if (!agreementData1 || !globalState?.state?.sessionResponse) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [agreementData1 || globalState?.state?.sessionResponse]);

    useEffect(() => {
        if (serviceRequestLoading || processIncidentLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [serviceRequestLoading, processIncidentLoading]);

    const getQuestionAnswer = () => {};

    const getProgrammeNameLbl = async () => {
        const programmeName = await getProgrammeName(agreementData1?.ClientOffer?.ClientOfferName);
        setProgrammeNameLBL(programmeName);
    };

    const onClickMulti = (v: any, e: any) => {
        const selectedOptions = MultiOptions.map((item: any) => {
            if (v.label === item.label) {
                item.selected = !item.selected;
                item.displayLabel = e?.target?.innerText;
            }
            return item;
        });
        const selectedOtherOption: any = MultiOptions?.filter((i: any) => {
            return i.selected === true && i?.label?.toUpperCase() === 'OTHER ISSUES';
        });
        const selectedMultiOptions: any = MultiOptions?.filter((i: any) => {
            return i.selected === true;
        });
        setSelectedOptions(selectedMultiOptions);
        setMultiOptions(selectedOptions);
        setMultiOptionsData(selectedMultiOptions);
        setOtherSelected(selectedOtherOption);
    };

    let FailureDescriptiveText = MultiOptions.filter(
        (item) => item?.selected && item?.label !== 'Other Issues'
    )
        .map((item) => item?.label)
        .join(' | ');

    let handleInputChange = (e: any) => {
        let inputValue = e.target.value;
        setOtherText(inputValue);
    };

    const onAnswerClick = (triageQue: QueAns, ans: string) => {
        if (triageQue?.type === 'InWarrantyOther') {
            if (ans === 'Yes') {
                setOtherSelected('Others');
            } else {
                setOtherSelected('');
            }
        }

        const triageQues = triageList?.map((item: QueAns) => {
            if (triageQue.Que === item.Que) {
                item.ans = ans;
            }
            return item;
        });
        setTriageList(triageQues);
        const isDisable = isAllQuestionAnswered(triageQues) && isOtherAnswered();

        if (isDisable) {
            triageDecisionMatrix();
            // getserviceFee();
        }

        setDisableNext(!isDisable);
    };

    const isRepairWarrantyEligible = () => {
        const triageQues = triageList?.filter((item: QueAns) => {
            return (
                item?.type === TRIAGE_QUESTION_TYPE.IN_WARRANTY_OTHER ||
                item.expAns.toUpperCase() === item.ans.toUpperCase()
            );
        });

        // all answer shoulbe be same as expected answer
        return triageQues?.length === triageList?.length;
    };

    const isSwapEligible = () => {
        const triageQues = triageList?.filter((item: QueAns) => {
            return (
                item.type === TRIAGE_QUESTION_TYPE.TRIAGE &&
                item.expAns.toUpperCase() != item.ans.toUpperCase()
            );
        });
        return triageQues?.length === undefined || triageQues?.length === 0;
    };

    // const getserviceFee = () => {
    //   const offer = serviceFee?.filter((a: any) => {
    //     return a.IncidentType?.toUpperCase() === offeredPeril?.toUpperCase();
    //   });
    //   setOfferedserviceFee(offer);
    // };
    //here Malfunction means Swap/Replace
    const triageDecisionMatrix = () => {
        if (isSwap(IncidentTypes) || isReplacement(IncidentTypes)) {
            if (isSwapEligible()) {
                setTriageStatus(true);
            } else {
                setTriageStatus(false);
                // const offeredPeril = 'SWAP';
                // setOfferedPeril(offeredPeril);
            }
        } else if (isRepairFlow) {
            if (isRepairWarrantyEligible()) {
                setTriageStatus(true);
            } else {
                setTriageStatus(false);
                // setOfferedPeril('SWAP');
            }
        }
    };

    const OnNextClick = () => {
        if (triagestatus) {
            // getserviceFeeApi()
            createServiceRequest();
        } else {
            // getserviceFeeApi()
            navigate('../noteligibleinwarranty', { replace: true });
        }
    };
    const getPlaceHolderText = async () => {
        const PlaceHolderText = await getTextScript(
            'HowCanWeHelpPage/Placeholder',
            `Please tell us more`
        );
        setPlaceholderText(PlaceHolderText);
    };

    useEffect(() => {
        getPlaceHolderText();
    }, []);

    const deviceMake = assetData?.Make?.Name?.toUpperCase() === 'APPLE' ? 'APPLE' : 'OTHER';
    return (
        <Stack>
            <Box id={'myasurion-srswap-howcanwehelp-1'} paddingTop={'54px'}>
                <SubHeader
                    showBackButton={true}
                    backText={'Back'}
                    cancelText={Scripts.CancelSR.cancelBtnText}
                    allowBackButtonClick={false}
                    // onClick={() => window.open(getRedirectUrl(getTypeByDomain()), '_self')}
                    onClick={() => navigate(getRedirectUrl(getTypeByDomain()))}
                ></SubHeader>
                <Box
                    paddingTop={'16px'}
                    paddingX={'16px'}
                    id={'myasurion-srswap-howcanwehelp-2'}
                    marginX={isDesktop ? '20%' : '1%'}
                >
                    <Text fontSize="32px" pb={'16px'}>
                        <TextScript
                            text={'How can we help?'}
                            translationKey={`${HowCanWeHelpPageKeys.InWarrantyPageName}/${HowCanWeHelpPageKeys.HeaderText}`}
                        />
                    </Text>
                    <Center>
                        {assetData ? (
                            <DeviceCard
                                type=""
                                status=""
                                assetId={assetData && assetData?.AssetId.toString()}
                                plan={
                                    programmeNameLBL || agreementData1?.ClientOffer?.ClientOfferName
                                }
                                // mdn={`+65 ${assetData?.MobileDeviceNumber}`}
                                mdn={`(${
                                    assetData?.CountryCallingCode?.split('+')?.[1] ||
                                    agreementData1?.ContactPoints?.ContactPoint?.[0]
                                        ?.CountryCallingCode
                                }) ${assetData && assetData?.MobileDeviceNumber?.split('_')?.[0]}`}
                                model={`${assetData?.AssetCatalog.AssetCatalogName}`}
                                imei={assetData?.IMEI}
                                imageEl={
                                    <Image
                                        height={'80px'}
                                        marginTop={'22px'}
                                        alignSelf={'center'}
                                        src={getDeviceImageByModel(
                                            globalState?.state?.deviceImages,
                                            `${assetData?.AssetCatalog.AssetFamily}`,
                                            '',
                                            80
                                        )}
                                    ></Image>
                                }
                            ></DeviceCard>
                        ) : (
                            <Skeleton
                                minW={'335px'}
                                minH={'150px'}
                                w={'full'}
                                borderRadius={'8px'}
                            />
                        )}
                    </Center>
                    {triageList?.length != undefined && triageList?.length > 0 && deviceMake && (
                        <Box>
                            <TriageQuestion
                                enableButton={true}
                                triageQuestionList={triageList}
                                isDesktop={false}
                                getQuestionAnswer={getQuestionAnswer}
                                onAnswerClick={onAnswerClick}
                                pageName={HowCanWeHelpPageKeys.InWarrantyPageName}
                                incidentType={PerilType}
                                asset={deviceMake}
                            ></TriageQuestion>
                        </Box>
                    )}
                    <Center>
                        {!isBatteryReplacement(IncidentTypes) && (
                            <Box>
                                <Flex justifyContent={'center'} alignContent="center">
                                    <Text fontSize={'22.5px'} paddingTop={'36px'}>
                                        <TextScript
                                            text={
                                                'Please indicate if there are any other issues with your device:'
                                            }
                                            translationKey={`${HowCanWeHelpPageKeys.InWarrantyPageName}/${HowCanWeHelpPageKeys.MultiSelectQuestion}`}
                                        />
                                    </Text>
                                </Flex>
                                <Box id={'myasurion-profile-avatar'} w={'full'} h={'auto'}>
                                    {MultiOptions.map((val: any, i: any) => {
                                        return (
                                            <Tag
                                                key={`Multi-${i}`}
                                                width={'155px'}
                                                height={'100px'}
                                                px={'16px'}
                                                py={'12px'}
                                                // alignItems={'center'}
                                                justifyContent={'center'}
                                                color={bodyTextColor}
                                                textAlign="center"
                                                margin={'6px'}
                                                border={
                                                    val.selected
                                                        ? '2px solid #09411D'
                                                        : '1px solid #A5AAAF'
                                                }
                                                fontWeight={'700'}
                                                background={'#FFF'}
                                                onClick={(e) => {
                                                    onClickMulti(val, e);
                                                }}
                                            >
                                                <TextScript
                                                    text={val.label}
                                                    translationKey={`${HowCanWeHelpPageKeys.PageName}/${val.scriptKey}`}
                                                />
                                            </Tag>
                                        );
                                    })}
                                </Box>
                            </Box>
                        )}
                    </Center>
                    {Otherselected.length > 0 /*triageList?.length === Questions?.length &&*/ && ( //need to do Program name
                        <Box>
                            <Text px={'9px'} pt={'16px'} pb={'8px'} fontSize={'18px'}>
                                <TextScript
                                    text={'Please tell us more'}
                                    translationKey={`${HowCanWeHelpPageKeys.InWarrantyPageName}/${HowCanWeHelpPageKeys.TextAreaLbl}`}
                                />
                            </Text>
                            <Textarea
                                h={'140px'}
                                value={otherText}
                                onChange={handleInputChange}
                                paddingTop={'8px'}
                                placeholder={Placeholdertexts ? Placeholdertexts : ''}
                                maxLength={200}
                            />
                            <Flex justifyContent={'end'}>
                                <Text pt={'5px'}>
                                    {otherText.length}
                                    <TextScript
                                        text={`/200`}
                                        translationKey={`${HowCanWeHelpPageKeys.InWarrantyPageName}/${HowCanWeHelpPageKeys.TextAreaCount}`}
                                    />
                                </Text>
                            </Flex>
                        </Box>
                    )}
                    {globalState?.state?.sessionResponse && (
                        <Center>
                            <Flex direction={'column'}>
                                {
                                    <Box pt={'24px'} pb={'24px'}>
                                        <Button
                                            px={'20px'}
                                            py={'12px'}
                                            w={'343px'}
                                            variant={'solid'}
                                            onClick={() => OnNextClick()}
                                            isDisabled={disableNext}
                                            style={{
                                                cursor: disableNext ? 'not-allowed' : '',
                                                background: disableNext ? 'gray' : primary,
                                                color: disableNext ? 'lightGray' : 'white',
                                            }}
                                        >
                                            <TextScript
                                                text={Scripts.Global.Next}
                                                translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Next}`}
                                            />
                                        </Button>
                                    </Box>
                                }
                            </Flex>
                        </Center>
                    )}
                </Box>
            </Box>
        </Stack>
    );
};

export default HowCanWeHelpPageInWarranty;
