/* eslint-disable no-console */
import {
    SubHeader,
    TextInput,
    TextScript,
    getTypeByDomain,
    useConfig,
} from '@MGPD/myasurion-shared';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Flex,
    FormControl,
    Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import Translations from '../../Scripts/translationsKeys.json';
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import { createInquiry, updateDeviceSynnex } from '../../services/api';
import ActionTypes from '../../store/actionTypes.ts';
import { useCentralStore } from '../../store/appContext.tsx';
import { AssetData } from '../../types/types.ts';
import { getRedirectUrl, saveError, showErrorPage } from '../../utils/utills.tsx';
import CancelSrPopup from '../myclaims/CancelSrPopup.tsx';

// import findagreement from './agreement.json';

function SerialNumberDetail() {
    const SerialNumberDetailsScrips = Scripts.UpdateDeviceDetails.SerialNumberDetails;
    const SerialNumberTranslations = Translations.SerialNumberDetails;
    const globalState = useCentralStore();
    const navigate = useNavigate();
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [serialNumber, setSrNo] = useState<string>('');
    const [isSrNoError, setSrNoError] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [assetData, setAssetData] = useState<any>();

    // const agreementData1: any = findagreement?.FindAgreementsResults?.Agreements?.Agreement[0];

    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
    const InitializeResponse = globalState?.state?.sessionResponse?.InitializeResponse;

    const pageName = SerialNumberTranslations.PageName;

    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;

    useEffect(() => {
        if (agreementData1) {
            let assetData1: AssetData = agreementData1?.Assets.Asset.filter(
                (a: any) => a.AssetInstance === 'ENROLLED'
            )[0];
            setAssetData(assetData1);
        }
    }, [agreementData1 || globalState?.state?.sessionResponse]);

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const goToLandingPage = () => {
        window.location.href = getRedirectUrl(getTypeByDomain());
    };

    const handleSubmit = () => {
        // call update device API
        callUpdateDeviceAPI();
    };

    const handleSrNoChange = (e: any) => {
        const regex = /^[a-zA-Z0-9-_]+$/;

        if (regex.test(e.value)) {
            setSrNoError(false);
            setIsDisabled(false);
        } else {
            setSrNoError(true);
            setIsDisabled(true);
        }

        setSrNo(e.value);
    };

    const callCreateInquiryAPI = () => {
        const payload = {
            CreateInquiryParams: {
                MobileDeviceNumber: assetData?.MobileDeviceNumber,
                AgreementId: agreementData1?.AgreementId,
                FirstName: agreementData1?.Customers?.Customer?.[0]?.FirstName,
                LastName: agreementData1?.Customers?.Customer?.[0]?.LastName,
                Email: agreementData1?.ContactPoints?.ContactPoint?.filter(
                    (val: any) => val?.EmailAddress
                )?.[0]?.EmailAddress,
                CustomerId: agreementData1?.Customers?.Customer?.[0]?.CustomerId,
                ClientName: InitializeResponse?.ClientName || 'twopenmarket',
                DepartmentType: 'Care',
                InquiryChannel: 'Inquiry Channel',
                InquiryType: 'Update Device',
                InquirySubType: 'Update Device',
                InquiryDescription: globalState?.state?.updateDeviceInqDesc,
                CallDriverCategory: 'Update Device Info',
                CallDriverSubCategory: 'Update Device Info',
                ProgramName: agreementData1?.ClientOffer?.ClientOfferName,
                ProgramType: 'Handset Protection',
                Priority: 'Low',
                ClientId: InitializeResponse?.ClientId,
                ClientChannelId: interactionData?.ClientChannelId,
                AssetId: assetData?.AssetId,
                Files: [
                    {
                        fileId: 'upload-UpdateDevice-Id-doc',
                        name: globalState?.state?.updateDeviceFile?.name,
                        fileType: globalState?.state?.updateDeviceFile?.type,
                        preview: {
                            type: globalState?.state?.updateDeviceFile?.name.split('.').pop(),
                            url: 'blob:https://ui-seahzn-ag-sqa.consoleone.apac.npr.aws.asurion.net/04f397fa-5864-4401-83c0-7b1fa441be46',
                        },
                        fileSize: globalState?.state?.updateDeviceFile?.size,
                        data: globalState?.state?.updateDeviceBaseURL,
                    },
                ],
                UpdateDeviceDetails: {
                    AssetCatalogId: assetData?.AssetCatalog?.AssetCatalogId,
                    IMEI: serialNumber,
                    ClientAssetSkuNumber: assetData?.AssetCatalog?.AssetCatalogName,
                    MobileDeviceNumber: assetData?.MobileDeviceNumber,
                    SubscriptionNumber: agreementData1?.SubscriptionNumber,
                    Email: agreementData1?.ContactPoints?.ContactPoint?.filter(
                        (val: any) => val?.EmailAddress
                    )?.[0]?.EmailAddress,
                },
            },
        };
        setLoading(true);
        createInquiry(payload, interactionLineId, globalState?.state?.findAgreementSessionData)
            .then((resp) => {
                setLoading(false);
                console.log('createInquiry---', resp);
                goToLandingPage();
            })
            .catch((err) => {
                console.log('', err);
                setLoading(false);
                saveError(err, globalState);
                showErrorPage(navigate);
            });
    };

    const callUpdateDeviceAPI = () => {
        setLoading(true);
        const payload = {
            UpdateDeviceParameters: {
                MobileDeviceNumber: assetData?.MobileDeviceNumber,
                AgreementId: agreementData1?.AgreementId,
                SerialNumber: serialNumber,
                AssetId: assetData?.AssetId,
            },
        };

        updateDeviceSynnex(payload, interactionLineId, globalState?.state?.findAgreementSessionData)
            .then((resp) => {
                setLoading(false);
                console.log('updateDevice---', resp);
                if (resp?.message === 'device updated successfully') {
                    // create auto close device update inquiry
                    callCreateInquiryAPI();
                } else {
                    setSrNoError(resp?.message);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log('', err);
                setLoading(false);
                saveError(err, globalState);
                showErrorPage(navigate);
            });
    };

    return (
        <Box>
            <Box
                w={{ base: 'full', lg: '80%' }}
                margin="auto"
                pb={{ base: 0, lg: '150px' }}
                paddingTop={'54px'}
            >
                <MainStackLayout gap={0} spacing={0}>
                    <SubHeader
                        backText={'Back'}
                        allowBackButtonClick={true}
                        showCancelButton={true}
                        showBackButton={true}
                        cancelText={Scripts.CancelSR.cancelBtnTextCancel}
                        onCancelClick={goToLandingPage}
                    ></SubHeader>
                    <Box w={{ base: 'full', lg: '80%' }} margin="0 auto" h={'auto'} p={'10px'}>
                        <Box
                            p={2}
                            alignItems="center"
                            w={{ base: 'full', lg: '60%' }}
                            margin={{ base: '0', lg: 'auto' }}
                            pb={{ base: '0', lg: '140px' }}
                        >
                            <Box>
                                <Text fontSize={{ base: '2xl', lg: '3xl' }}>
                                    <TextScript
                                        text={SerialNumberDetailsScrips.title}
                                        translationKey={`${pageName}/${SerialNumberTranslations.title}`}
                                    />
                                </Text>
                            </Box>
                            <Box>
                                <FormControl w={'full'} isRequired={true} my={4}>
                                    <TextInput
                                        showIcon={false}
                                        type="text"
                                        borderColor={colors.bodyTextColor}
                                        placeholder={
                                            SerialNumberDetailsScrips.serialNumberPlaceholder
                                        }
                                        onChange={(e: any) => handleSrNoChange(e)}
                                        value={serialNumber}
                                        maxLength={25}
                                        // isInvalid={!isInvalidInpuTBldgLBLValue}
                                    />
                                    {isSrNoError && (
                                        <Text color={'red'}>
                                            <TextScript
                                                text={SerialNumberDetailsScrips.errorSerialNumber}
                                                translationKey={`${pageName}/${SerialNumberTranslations.errorSerialNumber}`}
                                            />
                                        </Text>
                                    )}
                                </FormControl>
                            </Box>
                            <Box mt="5">
                                <Text fontSize={{ base: '2xl', lg: '3xl' }}>
                                    <TextScript
                                        text={SerialNumberDetailsScrips.findSrNoLabel}
                                        translationKey={`${pageName}/${SerialNumberTranslations.findSrNoLabel}`}
                                    />
                                </Text>
                            </Box>

                            <Accordion allowToggle width="100%">
                                <AccordionItem key={`accordion-item-faq`} py="6" border={'none'}>
                                    {({ isExpanded }) => (
                                        <>
                                            <AccordionButton
                                                background={'inherit'}
                                                _hover={{ background: 'inherit', border: 'none' }}
                                                p={0}
                                                alignItems={'baseline'}
                                            >
                                                <Box as="span" flex="1" textAlign="left">
                                                    <Text
                                                        as={'b'}
                                                        fontSize={'18px'}
                                                        fontWeight={isExpanded ? 700 : 'normal'}
                                                    >
                                                        <TextScript
                                                            text={
                                                                SerialNumberDetailsScrips.windowsTitle
                                                            }
                                                            translationKey={`${pageName}/${SerialNumberTranslations.windowsTitle}`}
                                                        />
                                                    </Text>
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                            <AccordionPanel px={0}>
                                                <Text
                                                    as={'b'}
                                                    fontSize={'18px'}
                                                    fontWeight={isExpanded ? 700 : 'normal'}
                                                >
                                                    <TextScript
                                                        text={
                                                            SerialNumberDetailsScrips.windowsHeading
                                                        }
                                                        translationKey={`${pageName}/${SerialNumberTranslations.windowsHeading}`}
                                                    />
                                                </Text>
                                                <ol style={{ listStyle: 'none' }}>
                                                    {SerialNumberDetailsScrips.windowsInstruciton?.map(
                                                        (v: any, i: any) => {
                                                            return (
                                                                <li style={{ paddingTop: '8px' }}>
                                                                    <Flex direction={'row'}>
                                                                        <Box
                                                                            display="inline-block"
                                                                            width="1em"
                                                                        >
                                                                            {i + 1}.
                                                                        </Box>
                                                                        <Text fontSize={'14px'}>
                                                                            <TextScript
                                                                                text={v}
                                                                                translationKey={`${pageName}/iosInstruciton${
                                                                                    i + 1
                                                                                }`}
                                                                            />
                                                                        </Text>
                                                                    </Flex>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ol>
                                            </AccordionPanel>
                                        </>
                                    )}
                                </AccordionItem>
                                <AccordionItem key={`accordion-item-faq`} py="6">
                                    {({ isExpanded }) => (
                                        <>
                                            <AccordionButton
                                                background={'inherit'}
                                                _hover={{ background: 'inherit', border: 'none' }}
                                                p={0}
                                                alignItems={'baseline'}
                                            >
                                                <Box as="span" flex="1" textAlign="left">
                                                    <Text
                                                        as={'b'}
                                                        fontSize={'18px'}
                                                        fontWeight={isExpanded ? 700 : 'normal'}
                                                    >
                                                        <TextScript
                                                            text={
                                                                SerialNumberDetailsScrips.macTitle
                                                            }
                                                            translationKey={`${pageName}/${SerialNumberTranslations.macTitle}`}
                                                        />
                                                    </Text>
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                            <AccordionPanel px={0}>
                                                <Text
                                                    as={'b'}
                                                    fontSize={'18px'}
                                                    fontWeight={isExpanded ? 700 : 'normal'}
                                                >
                                                    <TextScript
                                                        text={SerialNumberDetailsScrips.macHeading}
                                                        translationKey={`${pageName}/${SerialNumberTranslations.macHeading}`}
                                                    />
                                                </Text>
                                                <ol style={{ listStyle: 'none' }}>
                                                    {SerialNumberDetailsScrips.macInstruciton?.map(
                                                        (v: any, i: any) => {
                                                            return (
                                                                <li style={{ paddingTop: '8px' }}>
                                                                    <Flex direction={'row'}>
                                                                        <Box
                                                                            display="inline-block"
                                                                            width="1em"
                                                                        >
                                                                            {i + 1}.
                                                                        </Box>
                                                                        <Text fontSize={'14px'}>
                                                                            <TextScript
                                                                                text={v}
                                                                                translationKey={`${pageName}/androidInstruciton${
                                                                                    i + 1
                                                                                }`}
                                                                            />
                                                                        </Text>
                                                                    </Flex>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ol>
                                            </AccordionPanel>
                                        </>
                                    )}
                                </AccordionItem>
                            </Accordion>

                            <Box w={{ base: 'full', lg: '70%' }} m="auto" mt="5">
                                <Box w={{ base: 'full', lg: '50%' }} m="auto">
                                    <Button
                                        w="full"
                                        color="white"
                                        colorScheme="primary"
                                        bg={isDisabled ? 'gray.200' : colors.primary}
                                        cursor={isDisabled ? 'not-allowed' : 'pointer'}
                                        disabled={isDisabled}
                                        onClick={() => handleSubmit()}
                                    >
                                        <TextScript
                                            text={Scripts.Global.Confirm}
                                            translationKey={`${Translations.Global.PageName}/${Translations.Global.Confirm}`}
                                        />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </MainStackLayout>
                <CancelSrPopup
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
        </Box>
    );
}

export default SerialNumberDetail;
