import { Reducer } from 'react';

import ActionTypes from './actionTypes';
import { FlowTypeObject } from '../types/types';

export type stateType = {
    projectName: string;
    selectedReplacementDevice: any;
    deviceImages: DeviceImage[];
    Loading: boolean;
    srf: string;
    showoptions: boolean;
    selectedPaymentMethod: string;
    sessionResponse: any;
    nightDeliveryFee: any;
    serviceOrderData: any;
    createServiceData: any;
    processIncData: any;
    getFulfillmentData: any;
    setFulfillmentData: any;
    shippingMethodDatav2: any;
    doorStepAddOnData: DoorStepAddOnData;
    lflDeviceData: any;
    lflDeviceStock: any;
    currentAgreementData: any;
    pickupDate: any;
    returnDate: any;
    pickupTime: any;
    returnTime: any;
    deliveryDate: any;
    deliveryTime: any;
    deliveryTimeCode: any;
    returnAddress: any;
    pickUpAddress: any;
    deliveryAddress: any;
    chargeNightDeliveryFee: boolean;
    locationCoordinates: any;
    errorData: any;
    BTClientTokenData: any;
    SwapConversion: any;
    IncidentType: string;
    RPMFlag: any;
    ReplacementResponse: any;
    enableReviewBtn: any;
    ShippingOrderUpdateResults: any;
    isPaymentMessage: any;
    updateSalesOrderParams: any;
    repirScheduleParams: any;
    shippingMethodRes: any;
    updateDeviceInqDesc: any;
    imeiDeviceDetails: any;
    updateDeviceImei: any;
    updateDeviceCapacity: any;
    updateDeviceColor: any;
    updateDeviceBaseURL: any;
    updateDeviceFile: any;
    myPaymentsResp: any;
    myPaymentsToken: any;
    sdkLoader: boolean;
    sdkLibFailure: string;
    shippingMethodResponse: any;
    myPaymentsCurrentCCDetails: any;
    enrolledDevicesList: any;
    myPaymentsCurrentSession: any;
    myPaymentsSelectedDevice: any;
    renewalSelectedOption: any;
    renewalPaymentDetails: any;
    renewalGetQuoteDetails: any;
    openChatWindow: boolean;
    renewalMonthlyDiscount: any;
    renewalAnnualDiscount: any;
    isMaxis: any;
    isPendingAgreement: boolean;
    brainTreeMethods: any;
    citiesData: any;
    alternateContactNumber: string;
    categoryListResponse: any;
    registerDeviceResponse: any;
    loaded: any;
    selectedCategory: any;
    NewAddedDevices: any;
    inquiryResponse: any;
    assetDetailsUpdateDevice: any;
    modelDataUpdateDevice: any;
    limitExceedData: any;
    fromUpdateDeviceButton: any;
    selectedDeviceDetails: any;
    jwtExpired: boolean;
    deviceRegistrationResponse: any;
    deviceCategories: any;
    selectedDeviceCategory: any;
    selectedDeviceModel: any;
    selectedCleaningDeviceDetails: any;
    cleaningLogistics: any;
    cleaningLogisticsEditField: string;
    cleaningIncidentData: any;
    paymentError: string;
    selectedAssetDetails: any;
    serviceFeeDetailsData: any;
    findAgreementByMDNData: any;
    claimDetailsCustomerCaseResults: any;
    scrollToSR: any;
    selectedEnrolledDevice: any;
    findAgreementSessionData: any;
    isWarrantyRepairEligible: boolean | undefined;
    isRepairRequestEligible: boolean | undefined;
    isWarrantySwapEligible: boolean | undefined;
    checkBalanceData: FlowTypeObject | null;
    trackOrderData: any;
    newClaimAllowed: any;
};

export type actionType = {
    type: ActionTypes;
    payload: any;
};

interface DeviceImage {
    title: string;
    horizonId: string;
    brandName: string;
    deviceImagesMyAsurionCollection: {
        items: { title: string; url: string }[];
    };
}

interface DoorStepAddOnData {
    hasAddOnAcquired: boolean;
    fee: string;
}

export const initialState: stateType = {
    projectName: '',
    selectedReplacementDevice: '',
    deviceImages: [],
    Loading: false,
    srf: '',
    showoptions: true,
    selectedPaymentMethod: '',
    sessionResponse: '',
    nightDeliveryFee: '',
    serviceOrderData: '',
    createServiceData: {},
    processIncData: '',
    doorStepAddOnData: { hasAddOnAcquired: false, fee: '0' },
    getFulfillmentData: '',
    setFulfillmentData: '',
    shippingMethodDatav2: '',
    lflDeviceData: '',
    lflDeviceStock: '',
    currentAgreementData: '',
    pickupDate: '',
    returnDate: '',
    pickupTime: '',
    returnTime: '',
    deliveryDate: '',
    deliveryTime: '',
    deliveryTimeCode: '',
    returnAddress: '',
    pickUpAddress: '',
    deliveryAddress: {},
    chargeNightDeliveryFee: false,
    locationCoordinates: {},
    errorData: {},
    BTClientTokenData: '',
    SwapConversion: false,
    IncidentType: '',
    RPMFlag: '',
    ReplacementResponse: '',
    enableReviewBtn: false,
    ShippingOrderUpdateResults: '',
    isPaymentMessage: false,
    updateSalesOrderParams: '',
    repirScheduleParams: '',
    shippingMethodRes: '',
    updateDeviceInqDesc: '',
    imeiDeviceDetails: '',
    updateDeviceImei: '',
    updateDeviceCapacity: '',
    updateDeviceColor: '',
    updateDeviceBaseURL: '',
    updateDeviceFile: '',
    myPaymentsResp: '',
    myPaymentsToken: '',
    sdkLoader: false,
    sdkLibFailure: '',
    shippingMethodResponse: '',
    myPaymentsCurrentCCDetails: undefined,
    enrolledDevicesList: undefined,
    myPaymentsCurrentSession: undefined,
    myPaymentsSelectedDevice: undefined,
    renewalSelectedOption: undefined,
    renewalPaymentDetails: undefined,
    renewalGetQuoteDetails: undefined,
    openChatWindow: false,
    renewalMonthlyDiscount: undefined,
    renewalAnnualDiscount: undefined,
    isMaxis: undefined,
    isPendingAgreement: false,
    brainTreeMethods: undefined,
    citiesData: undefined,
    alternateContactNumber: '',
    categoryListResponse: undefined,
    registerDeviceResponse: '',
    loaded: undefined,
    NewAddedDevices: undefined,
    selectedCategory: undefined,
    inquiryResponse: undefined,
    assetDetailsUpdateDevice: undefined,
    modelDataUpdateDevice: undefined,
    limitExceedData: undefined,
    fromUpdateDeviceButton: false,
    selectedDeviceDetails: undefined,
    jwtExpired: false,
    deviceRegistrationResponse: undefined,
    deviceCategories: undefined,
    selectedDeviceCategory: undefined,
    selectedDeviceModel: undefined,
    selectedCleaningDeviceDetails: undefined,
    cleaningLogistics: undefined,
    cleaningLogisticsEditField: '',
    cleaningIncidentData: undefined,
    paymentError: '',
    selectedAssetDetails: undefined,
    serviceFeeDetailsData: undefined,
    findAgreementByMDNData: undefined,
    claimDetailsCustomerCaseResults: undefined,
    scrollToSR: false,
    selectedEnrolledDevice: undefined,
    findAgreementSessionData: undefined,
    isWarrantyRepairEligible: undefined,
    isRepairRequestEligible: undefined,
    isWarrantySwapEligible: false,
    trackOrderData: undefined,
    newClaimAllowed: undefined,
    checkBalanceData: {
      FlowType: null,
      AvailableBalance: 0,
      AccountLimit: 0,
      AvailableTiers: []
  }
};

const reducer: Reducer<stateType, actionType> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_PROJECT_NAME:
            return { ...state, projectName: action.payload };

        case ActionTypes.SET_SESSION_RESPONSE:
            return { ...state, sessionResponse: action.payload };

        case ActionTypes.SET_GET_CATEGORY_RESPONSE:
            return { ...state, categoryListResponse: action.payload };

        case ActionTypes.SET_REGISTER_DEVICE_RESPONSE:
            return { ...state, registerDeviceResponse: action.payload };

        case ActionTypes.SET_SELECTED_SERVICE:
            return { ...state, service: action.payload };

        case ActionTypes.SET_RPM_DEVICE:
            return { ...state, selectedReplacementDevice: action.payload };

        case ActionTypes.GET_DEVICE_IMAGES:
            return { ...state, deviceImages: action.payload };
        case ActionTypes.LOADING:
            return { ...state, Loading: action.payload };
        case ActionTypes.SRF:
            return { ...state, srf: action.payload };
        case ActionTypes.SHOW_PAYMENT_OPTIONS:
            return { ...state, showoptions: action.payload };
        case ActionTypes.SET_PAYMENT_METHOD:
            return { ...state, selectedPaymentMethod: action.payload };
        case ActionTypes.SET_DELIVERY_NIGHT_FEE:
            return { ...state, nightDeliveryFee: action.payload };
        case ActionTypes.SET_SERVICE_ORDER_DATA:
            return { ...state, serviceOrderData: action.payload };
        case ActionTypes.SET_CREATE_SERVICE_DATA:
            return { ...state, createServiceData: action.payload };
        case ActionTypes.SET_PROCESS_INC_DATA:
            return { ...state, processIncData: action.payload };
        case ActionTypes.SET_DOOR_STEP_DATA_TRANSFER_ADD_ON:
            return { ...state, doorStepAddOnData: action.payload };
        case ActionTypes.GET_FULFILLMENT_DATA:
            return { ...state, getFulfillmentData: action.payload };
        case ActionTypes.SET_FULFILLMENT_DATA:
            return { ...state, setFulfillmentData: action.payload };
        case ActionTypes.SET_SHIPPING_METHOD_V2:
            return { ...state, shippingMethodDatav2: action.payload };
        case ActionTypes.SET_LFLDevice:
            return { ...state, lflDeviceData: action.payload };
        case ActionTypes.SET_LFL_DEVICE_STOCK:
            return { ...state, lflDeviceStock: action.payload };
        case ActionTypes.SET_CURRENT_AGREEMENT_DATA:
            return { ...state, currentAgreementData: action.payload };
        case ActionTypes.SET_PICKUP_DATE:
            return { ...state, pickupDate: action.payload };
        case ActionTypes.SET_RETURN_DATE:
            return { ...state, returnDate: action.payload };
        case ActionTypes.SET_DELIVERY_DATE:
            return { ...state, deliveryDate: action.payload };
        case ActionTypes.SET_PICKUP_TIME:
            return { ...state, pickupTime: action.payload };
        case ActionTypes.SET_RETURN_TIME:
            return { ...state, returnTime: action.payload };
        case ActionTypes.SET_DELIVERY_TIME:
            return { ...state, deliveryTime: action.payload };
        case ActionTypes.SET_DELIVERY_TIME_CODE:
            return { ...state, deliveryTimeCode: action.payload };
        case ActionTypes.SET_RETURN_ADDRESS:
            return { ...state, returnAddress: action.payload };
        case ActionTypes.SET_PICKUP_ADDRESS:
            return { ...state, pickUpAddress: action.payload };
        case ActionTypes.SET_DELIVERY_ADDRESS:
            return { ...state, deliveryAddress: action.payload };
        case ActionTypes.SET_LOCATION_COORDINATES:
            return {
                ...state,
                locationCoordinates: { lat: action.payload.lat, lng: action.payload.lng },
            };
        case ActionTypes.CHARGE_NIGHT_DELIVERY_FEE:
            return { ...state, chargeNightDeliveryFee: action.payload };
        case ActionTypes.SET_ERROR_DATA:
            return { ...state, errorData: action.payload };
        case ActionTypes.SET_BT_CLIENT_TOKEN:
            return { ...state, BTClientTokenData: action.payload };
        case ActionTypes.SET_SWAP_CONVERSION:
            return { ...state, SwapConversion: action.payload };
        case ActionTypes.SET_INCIDENT_TYPE:
            return { ...state, IncidentType: action.payload };
        case ActionTypes.CHECK_FLAG:
            return { ...state, RPMFlag: action.payload };
        case ActionTypes.SET_RPM_RESPONSE:
            return { ...state, ReplacementResponse: action.payload };
        case ActionTypes.ENABLE_REVIEW_BTN:
            return { ...state, enableReviewBtn: action.payload };
        case ActionTypes.SET_SHIPPING_ORDER_UPDATES_RESULT:
            return { ...state, ShippingOrderUpdateResults: action.payload };
        case ActionTypes.SET_PAYMENT_MESSAGE:
            return { ...state, isPaymentMessage: action.payload };
        case ActionTypes.SET_UPDATESALESORDER_PARAMS:
            return { ...state, updateSalesOrderParams: action.payload };
        case ActionTypes.SET_REPAIRSCHEDULE_PARAMS:
            return { ...state, repirScheduleParams: action.payload };
        case ActionTypes.SET_RAW_SHPPINGMETHOD_RES:
            return { ...state, shippingMethodRes: action.payload };
        case ActionTypes.SET_UPDATE_DEVICE_INQ_DESC:
            return { ...state, updateDeviceInqDesc: action.payload };
        case ActionTypes.IMEI_DEVICE_DETAILS:
            return { ...state, imeiDeviceDetails: action.payload };
        case ActionTypes.SET_UPDATE_DEVICE_IMEI:
            return { ...state, updateDeviceImei: action.payload };
        case ActionTypes.SET_UPDATE_DEVICE_CAPACITY:
            return { ...state, updateDeviceCapacity: action.payload };
        case ActionTypes.SET_UPDATE_DEVICE_COLOR:
            return { ...state, updateDeviceColor: action.payload };
        case ActionTypes.SET_UPDATE_DEVICE_BASE_URL:
            return { ...state, updateDeviceBaseURL: action.payload };
        case ActionTypes.SET_UPDATE_DEVICE_FILE:
            return { ...state, updateDeviceFile: action.payload };
        case ActionTypes.SET_MY_PAYMENT_DETAILS_RESP:
            return { ...state, myPaymentsResp: action.payload };
        case ActionTypes.SET_MY_PAYMENTS_REF_ID:
            return {
                ...state,
                myPaymentsToken: { ...state.myPaymentsToken, referenceID: action.payload },
            };
        case ActionTypes.SET_MY_PAYMENTS_TOKEN_RESP:
            return {
                ...state,
                myPaymentsToken: { ...state.myPaymentsToken, myPaymentsToken: action.payload },
            };
        case ActionTypes.SDK_LIB_LOADER:
            return { ...state, sdkLoader: action.payload };
        case ActionTypes.SDK_LIB_FAILURE:
            return { ...state, sdkLibFailure: action.payload };
        case ActionTypes.SET_SHIPPING_METHOD_RESPONSE:
            return { ...state, shippingMethodResponse: action.payload };
        case ActionTypes.SET_CURRENT_CC_DETAILS:
            return { ...state, myPaymentsCurrentCCDetails: action.payload };
        case ActionTypes.SET_DEVICES_LIST:
            return { ...state, enrolledDevicesList: action.payload };
        case ActionTypes.MYPAYMENTS_CURRENT_SESSION_RESP:
            return { ...state, myPaymentsCurrentSession: action.payload };
        case ActionTypes.MYPAYMENTS_SELECTED_DEVICE:
            return { ...state, myPaymentsSelectedDevice: action.payload };
        case ActionTypes.SET_RENEWAL_OPTION:
            return { ...state, renewalSelectedOption: action.payload };
        case ActionTypes.SET_RENEWAL_PAYMENT_DETAILS_RESP:
            return { ...state, renewalPaymentDetails: action.payload };
        case ActionTypes.SET_RENEWAL_GETQUOTE_DETAILS:
            return { ...state, renewalGetQuoteDetails: action.payload };
        case ActionTypes.OPEN_CHAT_WINDOW:
            return { ...state, openChatWindow: action.payload };
        case ActionTypes.SET_RENEWAL_MONTHLY_DISCOUNT:
            return { ...state, renewalMonthlyDiscount: action.payload };
        case ActionTypes.SET_RENEWAL_ANNUAL_DISCOUNT:
            return { ...state, renewalAnnualDiscount: action.payload };
        case ActionTypes.SET_IS_MAXIS_FLAG:
            return { ...state, isMaxis: action.payload };
        case ActionTypes.SET_PENDING_PAYMENT_FLAG:
            return { ...state, isPendingAgreement: action.payload };
        case ActionTypes.SET_BRAINTREE_METHODS:
            return { ...state, brainTreeMethods: action.payload };
        case ActionTypes.SET_CITIES_DATA:
            return { ...state, citiesData: action.payload };
        case ActionTypes.SET_ALTERNATE_CONTACT_NUMBER:
            return { ...state, alternateContactNumber: action.payload };
        case ActionTypes.SET_ADDED_DEVICE_DETAILS:
            return { ...state, NewAddedDevices: action.payload };
        case ActionTypes.SET_SELECTED_CATEGORY:
            return { ...state, selectedCategory: action.payload };
        case ActionTypes.SET_INQUIRY_RESPONSE:
            return { ...state, inquiryResponse: action.payload };
        case ActionTypes.UPDATEDEVICE_ASSET_DETAILS:
            return { ...state, assetDetailsUpdateDevice: action.payload };
        case ActionTypes.UPDATEDEVICE_ASSET_MODELS_DATA:
            return { ...state, modelDataUpdateDevice: action.payload };
        case ActionTypes.FROM_UPDATE_DEVICE_BUTTON:
            return { ...state, fromUpdateDeviceButton: action.payload };
        case ActionTypes.SET_LIMIT_EXCEED_DATA:
            return { ...state, limitExceedData: action.payload };
        case ActionTypes.SET_SELECTED_DEVICE_DETAILS:
            return { ...state, selectedDeviceDetails: action.payload };
        case ActionTypes.SET_JWT_EXPIRED:
            return { ...state, jwtExpired: action.payload };
        case ActionTypes.SET_DEVICE_REGISTRATION_RESPONSE:
            return { ...state, deviceRegistrationResponse: action.payload };
        case ActionTypes.SET_CLEANING_DEVICE_CATEGORIES:
            return { ...state, deviceCategories: action.payload };
        case ActionTypes.SET_CLEANING_DEVICE_CATEGORY:
            return { ...state, selectedDeviceCategory: action.payload };
        case ActionTypes.SET_CLEANING_DEVICE_MODEL:
            return { ...state, selectedDeviceModel: action.payload };
        case ActionTypes.SET_CLEANING_DEVICE_DETAILS:
            return { ...state, selectedCleaningDeviceDetails: action.payload };
        case ActionTypes.SET_CLEANING_LOGISTICS:
            return { ...state, cleaningLogistics: action.payload };
        case ActionTypes.SET_EDIT_CLEANING_LOGISTICS_FIELD:
            return { ...state, cleaningLogisticsEditField: action.payload };
        case ActionTypes.SET_CLEANING_INCIDENT_DATA:
            return { ...state, cleaningIncidentData: action.payload };
        case ActionTypes.SET_PAYMENT_ERROR:
            return { ...state, paymentError: action.payload };
        case ActionTypes.SET_SELECTED_ASSET_DETAILS:
            return { ...state, selectedAssetDetails: action.payload };
        case ActionTypes.SET_SERVICE_FEE_DATA:
            return { ...state, serviceFeeDetailsData: action.payload };
        case ActionTypes.SET_FINDAGREEMENT_BY_MDN_DATA:
            return { ...state, findAgreementByMDNData: action.payload };
        case ActionTypes.SET_CLAIMDETAILS_DATA:
            return { ...state, claimDetailsCustomerCaseResults: action.payload };
        case ActionTypes.SCROLL_TO_SR:
            return { ...state, scrollToSR: action.payload };
        case ActionTypes.SET_SELECTED_ENROLLED_DEVICE:
            return { ...state, selectedEnrolledDevice: action.payload };
        case ActionTypes.SET_FINDAGREEMENT_SESSION_DATA:
            return { ...state, findAgreementSessionData: action.payload };
        case ActionTypes.NAVIGATE_TO_TRIAGE_FAILUR_PAGE:
            return { ...state, isTriageFailure: action.payload };
        case ActionTypes.SET_WARRANTY_REPAIR_ELIGIBILITY:
            return { ...state, isWarrantyRepairEligible: action.payload };
        case ActionTypes.SET_PAID_REPAIR_REQUEST:
            return { ...state, isRepairRequestEligible: action.payload };
        case ActionTypes.SET_WARRANTY_SWAP_ELIGIBILITY:
            return { ...state, isWarrantySwapEligible: action.payload };
        case ActionTypes.SET_CHECK_BALANCE_DATA:
            return { ...state, checkBalanceData: action.payload };
        case ActionTypes.SET_DOCUMENT_UPLOAD_DATA_TYPE:
            return { ...state, documentUploadDataType: action.payload };
        case ActionTypes.SET_DOCUMENT_UPLOAD_DATA:
            return { ...state, documentUploadData: action.payload };
        case ActionTypes.TRACK_ORDER_DATA:
            return {...state, trackOrderData: action.payload}
        case ActionTypes.SET_NEW_CLAIM_ALLOWED:
            return {...state, newClaimAllowed: action.payload}
        default:
            throw new Error();
    }
};

export default reducer;
